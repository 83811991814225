/* 
 * 日付・時間の整形を行う。Moment.js を補う形で。
 */

import moment from 'moment'

const MyDateTimePlugin = {

    install(Vue) {
        console.log('install MyDateTimePlugin')

        Vue.prototype.$moment = moment

        Vue.prototype.$datetime = {

            /**
             * 日時のフォーマット
             * a の日時を表示するのに、aとbが同一日なら出力なし。違えば DD日/hh:mm と出力
             */
            formatDDhhmm(_a, _b = null) {
                const a = moment(_a)
                const b = _b ? moment(_b) : moment(_a)

                return (a.date() !== b.date() ? `${a.date()}日/ ` : '') + a.format('HH:mm')
            },
            /**
             * 2つの時間の差を分で返す _a - _b
             */
            diffMinutes(_a, _b) {
                return moment.duration(moment(_a).diff(moment(_b))).asMinutes()
            },
            /**
             * 分を HH:MM にフォーマット（分 の 0詰め）
            */
            formatHHMM(mins) {
                return `${Math.floor(mins / 60)}:${('00' + (mins % 60)).slice(-2)}`
            },
            /**
             * ２つの時間（文字列）の差を整形して出力
             */
            formatDiff(_a, _b) {
                return formatHHMM(diffMinutes(_a, _b))
            }
        }
    }

}

export default MyDateTimePlugin


