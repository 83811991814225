/* 
 * Vueルーター設定
 */
import Vue from 'vue'
import VueRouter from 'vue-router'
import Welcome from '@/components/ClubList.vue'
import BrmEdit from '@/components/BrmEdit.vue'
import BrmList from '@/components/BrmList.vue'
import BrmRegister from '@/components/BrmRegister.vue'
import BrmEntryList from '@/components/BrmEntryList'
import BrmSummary from '@/components/BrmSummary.vue'
import DnfRegister from '@/components/DnfRegister.vue'
import SelfFinishRegister from '@/components/rider/SelfFinishRegister.vue'
import DnfCancel from '@/components/DnfCancel.vue'
import DnfList from '@/components/DnfList.vue'
import RiderHome from '@/components/rider/RiderHome.vue'
import MyProfile from '@/components/MyProfile.vue'
import GoalBot from '@/components/GoalBot.vue'
import LeaderBoard from '@/components/LeaderBoard.vue'
import ShowResult from '@/components/ShowResult.vue'
import AccountManage from '@/components/AccountManage.vue'
import Communication from '@/components/Communication'
import RiderInfo from '@/components/rider/RiderInfo'
import SpeechEdit from '@/components/SpeechEdit'

import store from '@/vue-store.js'

Vue.use(VueRouter)

const routes = [
    {
        // スタッフホーム画面
        path: '/staff',
        name: 'staffHome',
        component: BrmSummary
    },
    {
        // ブルベ一覧
        path: '/staff/brmList',
        name: 'brmList',
        component: BrmList
    },
    {
        // ブルベ新規作成・編集
        path: '/staff/brmEdit',
        name: 'brmEdit',
        component: BrmEdit,
        props: true
    },
    {
        path: '/staff/brmEntryList/:id',
        name: 'brmEntryList',
        component: BrmEntryList,
        props: true
    },
    {
        path: '/staff/leaderboard',
        name: 'leaderBoard',
        component: LeaderBoard,
        props: true
    },
    {
        path: '/staff/showresult/:id',
        name: 'showResult',
        component: ShowResult,
        props: true
    },

    {
        path: '/staff/brmRegister',
        name: 'brmRegister',
        component: BrmRegister,
        props: true
    },
    {
        path: '/staff/dnf',
        name: 'staffDnfRegister',
        component: DnfRegister
    },
    {
        path: '/staff/dnflist',
        name: 'staffDnfList',
        component: DnfList
    },
    {
        path: '/staff/bot',
        name: 'goalBot',
        component: GoalBot
    },
    {
        path: '/staff/communication',
        name: 'communication',
        component: Communication
    },
    {
        path: '/staff/speechEdit',
        name: 'speechEdit',
        component: SpeechEdit
    },
    {
        path: '/rider',
        name: 'riderHome',
        component: RiderHome
    },
    {
        path: '/rider/profile',
        name: 'riderProfile',
        component: MyProfile
    },
    {
        path: '/rider/dnf',
        name: 'riderDnfRegister',
        component: DnfRegister,
        beforeEnter: (to, from, next) => {  // DNF すみなら解除ページへ
            if (store.state.current.brmId === store.state.user.riderBrmId && store.state.user.riderStatus === 'dnf') {
                next({ name: 'riderCancelDnf' })
            } else {
                next()
            }
        }
    },
    {
        path: '/rider/canceldnf',
        name: 'riderCancelDnf',
        component: DnfCancel
    },
    {
        path: '/rider/selfgoal',
        name: 'riderSelfFinishRegister',
        component: SelfFinishRegister,
        beforeEnter: (to,from,next)=>{
            next()
        }
    },



    {
        path: '/rider/info',
        name: 'riderInfo',
        component: RiderInfo
    },


    {
        path: '/visitor',
        name: 'visitorHome',
        component: BrmSummary
    },
    {
        path: '/bot',
        name: 'BotHome',
        component: GoalBot

    },
    {
        path: '/admin/account',
        name: 'Account',
        component: AccountManage
    }

]

const router = new VueRouter(
    {
        mode: 'history',
        base: '/brm',
        fallback: true,
        routes,
    }
)
export default router


