import { render, staticRenderFns } from "./LeaderBoardView.vue?vue&type=template&id=590d6e1d&scoped=true&"
import script from "./LeaderBoardView.vue?vue&type=script&lang=js&"
export * from "./LeaderBoardView.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "590d6e1d",
  null
  
)

export default component.exports