// メダル刻印用のイメージを作成
// 記念メダル用

// 通常メダルと同じジグを使うので同じサイズで作成
// 記念メダルへの刻印中止ということで、作業中断 2023/10/9

// canvas ごと返して LeaderBoard.vue の方で toBlob してダウンロードさせる
// 
// 主催者のところは予め用意したビットマップを取り込んでいる。非同期処理なので Promise ベースとした
// 

import outlineImg from '../../image/kinen_medal.png'
import logoImg from '../../image/kinen_logo.png'

const loadImage = (src) => {
    return new Promise(resolve => {
        const img = new Image()
        img.onload = () => resolve(img)
        img.src = src
    })
}



export const makeImageKinen = async (first_initial, kanji_last, kinenTime, outlineImage = true) => {

    const name = `${first_initial}. ${kanji_last}`

    return new Promise(async (resolve) => {

        const canvas = document.createElement('canvas')
        canvas.width = 600  // 300DPI 2inch=約5cm
        canvas.height = 600
        const ctx = canvas.getContext('2d')

        ctx.translate(271, 553)

        const outline = await loadImage(outlineImg)
        const logo = await loadImage(logoImg)


        ctx.save()
        if (outlineImage) {
            ctx.drawImage(outline, -outline.width / 2, -outline.height)
        }
        ctx.scale(1, 0.9)
        ctx.drawImage(logo, -logo.width / 2, -(logo.height + 260))
        ctx.restore()


        // name
        ctx.save()
        const maxNameWidth = 300
        const fontSizes = [...Array(100)].map((v, i) => i)
        const appSize = fontSizes.reduce((app, size) => {
            ctx.font = `bold ${size}px Arial`
            const tmpText = ctx.measureText(name)
            if (tmpText.width < maxNameWidth && size > app) {
                return size
            } else {
                return app
            }
        }, -Infinity)
        ctx.fillStyle = "black"
        ctx.font = `bold ${appSize}px Arial`
        ctx.textAlign = 'center'
        ctx.textBaseline = 'top'

        const text = ctx.measureText(name)
        const textHeight = text.actualBoundingBoxAscent + text.actualBoundingBoxDescent

        ctx.scale(1, 60 / textHeight)
        ctx.fillText(name, 0, -285)
        ctx.restore()
        // BRM, YEAR
        ctx.save()
        ctx.fillStyle = "black"
        ctx.textAlign = 'center'
        ctx.textBaseline = 'middle'
        ctx.font = 'bold 60px Arial'

        ctx.fillText(kinenTime, 5, -100)
        ctx.restore()

        ctx.restore()
        resolve(canvas)


    })
}

