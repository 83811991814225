<template></template>

<script>
import { mapState, mapMutations, mapActions, mapGetters } from "vuex"

const EFFECTS = ["drum", "kodutumi", "fanfare", "applause", "hyoushigi"]
const ZUNDA = 3
const METAN = 2
const SORA = 19
const IDLE_SPEAKER = [0, 4, 1, 5, 8, 10, 9, 14, 15, 17]

// https://openweathermap.org/weather-conditions
const WEATHER_CONDITION = new Map([
    ["clear sky", "晴れ"],
    ["few clouds", "曇り"],
    ["scattered clouds", "曇り"],
    ["broken clouds", "曇り"],
    ["shower rain", "雨"],
    ["rain", "雨"],
    ["thunderstorm", "雷雨"],
    ["snow", "雪"],
    ["mist", "濃霧"],
])

const WIND_DIRECTION = [
    "北",
    "北北東",
    "北東",
    "東北東",
    "東",
    "東南東",
    "南東",
    "南南東",
    "南",
    "南南西",
    "南西",
    "西南西",
    "西",
    "西北西",
    "北西",
    "北北西",
    "北",
]

export default {
    created() {
        console.log("live announce component ready")
    },

    computed: {
        ...mapGetters(["summary", "everyStart"]),

        currentStatus() {
            const ts = new Date()
            const _hh = ts.getHours()
            const ampm = _hh >= 12 ? "午後" : "午前"
            const hh = _hh >= 12 ? _hh - 12 : _hh
            const mm = ts.getMinutes()

            const total = this.summary.total - this.summary.canceled
            const finish = this.summary.finish
            const dns = this.summary.dns
            const dnf = this.summary.dnf
            const remain = total - (finish + dns + dnf)

            let sentence = `${ampm}${hh}時|${mm}分|現在の途中経過です|{500}|`
            sentence += `出走|${total}人|{100}|`
            if (finish > 0) {
                sentence += `完走|${finish}人|{100}|`
            } else {
                sentence += `完走者は、まだいません|{100}|`
            }
            sentence += `DNS|${dns}人|{100}|`
            if (dnf > 0) {
                sentence += `DNF|${dnf}人|{100}|`
            } else {
                sentence += `DNF|ゼロ|{100}|`
            }
            if (remain > 0) {
                sentence += `${remain}人|が走行中です|`
            } else {
                sentence += `全員戻ってきました|`
            }
            return sentence
        },

        weatherReport() {

            return this.everyStart.map((gr)=>{

                const groupName = `${gr.start.getHours()}時|${gr.start.getMinutes()}分組`
                const avgDistance = `${gr}`

            })



        },
    },

    methods: {
        getDirection(deg) {
            return WIND_DIRECTION[Math.floor((deg / 11.25 + 1) / 2)]
        },
    },
}
</script>
