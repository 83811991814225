var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('el-row',[_c('el-button',{on:{"click":function($event){return _vm.newEntry()}}},[_vm._v("新規登録")])],1),_vm._v(" "),_c('el-table',{attrs:{"data":_vm.entryList,"size":"mini"}},[_c('el-table-column',{attrs:{"type":"expand"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
return [_c('table',{staticClass:"table"},[_c('tr',[_c('th',[_vm._v("住所")]),_vm._v(" "),_c('td',[_vm._v(_vm._s(row.address))])]),_vm._v(" "),_c('tr',[_c('th',[_vm._v("携帯電話")]),_vm._v(" "),_c('td',[_vm._v("\n                            "+_vm._s(row.portable)+"\n                            "),_c('a',{staticClass:"d-md-none",attrs:{"href":'tel:' + row.portable}},[_c('i',{staticClass:"el-icon-phone-outline"})])])]),_vm._v(" "),_c('tr',[_c('th',[_vm._v("緊急電話")]),_vm._v(" "),_c('td',[_vm._v("\n                            "+_vm._s(row.emergency)+"("+_vm._s(row.relationship)+")\n                            "),_c('a',{staticClass:"d-md-none",attrs:{"href":'tel:' + row.emergency}},[_c('i',{staticClass:"el-icon-phone-outline"})])])]),_vm._v(" "),_c('tr',[_c('th',[_vm._v("メール")]),_vm._v(" "),_c('td',[_vm._v("\n                            "+_vm._s(row.user.email)+"\n                            "),_c('a',{attrs:{"href":'mailto:' + row.user.email}},[_c('i',{staticClass:"el-icon-postcard"})])])]),_vm._v(" "),_c('tr',[_c('th',[_vm._v("\n                            QR"),_c('br'),_vm._v(" "),_c('pre',[_vm._v(_vm._s(row.user.userid))])]),_vm._v(" "),_c('td',[_c('qriously',{attrs:{"value":("https://brm.ar-chubu.org/" + (row.user.userid)),"size":200}})],1)])])]}}])}),_vm._v(" "),_c('el-table-column',{attrs:{"prop":"user_id","label":"ID","width":"100"}}),_vm._v(" "),_c('el-table-column',{attrs:{"prop":"start_no","label":"番号","width":"50"}}),_vm._v(" "),_c('el-table-column',{attrs:{"label":"スタート","width":"60"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
return [_c('el-tag',{attrs:{"type":"success"}},[_vm._v(_vm._s(row.startLabel))])]}}])}),_vm._v(" "),_c('el-table-column',{attrs:{"label":"氏名","width":"100"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
return [_vm._v("\n                "+_vm._s(row.user.name)+"\n                "),(row.user.participant.sex === 'F')?_c('span',{staticStyle:{"color":"red"}},[_vm._v("F")]):_vm._e()]}}])}),_vm._v(" "),_c('el-table-column',{scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
return [_c('el-button',{on:{"click":function($event){return _vm.edit(row.user_id)}}},[_vm._v("編集")])]}}])})],1),_vm._v(" "),_c('el-dialog',{key:'key' + _vm.editUserId,attrs:{"visible":_vm.editVisible,"width":"80%"},on:{"update:visible":function($event){_vm.editVisible=$event}}},[_c('participant-edit',{attrs:{"brm":_vm.id,"id":_vm.editUserId},on:{"close-dialog":function($event){_vm.editVisible = false},"saved":function($event){return _vm.saved()}}})],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }