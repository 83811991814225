p<!-- 
    ゴール処理用・リーダーボード
    ゴールで各人が開いておく

-->

<template>
    <div class="row">
        <div class="d-none d-lg-block col-lg-3 side">
            <count-down></count-down>
            <el-divider></el-divider>
            <brm-summary>
                <template slot="title">
                    <h5>ブルベサマリー</h5>
                </template>
            </brm-summary>
        </div>
        <div class="col-12 col-lg-9 leaderboard">
            <div slot="header">
                <h5>
                    リーダーボード
                    <el-tooltip class="item" effect="dark" content="DBに問い合わせて更新します" placement="right">
                        <el-button class="ml-2" type="info" size="mini" :icon="updateIcon" circle
                            @click="update"></el-button>
                    </el-tooltip>
                </h5>
                <div class="row">
                    <div class="col-3 col-md-3">
                        <el-checkbox :indeterminate="isIndeterminate" v-model="checkAll"
                            @change="handleCheckAllChange">すべて</el-checkbox>
                    </div>
                    <div class="col-9 col-md-7">
                        <el-checkbox-group v-model="checkedStatus" @change="handleCheckedStatusChange">
                            <el-checkbox v-for="s in statusList" :label="s.status" :key="s.status">{{ s.name
                                }}</el-checkbox>
                        </el-checkbox-group>
                    </div>
                </div>
            </div>

            <el-table :data="list()" :border="true" size="mini" style="width: 100%" @sort-change="sort"
                @header-click="headerClick">
                <el-table-column label="No" width="60" prop="start_no" align="center" fixed
                    sortable="custum"></el-table-column>

                <el-table-column label="リザルト" width="100" align="center">
                    <template v-slot="{ row }">
                        <el-button size="mini" :type="row.status_tag_type" @click="btnClick(row)">{{ row.status_tag
                            }}</el-button>
                    </template>
                </el-table-column>
                <el-table-column label="氏名" prop="name">
                    <template v-slot="{ row }">
                        {{ row.name.replace(/[　 ]+/, " ")
                        }}<i :class="row.approvedIcon"></i>
                    </template>
                </el-table-column>
                <el-table-column label="スタート" width="80" align="center">
                    <template v-slot="{ row }">
                        <el-tag :type="row.start_time_tag_type">{{
                            row.start_time_tag
                            }}</el-tag>
                    </template>
                </el-table-column>
                <el-table-column label="ゴール" prop="finish_at" align="center" sortable="custom">
                    <template v-slot="{ row }">
                        {{ row.finishTime }}
                    </template>
                </el-table-column>
                <el-table-column label="認定" prop="brevetTime" align="center" sortable="custum">
                    <template v-slot="{ row }">
                        {{ row.brevetTime }}
                    </template>
                </el-table-column>
                <el-table-column label="メダル" align="center" width="80px">
                    <template v-slot="{ row }">
                        <template v-if="current.kinenmedal && row.medal">
                            <!-- v-if 条件の row.medal がないと popconfirm がうまく書き換えられない.随分と悩んだ @記念400km -->
                            <el-popconfirm title="記念メダル用ですか" confirm-button-text="はい" cancel-button-text="いいえ"
                                @confirm="downloadEngraveImage(row, true)" @cancel="downloadEngraveImage(row, false)">
                                <el-button slot="reference" v-if="row.medal" type="warning"
                                    icon="el-icon-medal"></el-button>
                            </el-popconfirm>
                        </template>
                        <template v-else>
                            <el-button slot="reference" v-if="row.medal" type="warning" icon="el-icon-medal"
                                @click="downloadEngraveImage(row, false)"></el-button>
                        </template>
                    </template>
                </el-table-column>
                <el-table-column v-if="current.multipurpose_check === 1" :label="current.multipurpose_check_name"
                    align="center">
                    <template v-slot="{ row }">
                        <b style="color: gold; font-size: 18px" v-if="row.multipurpose_check === 1"
                            class="el-icon-star-on"></b>
                    </template>
                </el-table-column>
                <el-table-column v-if="viewComment" label="コメント" align="center">
                    <template v-slot="{ row }">
                        <p class="text-left" v-html="row.cellComment"></p>
                    </template>
                </el-table-column>
            </el-table>

            <el-dialog :visible.sync="detailVisible" width="90%">
                <EntryStatusEdit :entry_id="detailEntryId" @terminate="onTerminate"></EntryStatusEdit>
            </el-dialog>

            <el-dialog :visible.sync="anonymousMedal" width="70%"><template v-slot:title>
                    <h3>メダル作成</h3>
                    <p>任意のメダルを作成します</p>
                </template>
                <anonymous-medal @close="closeAnonymousMedal"></anonymous-medal>
            </el-dialog>
        </div>
    </div>
</template>

<script>
//import { makeImage } from "@/lib/engrave.js"
import { makeImage } from "@/lib/engrave2024.js"
import { makeImageKinen } from "@/lib/kinenEngrave.js"
import fileDownload from "js-file-download"
import { mapState, mapGetters, mapActions } from "vuex"
import EntryStatusEdit from "@/components/EntryStatusEdit"
import BrmSummary from "@/components/BrmSummary"
import CountDown from "@/components/CountDown"
import AnonymousMedal from "@/components/AnonymousMedal"

const status_tags = {
    canceled: "キャンセル",
    dnf: "DNF",
    dns: "DNS",
    registered: "走行中",
    overtime: "OT",
    finish: "FIN",
}
const status_tag_types = {
    canceled: "",
    dnf: "danger",
    dns: "warning",
    registered: "info",
    overtime: "",
    finish: "success",
}

const statusList = [
    { status: "registered", name: "走行中" },
    { status: "overtime", name: "OT" },
    { status: "finish", name: "完走" },
    { status: "dns", name: "DNS" },
    { status: "dnf", name: "DNF" },
    { status: "canceled", name: "除外" },
]

export default {
    components: {
        EntryStatusEdit,
        BrmSummary,
        CountDown,
        AnonymousMedal,
    },

    data() {
        return {
            detailVisible: false,
            detailEntryId: null,
            checkAll: false,
            statusList,
            checkedStatus: ["finish", "overtime"],

            isIndeterminate: true,

            sortProp: "finish_at",
            sortOrder: "descending",

            updateLoading: false,

            anonymousMedal: false, // 任意のメダルデザインを作成するダイアログを表示
            tableKey: Symbol()  // 表の強制書き換え用
        }
    },

    computed: {
        ...mapState(["current"]),

        entryList() {
            return this.current.entries.list.map((entry) => {
                const start = this.current.starts.find((element) => {
                    return element.id == entry.start_id
                })
                const startDay = new Date(entry.start_time).getDate()
                const finishDay = new Date(entry.finish_at).getDate()

                const finishTime =
                    (startDay !== finishDay ? `${finishDay}/ ` : "") +
                    new Date(entry.finish_at).getHours() +
                    ":" +
                    ("00" + new Date(entry.finish_at).getMinutes()).slice(-2)

                const brevetTime =
                    new Date(entry.result_at) - new Date(entry.start_time)
                const brevetTimeMinutes = Math.floor(brevetTime / (60 * 1000))
                const brevetTimeHr = Math.floor(brevetTimeMinutes / 60)
                const brevetTimeMin = brevetTimeMinutes % 60

                return {
                    ...entry,
                    status_tag: status_tags[entry.status],
                    status_tag_type: status_tag_types[entry.status],
                    start_time_tag: start.tag ? start.tag : "",
                    start_time_tag_type: start.type ? start.type : "",
                    finishTime:
                        entry.status === "finish" || entry.status === "overtime"
                            ? finishTime
                            : "",
                    brevetTimeMinutes,
                    brevetTime:
                        entry.status === "finish"
                            ? brevetTimeHr +
                            ":" +
                            ("00" + brevetTimeMin).slice(-2)
                            : "",
                    approvedIcon: entry.approved
                        ? "approved el-icon-circle-check"
                        : "not-approved el-icon-question",
                    cellComment: entry.comment
                        ? entry.comment.replace(/[\n\r]+/, "<br>")
                        : "(なし)",
                }
            })
        },

        updateIcon() {
            return this.updateLoading ? "el-icon-loading" : "el-icon-refresh"
        },

        viewComment() {
            // セルフゴールブルベ時にライダーのコメントを表示する
            return this.current.selfgoal === 1
        },
    },

    methods: {
        ...mapActions(["getEntryList"]),
        list() {
            // select
            const selected = this.entryList.filter((entry) =>
                this.checkedStatus.includes(entry.status)
            )
            const ordered = selected.sort((_a, _b) => {
                let a, b
                switch (this.sortProp) {
                    case "start_no":
                        a = _a.start_no
                        b = _b.start_no
                        break
                    case "finish_at":
                        a = new Date(_a.finish_at)
                        b = new Date(_b.finish_at)
                        break
                    case "brevetTime":
                        a = _a.brevetTimeMinutes
                        b = _b.brevetTimeMinutes
                        break
                }

                return this.sortOrder === "ascending" ? a - b : b - a
            })
            return ordered
        },

        btnClick(row) {
            this.detailEntryId = row.id
            this.detailVisible = true
        },
        onTerminate() {
            this.detailVisible = false
        },
        handleCheckAllChange(val) {
            this.checkedStatus = val ? statusList.map((s) => s.status) : []
            this.isIndeterminate = false
        },
        handleCheckedStatusChange(val) {
            let checkedCount = val.length
            this.checkAll = checkedCount === this.statusList.length
            this.isIndeterminate =
                checkedCount > 0 && checkedCount < this.statusList.length
        },
        sort({ prop, order }) {
            this.sortProp = prop
            this.sortOrder = order
        },

        update() {
            this.updateLoading = true
            this.getEntryList()
                .then(() => {
                    this.updateLoading = false
                })
                .catch(() => {
                    this.updateLoading = false
                })
        },

        headerClick(column, event) {
            if (column.label === "メダル") {
                this.anonymousMedal = true
            }
        },

        // メダルレーザー刻印用のイメージを出力
        downloadEngraveImage(row, kinen = false) {
            const start_no = `${("00" + row.start_no).slice(-3)}`
            const year = this.current.brmDate.getFullYear()
            const name = `${row.name_roman_last}, ${row.name_roman_first}`
            const kanji_name = row.name.replace(/[ 　]+/, "_")      // 姓名を _ で区切っている
            const kanji_last_name = kanji_name.split('_')[0]
            const roman_first_name_initial = row.name_roman_first[0]
            const brm = this.current.brmName.split("_")[1]
            const _time = row.brevetTime.split(":")
            const brmTime = `${_time[0]}h${_time[1]}m`
            const brmTimeKinen = `${_time[0]}°${_time[1]}'`
            const fileName = `${brm}_${start_no}_${kanji_name}.png`
            const kinenFileName = `kinen_${fileName}`

            if (!kinen) {

                makeImage(year, brm, name, brmTime, this.current.kinenmedal).then((canvas) => {
                    canvas.toBlob((blob) => fileDownload(blob, fileName))
                })
            } else {

                makeImageKinen(roman_first_name_initial, kanji_last_name, brmTimeKinen).then((canvas) => {
                    canvas.toBlob((blob) => fileDownload(blob, kinenFileName))
                })
            }
        },

        // メダル作成ダイアログを閉じる
        closeAnonymousMedal() {
            this.anonymousMedal = false
        },
    },
}
</script>

<style scoped>
.approved {
    padding-left: 6px;
    color: gold;
}

.not-approved {
    padding-left: 6px;
    color: red;
}
</style>
