<!-- 
    連絡版： スタッフ間の連絡・ライダーからの連絡・ライダーへの情報
    サブウィンドウでも使えるようにいっぱいいっぱいで表示
-->

<template>
    <div :style="baseStyle">
        <div class="p-2 text-center" v-if="messages.length === 0">
            メッセージはありません
        </div>
        <div class="messages mb-2" v-else v-for="mes in messages" :key="mes.id">
            <component
                :is="messageComponent(mes.user_id)"
                :header="mes.header"
                :createdAt="mes.createdTS"
                :updatedAt="mes.updatedTS"
                :subject="mes.subject"
                :content="mes.content"
                :userId="mes.user_id"
                :color="mes.color"
                @edit="edit(mes.id)"
                @del-post="delPost(mes.id)"
                @reply="reply(mes.id)"
            >
            </component>
        </div>

        <div class="send-button">
            <el-button
                type="primary"
                icon="el-icon-edit"
                circle
                @click="writeVisible = !writeVisible"
            ></el-button>
        </div>

        <el-dialog
            title="メッセージ作成"
            :width="dialogWidth"
            :visible.sync="writeVisible"
            :close-on-click-modal="false"
        >
            <div class="row mt-1">
                <div class="col-12 col-sm-3"><b>送信先</b></div>
                <div class="col-12 col-sm-9">
                    <el-radio v-model="destination" label="staff"
                        >スタッフ</el-radio
                    >
                    <el-radio v-model="destination" label="all"
                        >スタッフ＋参加者</el-radio
                    >
                </div>
            </div>
            <div class="row mt-1">
                <div class="col-12 col-sm-3"><b>タイトル</b></div>
                <div class="col-12 col-sm-9">
                    <el-input v-model="messageTitle"></el-input>
                </div>
            </div>
            <div class="row mt-1">
                <div class="col-12 col-sm-3"><b>本文</b></div>
                <div class="col-12 col-sm-9">
                    <el-input
                        v-model="messageContent"
                        type="textarea"
                        autosize
                    ></el-input>
                </div>
            </div>
            <div class="row mt-3 justify-content-center">
                <el-button @click="cancelPost">キャンセル</el-button
                ><el-button type="primary" @click="submitPost">送信</el-button>
            </div>
        </el-dialog>
    </div>
</template>

<script>
import { mapState, mapGetters, mapActions } from "vuex"
import OtherSend from "@/components/communication/OtherSend"
import SelfSend from "@/components/communication/SelfSend"

export default {
    components: {
        OtherSend,
        SelfSend,
    },
    data() {
        return {
            communicationId: null,
            writeVisible: false,
            destination: "staff",
            replyTo: null,
            messageTitle: "",
            messageContent: "",

            timerId: null,
        }
    },

    computed: {
        ...mapState(["current", "communications", "user", "dimension"]),

        baseStyle() {
            return { "--menu-height": this.dimension.menuHeight + "px" }
        },

        messages() {
            return this.communications.messages.map((mes) => {
                let sender = ""
                let sendTo = ""
                let color = "default"

                if (mes.user_id == null) {
                    sender = "システム"
                    color = "info"
                } else if (mes.user_id == this.user.id) {
                    sender = "私"
                    sender = mes.wrote_by_name
                } else {
                    sender = mes.wrote_by_name
                }
                if (mes.destination == "staff") {
                    sendTo = " > スタッフ"
                } else if (mes.destination == "all") {
                    sendTo = " > スタッフ＋参加者"
                }

                const header = `${sender}${sendTo}`

                const curDT = new Date()
                const createdDT = new Date(mes.created_at)
                const createdMMDD = `${
                    createdDT.getMonth() + 1
                }/${createdDT.getDate()}`
                const createdHHMM = `${createdDT.getHours()}:${(
                    "00" + createdDT.getMinutes()
                ).slice(-2)}`
                const updatedDT = new Date(mes.updated_at)
                const updatedMMDD = `${
                    updatedDT.getMonth() + 1
                }/${updatedDT.getDate()}`
                const updatedHHMM = `${updatedDT.getHours()}:${(
                    "00" + updatedDT.getMinutes()
                ).slice(-2)}`

                const createdTS =
                    curDT.getFullYear() === createdDT.getFullYear() &&
                    curDT.getMonth() === createdDT.getMonth() &&
                    curDT.getDate() === createdDT.getDate()
                        ? createdHHMM
                        : createdMMDD + " " + createdHHMM
                const updatedTS =
                    curDT.getFullYear() === updatedDT.getFullYear() &&
                    curDT.getMonth() === updatedDT.getMonth() &&
                    curDT.getDate() === updatedDT.getDate()
                        ? updatedHHMM
                        : updatedMMDD + " " + updatedHHMM

                return { ...mes, header, createdTS, updatedTS, color }
            })
        },

        dialogWidth() {
            return Math.min(this.dimension.width * 0.9, 800) + "px"
        },
    },

    created() {
        this.getCommunications()
        this.timerId = setInterval(this.getCommunications, 180 * 1000)
    },

    methods: {
        ...mapActions(["getCommunications"]),

        // 動的コンポーネント選択用
        messageComponent(user_id) {
            return user_id != this.user.id ? OtherSend : SelfSend
        },

        cancelPost() {
            this.writeVisible = false
            this.destination = "staff"
            this.messageTitle = ""
            this.messageContent = ""
            this.communicationId = null
            this.replyTo = null
        },

        submitPost() {
            this.$confirm("メッセージを送信していいですか?", "確認", {
                customClass: "confirm-dialog",
            })
                .then(() => {
                    const data = {
                        communicationId: this.communicationId,
                        brmId: this.current.brmId,
                        userId: this.user.id,
                        replyTo: this.replyTo,
                        subject: this.messageTitle,
                        content: this.messageContent.replace(/[\n]/g, "__NL__"),
                        destination: this.destination,
                        sendTo: null,
                        approved: 1,
                    }

                    axios.post("/api/communication", data).then((res) => {
                        this.getCommunications()
                        this.cancelPost()
                    })
                })
                .catch(() => {
                    this.cancelPost()
                })
        },

        edit(id) {
            const message = this.messages.find((mes) => mes.id === parseInt(id))
            this.communicationId = message.id
            this.destination = message.destination
            this.messageTitle = message.subject
            this.messageContent = message.content.replace(/__NL__/g, "\n")
            this.writeVisible = true
        },

        reply(id) {
            const message = this.messages.find((mes) => mes.id === parseInt(id))
            const contentLines = message.content.split("__NL__")
            this.communicationId = null
            this.destination = "staff"
            this.replyTo = parseInt(id)
            this.messageTitle = "RE: " + message.subject
            this.messageContent = contentLines.reduceRight((content, line) => {
                return content + "　> " + line + "\n"
            }, "")
            this.messageContent =
                `${message.wrote_by_name} さんが書きました:` +
                "\n" +
                this.messageContent
            this.writeVisible = true
        },

        delPost(id) {
            const message = this.messages.find((mes) => mes.id === parseInt(id))
            console.log("deleting: %d", id)
            this.$confirm("このメッセージを削除しますか?", "確認").then(() => {
                axios
                    .post(
                        "/api/communication",
                        { id: parseInt(id) },
                        { headers: { "X-HTTP-Method-Override": "DELETE" } }
                    )
                    .then((res) => {
                        this.getCommunications()
                    })
                    .catch((error) => {
                        this.getCommunications()
                    })
            })
        },
    },

    beforeDestroy() {
        clearInterval(this.timerId)
    },
}
</script>

<style>
.send-button {
    position: fixed;
    right: 25px;
    top: calc(var(--menu-height) + 10px);
    z-index: 3;
}

.confirm-dialog {
    width: 350px !important;
}
</style>
