<template>
    <el-card>
        <template v-slot:header
            >{{ greetingMsg }}
            <h5 class="d-inline-block">
                <b>{{ displayName }}</b> さん
            </h5></template
        >
        <el-card v-if="isSelfGoal"
            ><about-self-goal></about-self-goal
        ></el-card>
        <el-card>
            <rider-status></rider-status>
        </el-card>
        <el-card>
            <brm-summary>
                <template v-slot:title
                    ><el-tag class="mb-1" plain
                        >ブルベ進行状況</el-tag
                    ></template
                >
            </brm-summary>
        </el-card>
    </el-card>
</template>

<script>
import { mapState, mapGetters } from "vuex"
import RiderStatus from "@/components/rider/RiderStatus.vue"
import BrmSummary from "@/components/BrmSummary.vue"
import AboutSelfGoal from "@/components/rider/AboutSelfGoal.vue"

export default {
    components: {
        RiderStatus,
        BrmSummary,
        AboutSelfGoal,
    },
    data() {
        return {
            greetingMsg: "",

            isSelfGoal: false,
            isFinishable: false,
            isApproved: false,
        }
    },

    computed: {
        ...mapState(["user"]),
        ...mapGetters(["riderInfo"]),

        displayName(){
            return this.riderInfo ? this.riderInfo.displayName : ""
        }

    },

    created() {
        this.greeting()
        setTimeout(this.setFinishParams, 1000)
    },

    methods: {
        greeting() {
            const hr = new Date().getHours()

            this.greetingMsg = (function () {
                if (hr < 6) {
                    return "こんばんは"
                }
                if (hr < 12) {
                    return "おはようございます"
                }
                if (hr < 19) {
                    return "こんにちは"
                } else {
                    return "こんばんは"
                }
            })()
        },
        setFinishParams() {
            if (!this.user.info || !this.user.info.entry) {
                return
            }
            this.isSelfGoal =
                this.user.info.entry.brm.selfgoal === 1 ? true : false
        },
    },

    beforeDestroy() {
        //
    },
}
</script>

<style scoped>
::v-deep .el-card__header {
    padding-top: 15px;
    padding-bottom: 5px;
}
</style>