<template>
    <el-row>
        <el-col :span="12">
            <el-form :model="formData" label-width="120px">
                <el-form-item>
                    <el-switch v-model="formData.centenaryMedal" active-text="記念メダル" inactive-text="通常メダル"></el-switch>
                </el-form-item>
                <el-form-item label="YEAR">
                    <el-input v-model="formData.year" @change="makeMedal"></el-input>
                </el-form-item>
                <el-form-item label="BRM">
                    <el-input v-model="formData.brm" @change="makeMedal"></el-input>
                </el-form-item>
                <el-form-item label="Name">
                    <el-input v-model="formData.name" @change="makeMedal" placeholder="LAST, First"></el-input>
                </el-form-item>
                <el-form-item label="Time">
                    <el-input v-model="formData.time" @change="makeMedal" placeholder="40h00m"></el-input>
                </el-form-item>
                <el-form-item label="メダル種類" v-show="!formData.centenaryMedal">

                    <el-radio border v-model="formData.kinen" :label="false" @change="makeMedal">通常メダル</el-radio>
                    <el-radio border v-model="formData.kinen" :label="true" @change="makeMedal">記念用</el-radio>

                </el-form-item>
            </el-form>
            <el-button type="primary" @click="submit">出力</el-button>
            <el-button type="primary" plain @click="cancel">キャンセル</el-button>
        </el-col>
        <el-col :span="12">
            <p class="text-center">
                <img style="width: 70%" :src="medalImage" />
            </p>
        </el-col>
    </el-row>
</template>

<script>
import { makeImage, makeMedalImage } from "@/lib/engrave2024.js"
import { makeImageKinen } from '@/lib/kinenEngrave.js'
import fileDownload from "js-file-download"
import { mapState } from "vuex"
export default {
    data() {
        return {
            formData: {
                centenaryMedal: false,
                name: null,
                year: "",
                brm: "",
                time: null,
                kinen: false,
            },
            medalImage: null,

        }
    },

    computed: {
        ...mapState(["current"]),
    },

    created() {
        this.reset()
    },

    methods: {
        reset() {
            this.formData.centenaryMedal = (this.current.kinenmedal === 1)
            this.formData.name = null
            this.formData.time = null
            this.formData.year = this.current.brmDate.getFullYear()
            this.formData.brm = this.current.brmName.split("_")[1]
            this.formData.kinen = false
            this.makeMedal()
        },

        async makeMedal() {

            const kinentime = this.formData.time !== null ? this.formData.time.replace(/[hH]/, '°').replace(/[mM]/, "'") : ''
            const _kinenName = this.formData.name !== null ? this.formData.name.split(/[,.][ ]*/) : []
            const kineninitial = _kinenName[0] || ''
            const kinenlast = _kinenName[1] || ''

            const canvas = !this.formData.centenaryMedal ? await makeMedalImage(
                this.formData.year,
                this.formData.brm,
                this.formData.name,
                this.formData.time,
                this.formData.kinen
            ) : await makeImageKinen(
                kineninitial, kinenlast, kinentime, true
            )
            this.medalImage = canvas.toDataURL("img/png")

        },

        async submit() {
            const kinentime = this.formData.time !== null ? this.formData.time.replace(/[hH]/, '°').replace(/[mM]/, "'") : ''
            const _kinenName = this.formData.name !== null ? this.formData.name.split(/[,.][ ]*/) : []
            const kineninitial = _kinenName[0] || ''
            const kinenlast = _kinenName[1] || ''

            const canvas = !this.formData.centenaryMedal ? await makeImage(
                this.formData.year,
                this.formData.brm,
                this.formData.name,
                this.formData.time,
                this.formData.kinen
            ) : await makeImageKinen(
                kineninitial, kinenlast, kinentime, false
            )

            canvas.toBlob((blob) => fileDownload(blob, "medal.png"))
            this.cancel()
        },

        cancel() {
            this.reset()
            this.$emit("close")
        },
    },
}
</script>
