<!--
    参加者の編集・新規登録

    event
        saved: データが更新されたときに emit する
-->

<template>
    <div>
        <el-form
            ref="form"
            :model="user"
            :rules="rules"
            size="mini"
            label-width="120px"
        >
            <el-form-item label="番号" prop="start_no">
                <el-input
                    :disabled="!newEntry"
                    v-model="user.start_no"
                ></el-input>
            </el-form-item>
            <el-form-item v-if="!newEntry" label="スタート時間" prop="start_at">
                <el-input :disabled="!newEntry" v-model="user.start_at" />
            </el-form-item>
            <el-form-item v-if="newEntry" label="スタート時間" prop="startId">
                <el-select v-model="user.startId">
                    <el-option
                        v-for="start in startList"
                        :key="start.startId"
                        :label="start.startTime"
                        :value="start.startId"
                    ></el-option>
                </el-select>
            </el-form-item>
            <el-form-item label="氏名" prop="name_kanji"
                ><el-input v-model="user.name_kanji"
            /></el-form-item>
            <el-form-item label="カナ氏名" prop="name_kana"
                ><el-input v-model="user.name_kana"
            /></el-form-item>
            <!-- 追加 -->
            <el-form-item label="ローマ字氏名">
                <el-row>
                    <el-col :span="11">
                        <el-input v-model="user.name_roman_last"></el-input>
                    </el-col>
                    <el-col :span="11">
                        <el-input v-model="user.name_roman_first"></el-input>
                    </el-col>
                </el-row>
            </el-form-item>
            <!-- 追加 -->
            <el-form-item label="メール" prop="email"
                ><el-input v-model="user.email"
            /></el-form-item>
            <el-form-item label="〒 都道府県"
                ><el-row>
                    <el-col :span="11" :md="5"
                        ><el-input v-model="user.zip"
                    /></el-col>
                    <el-col :span="12" :md="5" :offset="1">
                        <el-select v-model="user.prefecture">
                            <el-option
                                v-for="item in prefectures"
                                :key="item"
                                :label="item"
                                :value="item"
                            ></el-option>
                        </el-select>
                    </el-col> </el-row
            ></el-form-item>
            <el-form-item label="住所">
                <el-row>
                    <el-col :md="12"
                        ><el-input v-model="user.address"></el-input></el-col
                    ><el-col :md="12"
                        ><el-input v-model="user.address2"></el-input
                    ></el-col>
                </el-row>
            </el-form-item>
            <el-form-item label="携帯電話"
                ><el-input v-model="user.portable_phone"></el-input
            ></el-form-item>
            <el-form-item label="緊急電話"
                ><el-input v-model="user.emergency_phone"></el-input
            ></el-form-item>
            <el-form-item
                ><el-button type="primary" @click="submit()">送信</el-button
                ><el-button @click="cancel()"
                    >キャンセル</el-button
                ></el-form-item
            >
        </el-form>
    </div>
</template>

<script>
export default {
    props: {
        brm: {
            // brm_id
            type: Number,
        },
        id: {
            // user_id: null で新規エントリー
            type: Number,
            default: null,
        },
    },

    data() {
        const checkUnique = (brmId) => {
            return (rule, value, callback) => {
                if (!value) {
                    return callback(
                        new Error("スタート番号を入力してください.")
                    )
                }

                axios
                    .post(`/api/checkUniqueStartNo/${brmId}/${value}`)
                    .then((res) => {
                        if (parseInt(res.data) > 0) {
                            return callback(
                                new Error("スタート番号が重複しています.")
                            )
                        } else {
                            return callback()
                        }
                    })
                    .catch((error) => callback(new Error("サーバーエラー")))
            }
        }

        return {
            user: {
                start_no: null,
                startId: null,
                start_at: "",
                name_kanji: "",
                name_kana: "",
                email: "",
                zip: "",
                prefecture: "",
                address: "",
                address2: "",
                portable_phone: "",
                emergency_phone: "",
                name_roman_last: "",
                name_roman_first: "",
            },

            rules: {
                start_no: [
                    { validator: checkUnique(this.brm), trigger: "blur" },
                    {
                        required: true,
                        message: "入力してください",
                        trigger: "blur",
                    },
                ],
                startId: {
                    required: true,
                    message: "スタート時間を指定してください",
                    trigger: "blur",
                },
                name_kanji: {
                    required: true,
                    message: "入力してください",
                    trigger: "blur",
                },
                email: {
                    required: true,
                    message: "入力してください",
                    trigger: "blur",
                },
            },

            startList: [],

            prefectures: [
                // ネット上で取ってきた都道府県のリスト
                "北海道",
                "青森県",
                "岩手県",
                "宮城県",
                "秋田県",
                "山形県",
                "福島県",
                "茨城県",
                "栃木県",
                "群馬県",
                "埼玉県",
                "千葉県",
                "東京都",
                "神奈川県",
                "新潟県",
                "富山県",
                "石川県",
                "福井県",
                "山梨県",
                "長野県",
                "岐阜県",
                "静岡県",
                "愛知県",
                "三重県",
                "滋賀県",
                "京都府",
                "大阪府",
                "兵庫県",
                "奈良県",
                "和歌山県",
                "鳥取県",
                "島根県",
                "岡山県",
                "広島県",
                "山口県",
                "徳島県",
                "香川県",
                "愛媛県",
                "高知県",
                "福岡県",
                "佐賀県",
                "長崎県",
                "熊本県",
                "大分県",
                "宮崎県",
                "鹿児島県",
                "沖縄県",
            ],
        }
    },

    computed: {
        newEntry() {
            return this.id ? false : true
        },
    },

    methods: {
        submit() {
            if (!this.newEntry) {
                // メンバー（ユーザー）情報変更
                axios
                    .post(`/api/user/${this.id}`, this.user, {
                        headers: { "X-HTTP-Method-Override": "PUT" },
                    })
                    .then(() => {
                        this.$emit("saved")
                    })
            } else {
                // 新規登録
                this.$refs.form.validate((valid) => {
                    if (valid) {
                        this.$message("新規登録できます")
                        // ブルベ情報を取得
                        axios
                            .get("/api/brmstart/" + this.user.startId)
                            .then((res) => {
                                const registData = {
                                    brm: res.data.brm,
                                    data: [
                                        // 一人分の登録データを用意
                                        {
                                            ...this.user,
                                            start_id: this.user.startId, // 変数名が違うので調整
                                            hashKey:
                                                res.data.hashPrefix +
                                                this.user.start_no, // ユーザーID作成用のハッシュの頭はフロントで作るのが面倒なのでバックエンドで作成してもらった.
                                        },
                                    ],
                                }
                                return registData
                            })
                            .then((res) => {
                                return axios.post("/api/register", res)
                            })
                            .then((res) => {
                                if (res.data.status === "ok") {
                                    this.$message("参加者を登録しました")
                                    this.$emit("saved")
                                    return false
                                } else {
                                    this.$message("登録に失敗しました")
                                    return false
                                }
                            })
                    } else {
                        this.$message("必要項目を入力してください")
                        return false
                    }
                })
            }
        },

        cancel() {
            this.$emit("close-dialog")
        },
    },

    created() {
        // スタート時間を取得（新規登録のセレクト用）
        axios.get(`/api/brmstart?brmid=${this.brm}`).then((res) => {
            this.startList = res.data.map((start) => {
                const ts = new Date(start.start_at)
                return {
                    startId: start.id,
                    startTime: `${ts.getHours()}:${(
                        "00" + ts.getMinutes()
                    ).slice(-2)}`,
                }
            })
        })

        // 参加者情報を取得
        if (this.id) {
            axios.get("/api/user/" + this.id).then((res) => {
                const start = new Date(res.data.entry.start.start_at)
                const start_at = `${start.getHours()}:${(
                    "00" + start.getMinutes()
                ).slice(-2)}`

                this.user = {
                    start_no: res.data.entry.start_no,
                    start_at,
                    startId: res.data.entry.start.id,
                    name_kanji: res.data.participant.name_kanji,
                    name_kana: res.data.participant.name_kana,
                    email: res.data.participant.email,
                    zip: res.data.participant.zip,
                    prefecture: res.data.participant.prefecture,
                    address: res.data.participant.address,
                    address2: res.data.participant.address2,
                    portable_phone: res.data.participant.portable_phone,
                    emergency_phone: res.data.participant.emergency_phone,
                    name_roman_last: res.data.participant.name_roman_last,
                    name_roman_first: res.data.participant.name_roman_first,
                }
            })
        }
    },
}
</script>
