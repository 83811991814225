<template>
    <div>
        <el-dialog
            title="ファイルアップロード"
            width="80%"
            :close-on-click-modal="false"
            :close-on-press-escape="false"
            :show-close="false"
            :visible.sync="uploadDialog"
        >
            <el-row type="flex" justify="center">
                <el-upload
                    ref="upload"
                    drag
                    multiple
                    :limit="3"
                    action="false"
                    :auto-upload="false"
                    :on-change="handleAdd"
                    :on-remove="handleRemove"
                    :on-exceed="handleExceed"
                    :file-list="fileList"
                >
                    <i class="el-icon-upload"></i>
                    <div class="el-upload__text">
                        ファイルをドラッグするか <em>クリックしてください</em>
                    </div>
                </el-upload>
            </el-row>

            <el-row type="flex" justify="center">
                <el-button @click="submit">送信</el-button>
                <el-button @click="cancel">キャンセル</el-button>
            </el-row>
        </el-dialog>

        <el-row
            >以下の参加者を登録します.
            <el-button @click="register">DBに登録</el-button>
        </el-row>
        <el-table :data="entryList">
            <el-table-column type="selection" />
            <el-table-column prop="start_no" label="番号" />
            <el-table-column prop="startLabel" label="出走時間" />
            <el-table-column prop="name_kanji" label="氏名" />
        </el-table>

        <el-dialog
            :visible="registerDialog.visible"
            width="40%"
            :close-on-click-modal="false"
            :close-on-press-escape="false"
            :show-close="false"
        >
            <p>データ転送中...</p>
            <p>
                <el-progress
                    type="circle"
                    :percentage="
                        parseInt(
                            (
                                (registerDialog.complete /
                                    registerDialog.total) *
                                100
                            ).toFixed(0)
                        )
                    "
                ></el-progress>
            </p>
            <p>{{ registerDialog.complete }}/{{ registerDialog.total }}</p>
        </el-dialog>
    </div>
</template>

<script>
export default {
    props: { id: Number },
    data() {
        return {
            uploadDialog: true,
            fileList: [],
            brm: [],
            entry: [],
            registerDialog: {
                visible: false,
                total: 1,
                complete: 0,
                error: 0,
                errorMessages: [],
            },
        }
    },
    computed: {
        // hash の作り方
        // substr( sha1( "${acp_code}_${event_date}_${start_time}_${entry_no}"), 0, 8); "600008_20210410_0700_5"
        entryList() {
            return this.entry.map((entry) => {
                const startTs = new Date(
                    `${this.brm.event_date} ${entry.start_time}`
                )
                const startHour = ("00" + startTs.getHours()).slice(-2)
                const startMin = ("00" + startTs.getMinutes()).slice(-2)
                const eventTs = new Date(this.brm.event_date)
                const eventMonth = ("00" + (eventTs.getMonth() + 1)).slice(-2)
                const eventDate = ("00" + eventTs.getDate()).slice(-2)

                return {
                    ...entry,
                    startTs: startTs.getTime(),
                    start_id: this.startTime[startTs.getTime()],
                    startLabel: `${startTs.getHours()}:${(
                        "00" + startTs.getMinutes()
                    ).slice(-2)}`,
                    hashKey: `${
                        this.brm.club_code
                    }-${eventTs.getFullYear()}${eventMonth}${eventDate}-${startHour}${startMin}-${
                        entry.start_no
                    }`,
                }
            })
        },

        startTime() {
            let startObj = {}
            this.brm.starts.forEach((start) => {
                startObj[new Date(start.start_at).getTime()] = start.id
            })
            return startObj
        },
    },

    created() {
        axios.get(`/api/brm/${this.id}`).then((res) => {
            this.brm = res.data
        })
    },
    methods: {
        handleRemove: function (file, fileList) {
            this.fileList = fileList
        },
        // ファイルを追加した時
        handleAdd: function (file, fileList) {
            this.fileList = fileList
        },
        handleExceed() {
            this.$message("アップロードできるファイルは3つまでです")
        },
        submit() {
            let formData = new FormData()
            const config = {
                headers: {
                    "content-type": "multipart/form-data",
                },
            }
            for (let i = 0, len = this.fileList.length; i < len; i++) {
                formData.append(
                    "file" + i,
                    this.fileList[i].raw,
                    this.fileList[i].name
                )
            }

            axios
                .post("/api/upload", formData, {
                    headers: { "content-type": "multipart/form-data" },
                })
                .then((res) => {
                    if (res.data.status === "ok") {
                        this.entry = res.data.data
                        this.uploadDialog = false
                    } else {
                        this.$message("ファイルのデータが取り込めませんでした.")
                        this.$refs.upload.clearFiles()
                    }
                })
        },
        cancel() {
            this.$router.back()
        },
        async register() {
            this.registerDialog.visible = true

            this.registerDialog.total = this.entryList.length
            this.registerDialog.loading = true

            const sleep = () => {
                return new Promise((resolve) =>
                    setTimeout(() => {
                        resolve()
                    }, 50)
                )
            }

            // progress bar を表示するために少しづつデータを送る
            for (const block of _.chunk(this.entryList, 10)) {
                const payload = { brm: this.brm, data: block }

                try {
                    const res = await axios.post("/api/register", payload)

                    if (res.data.status === "error") {
                        ++this.registerDialog.error
                        this.registerDialog.errorMessages.push(res.data.message)
                        this.$message("DB登録エラー")
                    }
                    this.registerDialog.complete += res.data.count
                    if (
                        this.registerDialog.complete ===
                        this.registerDialog.total
                    ) {
                        this.registerDialog.visible = false
                    }
                } catch (error) {
                    if (error.response) {
                        console.log(
                            "register error",
                            error.response.data,
                            block
                        )
                    }
                }
                await sleep()
            }
        },
    },
}
</script>

<style scoped>
</style>
