<!--
    DNF登録ページ
-->
<template>
    <div>
        <el-card>
            <template v-slot:header>{{
                user.role === 3 ? "DNF連絡" : "DNF登録"
            }}</template>
            <p v-if="user.role == 3">
                ここからDNFの手続きができます.
                手続きが完了すれば、本部への電話連絡は不要です.
            </p>
            <el-button
                v-if="user.role == 3 && !formVisible"
                type="primary"
                @click="proceed()"
                >手続きにすすむ</el-button
            >
            <el-form
                v-if="isStaff || formVisible"
                v-model="dnfForm"
                label-width="100px"
            >
                <el-form-item label="スタート番号" class="my-0">
                    <el-input
                        :readonly="user.role === 3"
                        v-model="dnfForm.startNo"
                        @change="change"
                    ></el-input>
                </el-form-item>
                <el-form-item v-if="isStaff" label="氏名" class="my-0">
                    <el-input
                        :readonly="true"
                        v-model="dnfForm.name"
                    ></el-input>
                </el-form-item>
                <el-form-item label="DNF理由" class="my-0">
                    <el-select v-model="dnfForm.reason">
                        <el-option
                            v-for="item in dnfReasons"
                            :key="item"
                            :label="item"
                            :value="item"
                        />
                    </el-select>
                </el-form-item>
                <el-form-item label="ひとこと" class="my-0">
                    <el-input v-model="dnfForm.reason2"></el-input>
                </el-form-item>
                <el-form-item label="報告時刻" class="my-0">
                    <el-time-picker
                        v-model="dnfForm.dnfAt"
                        style="width: 100%"
                    ></el-time-picker>
                </el-form-item>
                <el-form-item>
                    <el-button @click="submit" type="primary">送信</el-button>
                    <el-button @click="cancel">キャンセル</el-button>
                </el-form-item>
            </el-form>
        </el-card>
    </div>
</template>

<script>
import { mapState, mapGetters, mapActions } from "vuex"

export default {
    data() {
        return {
            formVisible: false,
            dnfForm: {
                startNo: null,
                userId: null,
                name: "",
                reason: "",
                reason2: "",
                dnfAt: new Date(),
            },
            dnfReasons: [
                "体力的な問題",
                "機材トラブル",
                "気持ち的な問題",
                "事故などアクシデント",
                "所用による",
                "計画的DNF",
                "その他（一言もどうぞ）",
            ],
        }
    },

    computed: {
        ...mapState(["user", "current"]),
        ...mapGetters(["isStaff"]),
    },

    created() {
        if (this.user.role === 3) {
            this.dnfForm.startNo = this.user.info.entry.start_no
            this.dnfForm.userId = this.user.id
        }
    },

    mounted() {
        if (this.user.role !== 3) return false
        if (this.current.brmId !== this.user.riderBrmId) {
            this.$message({
                message: "あなたは今開催中のブルベに参加していません",
                offset: 50,
                type: "warning",
                onClose: () => (window.location.href = "/"),
            })
        } else if (this.user.riderStatus !== "registered") {
            let message = ""
            switch (this.user.riderStatus) {
                case "dns":
                    message =
                        "あなたはDNSとなっています. 間違っているときは本部まで連絡してください."
                    break
                case "canceled":
                    message =
                        "あなたは受付が取消されています. 間違っているときは本部まで連絡してください."
                    break
                case "finish":
                case "overtime":
                    message = "あなたはゴールしていますよ!!"
                    break
            }

            this.$message({
                message: message,
                offset: 50,
                type: "warning",
                onClose: () => (window.location.href = "/"),
            })
        }
    },

    methods: {
        ...mapActions(["getUserInfo", "getSummary"]),

        proceed() {
            this.formVisible = true
        },

        change() {
            axios
                .get("/api/getentry", {
                    params: {
                        brmId: this.current.brmId,
                        startNo: this.dnfForm.startNo,
                    },
                })
                .then((res) => {
                    this.dnfForm.name = res.data.user.name
                    this.dnfForm.userId = res.data.user.id
                })
        },

        submit() {
            this.$confirm("本当にDNF手続きを行ってよろしいですか?", "Warning", {
                confirmButtonText: "はい",
                cancelButtonText:
                    this.user.role === 3 ? "思いとどまる" : "キャンセル",
                type: "warning",
                center: true,
            })
                .then(() => this.commitDnf())
                .catch(() => this.cancel())
        },
        cancel() {
            if (this.user.role === 3) {
                this.$message({
                    message:
                        "思いとどまってくれてありがとう! 頑張って走ってくださいね。",
                    onClose: () => {
                        location.href = "/"
                    },
                })
            } else {
                this.$message("手続きをキャンセルしました.")
                // this.$router.push({ name: 'home' })
            }
        },
        commitDnf() {
            const dnfInfo = {
                command: "register",
                user_id: this.dnfForm.userId,
                brm_id: this.current.brmId,
                reporter_id: this.user.id,
                reason: this.dnfForm.reason ?? "",
                reason2: this.dnfForm.reason2 ?? "",
                dnf_time: this.dnfForm.dnfAt.toLocaleString(),
            }

            axios
                .post("/api/dnf", dnfInfo)
                .then((res) => {
                    if (this.user.role === 3) {
                        if (res.data.status === "ok") {
                            this.$message({
                                message:
                                    "DNFを受け付けました. お疲れさまでした...",
                                onClose: () => {
                                    this.$message({
                                        message:
                                            "安心してください!! いつでもDNFは取り消せます",
                                        onClose: () => {
                                            location.href = "/"
                                        },
                                    })
                                },
                            })
                        } else {
                            this.$message({
                                message:
                                    "システムのエラーで受け付けられませんでした(本当です...)",
                                onClose: () => {
                                    this.$message({
                                        message:
                                            "何度も失敗するようなら電話連絡をお願いします.",
                                    })
                                },
                            })
                        }
                    } else {
                        if (res.data.status === "ok") {
                            this.$message("DNFを受け付けました.")
                            window.location.href = "/"
                        } else {
                            this.$message("エラーです. 登録できませんでした.")
                            this.$router.back()
                        }
                    }
                    this.getUserInfo()
                    this.getSummary()
                })
                .catch((error) => {})
        },
    },
}
</script>