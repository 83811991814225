<!-- 
    本部からのインフォメーションを表示
-->

<template>
    <el-card>
        <template v-slot:header
            >{{ greetingMsg }}
            <h5 class="d-inline-block">
                <b>{{ user.info.name.replace(/　/, " ") }}</b> さん
            </h5></template
        >
        <el-card v-if="infos.length === 0"
            >お知らせは今のところありません</el-card
        >

        <el-card
            class="mb-2"
            v-else
            v-for="info in formatedInfos"
            :key="info.id"
        >
            <div class="text-dark text-right">{{ info.ts }}</div>
            <div class="bg-info text-white rounded p-2 h5">
                {{ info.subject }}
            </div>
            <div v-for="ln in info._contentLines" :key="ln.index">{{ ln.line }}</div>
        </el-card>
    </el-card>
</template>

<script>
import { mapState, mapGetters, mapActions } from "vuex"

export default {
    data() {
        return {
            greetingMsg: "",
            infos: [],
            timerId: null,
        }
    },

    computed: {
        ...mapState(["user"]),

        formatedInfos() {
            const now = new Date()
            return this.infos.map((info) => {
                const ud = new Date(info.updated_at)
                const day =
                    now.getFullYear() === ud.getFullYear() &&
                    now.getMonth() === ud.getMonth() &&
                    now.getDate() === ud.getDate()
                        ? ""
                        : `${ud.getDate()}日 `
                const ts = `${day}${ud.getHours()}:${(
                    "00" + ud.getMinutes()
                ).slice(-2)}`
                return {
                    ...info,
                    ts,
                    contentLines: info.content.split("__NL__"),
                    _contentLines: info.content.split("__NL__").map((line,index)=>({ line, index }))
                }
            })
        },
    },

    created() {
        this.greeting()
        this.getInfo()

        this.timerId = setInterval(() => this.getInfo(), 300 * 1000)
    },

    mounted() {
        console.log("mounted")
    },

    methods: {
        greeting() {
            const hr = new Date().getHours()

            this.greetingMsg = (function () {
                if (hr < 6) {
                    return "こんばんは"
                }
                if (hr < 12) {
                    return "おはようございます"
                }
                if (hr < 19) {
                    return "こんにちは"
                } else {
                    return "こんばんは"
                }
            })()
        },

        getInfo() {
            axios.get("/api/communication/getinfo").then((res) => {
                this.infos = res.data.infos
            })
        },
    },

    beforeDestroy() {
        clearInterval(this.timerId)
    },
}
</script>
