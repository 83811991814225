<!--
    本人でないときにログアウトさせる. layout 内で使用.
-->
<template>
    <div>
        <a class="dropdown-item" href="#" @click.prevent="click">
            <slot></slot>
        </a>
        <el-dialog
            :visible.sync="visibleDialog"
            width="80%"
            title="お名前確認"
            :append-to-body="true"
        >
            あなたが
            <b>{{ name.replace(/　/, " ") }}</b>
            さんでなければ一旦ログアウトしてブルベカードのQRコードを使って再度アクセスしてください.<br />
            それでもうまく行かないときは申し訳ありませんがQRコードが間違っています.<br />
            DNF連絡などは電話でお願いします. ご迷惑をおかけして申し訳ありません.
            <el-row
                ><el-button type="primary" @click="logout">ログアウト</el-button
                ><el-button @click="visibleDialog = false"
                    >キャンセル</el-button
                ></el-row
            >
        </el-dialog>
    </div>
</template>

<script>
export default {
    data() {
        return {
            visibleDialog: false,
            name: "",
        }
    },

    computed: {
        userName() {
            return this.$store.state.user.info.name
        },
    },

    watch: {
        userName(val) {
            this.name = val
        },
    },

    methods: {
        click() {
            this.visibleDialog = true
        },

        // logout API は POSTリクエストのみの受付
        logout() {
            axios
                .post("/logout")
                .then(() => {
                    window.location.href = "/"
                })
                .catch(() => {
                    window.location.href = "/"
                })
        },
    },
}
</script>
