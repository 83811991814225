<template>
    <div class="row">
        <div class="col-8">
            <finisher-table
                :body-style="bodyStyle"
                :key="finishersCount"
            ></finisher-table>
        </div>
        <div class="col-4">
            <leader-table
                :body-style="bodyStyle"
                :body-height="bodyHeight"
                :key="tableRefresh"
            ></leader-table>
        </div>
    </div>
</template>

<script>
import LeaderTable from "@/components/LeaderTable"
import FinisherTable from "@/components/FinisherTable"

export default {
    components: {
        LeaderTable,
        FinisherTable,
    },

    props: ["bodyStyle", "finishersCount", "tableRefresh", "bodyHeight"],
}
</script>

<style scoped>
</style>