<!--
    セルフフィニッシュ登録ページ
-->
<template>
    <div>
        <el-card>
            <template v-slot:header>
                <h5 class="d-inline-block">
                    <b>{{ riderName }}</b> さん
                </h5>
            </template>
            <p>
                長旅お疲れ様でした。フィニッシュの手続きをします。レシートは用意できていますか?<br />
                フォトチェックがあった場合は後ほど写真を&#x1f4e9;メールで送って下さい。
            </p>
            <el-button v-if="!formVisible" type="primary" @click="proceed()">手続きにすすむ</el-button>
            <el-form v-if="formVisible" v-model="finishForm" label-width="80px" label-position="top" size="mini">
                <el-form-item label="出走番号" class="my-0">
                    <el-input :readonly="true" v-model="finishForm.startNo"></el-input>
                </el-form-item>
                <el-form-item label="スタート" class="my-0">
                    <el-input :readonly="true" v-model="finishForm.startDayTime"></el-input>
                </el-form-item>
                <el-form-item label="フィニッシュ" class="my-1">
                    <el-row>
                        <el-col :span="8"><el-select v-model="finishForm.finishDateZeroTs"
                                :disabled="select.date.length === 1" @change="onDateChange">
                                <el-option v-for="item in select.date" :key="item.ts" :label="item.label"
                                    :value="item.ts"></el-option>
                            </el-select>
                        </el-col>
                        <el-col :span="8"><el-select v-model="finishForm.finishHour">
                                <el-option v-for="item in select.hour" :key="item" :label="item + '時'"
                                    :value="item"></el-option>
                            </el-select>
                        </el-col>
                        <el-col :span="8"><el-select v-model="finishForm.finishMinute">
                                <el-option v-for="item in select.minute" :key="item" :label="item + '分'"
                                    :value="item"></el-option>
                            </el-select>
                        </el-col>
                    </el-row>
                    <el-row class="mt-1">
                        <el-alert style="line-height: 12px" show-icon :closable="false" :title="brmResult.title"
                            :type="brmResult.type" :description="brmResult.description"></el-alert>
                    </el-row>
                </el-form-item>
                <el-form-item v-if="brmResult.type === 'success'" label="メダル希望">
                    <el-radio v-model="finishForm.medal" :label="true">Oui希望します</el-radio>
                    <el-radio v-model="finishForm.medal" :label="false">Non希望しません</el-radio>
                </el-form-item>
                <el-form-item label="コメント" class="my-1">
                    <el-row>
                        <el-input type="textarea" placeholder="ひとことどうぞ" v-model="finishForm.comment"></el-input>
                    </el-row>
                </el-form-item>
                <el-form-item>
                    <el-button :disabled="!brmResult.submittable" @click="submit" type="primary">送信</el-button>
                    <el-button @click="cancel">キャンセル</el-button>
                </el-form-item>
            </el-form>
        </el-card>
    </div>
</template>

<script>
import { mapState, mapGetters, mapActions } from "vuex"
import { calcOpenClose, limitHours } from "@/lib/brevet.js"
import { makeMedalImage } from "@/lib/engrave.js"

export default {
    data() {
        return {
            formVisible: false,
            finishForm: {
                startNo: null,
                userId: null,
                entryId: null,
                startDayTime: "",
                finishYear: 2022,
                finishMonth: 1,
                finishDate: 1,
                finishDateZeroTs: 0, // フィニッシュ日の 0:00:00.000
                finishHour: 0,
                finishMinute: 0,
                medal: false,   // メダル購入希望
                comment: "",
            },
            // セレクター用
            select: {
                date: [],
                hour: [...Array(24)].map((_, i) => i),
                minute: [...Array(60)].map((_, i) => i),
            },
        }
    },

    computed: {
        ...mapState(["user", "current"]),
        ...mapGetters(["isStaff", "riderInfo"]),

        riderName() {
            if (!this.riderInfo) {
                return ""
            }
            return this.riderInfo.displayName ?? ""

        },

        time() {
            const _start = new Date(this.user.info.entry.start.start_at)
            const _startTs = _start.getTime()
            const _finishTs =
                _startTs +
                limitHours.get(this.user.info.entry.brm.distance) * 3600_000
            const _openMs =
                calcOpenClose(this.user.info.entry.brm.distance * 1000).open *
                60_000
            const _openTs = _startTs + _openMs
            const _open = new Date(_openTs)
            const _openZero = new Date(
                _open.getFullYear(),
                _open.getMonth(),
                _open.getDate()
            )
            const _closeMs =
                limitHours.get(this.user.info.entry.brm.distance) * 3600_000

            return {
                _start,
                _startTs,
                _finish: new Date(_finishTs),
                _finishTs,
                _openMs,
                _open,
                _openTs,
                _openZero,
                _closeMs,
            }
        },

        inputFinishAt() {
            return new Date(
                this.finishForm.finishDateZeroTs +
                this.finishForm.finishHour * 3600_000 +
                this.finishForm.finishMinute * 60_000
            )
        },

        brmResult() {
            const brmTs = this.inputFinishAt.getTime() - this.time._startTs

            const hour = Math.floor(brmTs / 3600_000)
            const minute = Math.floor(brmTs / 60_000) % 60

            let type = "success"
            let title = ""
            const time = `${hour}時間${("00" + minute).slice(-2)}分`
            let description = ""
            let submittable = true
            let finish = false  // 完走したか

            if (brmTs < this.time._openMs) {
                type = "error"
                title = "速すぎ～！ まだゴールがオープンしていませんよ"
                submittable = false
            } else if (brmTs > this.time._closeMs) {
                type = "warning"
                title = time
                description = "タイムオーバーですか? 救済の申告などはコメントに"
            } else {
                title = time
                description = "完走おめでとうございます"
                finish = true
            }

            return {
                ms: brmTs,
                hour,
                minute,
                finish,
                type,
                title,
                description,
                submittable,
            }
        },
    },

    watch: {
        user: {
            handler: function (newVal) {
                this.prepare()
            },
            deep: true,
        },
    },

    methods: {
        ...mapActions(["getUserInfo", "getSummary"]),

        proceed() {
            this.formVisible = true
            this.prepare()
        },

        prepare() {
            // フィニッシュ時間選択用の日付リストを作成
            this.select.date.length = 0
            for (let dd = 0; ; dd++) {
                const dateTs =
                    this.time._openZero.getTime() + dd * 24 * 3600_000
                if (dateTs > this.time._finishTs + 12 * 3600_000) {
                    break
                }
                const date = new Date(dateTs)
                this.select.date.push({
                    label: `${date.getDate()}日`,
                    value: date,
                    ts: dateTs,
                })
            }

            const _sameMonth =
                this.time._start.getMonth() === this.time._finish.getMonth() // スタートとゴールが同一月か（これによって表示を変える）
            const _sameDate =
                this.time._start.getDate() === this.time._finish.getDate() // スタートとゴールが同一日か
            this.finishForm.startNo = this.user.info.entry.start_no
            this.finishForm.userId = this.user.id
            this.finishForm.entryId = this.user.info.entry.id
            this.finishForm.startDayTime =
                (!_sameMonth ? `${this.time._start.getMonth() + 1}月` : "") +
                (!_sameDate ? `${this.time._start.getDate()}日 ` : "") +
                `${this.time._start.getHours()}時` +
                `${("00" + this.time._start.getMinutes()).slice(-2)}分`

            const primaryFinishAt =
                this.user.info.entry.finish_at !== null
                    ? new Date(this.user.info.entry.finish_at)
                    : new Date()
            this.finishForm.finishYear = primaryFinishAt.getFullYear()
            this.finishForm.finishMonth = primaryFinishAt.getMonth() + 1
            this.finishForm.finishDate = primaryFinishAt.getDate()
            this.finishForm.finishDateZeroTs = new Date(
                this.finishForm.finishYear,
                this.finishForm.finishMonth - 1,
                this.finishForm.finishDate
            ).getTime()
            this.finishForm.finishHour = primaryFinishAt.getHours()
            this.finishForm.finishMinute = primaryFinishAt.getMinutes()
            // メダル
            this.finishForm.medal = this.user.info.entry.medal === 1
            // コメント
            this.finishForm.comment = this.user.info.entry.comment ?? ""
        },

        onDateChange(ts) {
            const zero = new Date(ts)
            this.finishForm.finishYear = zero.getFullYear()
            this.finishForm.finishMonth = zero.getMonth() + 1
            this.finishForm.finishDate = zero.getDate()
        },

        submit() {
            const h = this.$createElement
            this.$msgbox({
                message: h(
                    "div",
                    {
                        style: {
                            fontWeight: "bold",
                            fontSize: "12px",
                        },
                    },
                    "登録します. よろしいですか?"
                ),
                confirmButtonText: "はい",
                showCancelButton: true,
                cancelButtonText: "キャンセル",
                closeOnClickModal: false,
                center: true,
            })
                .then(() => {
                    this.register()
                })
                .catch(() => console.log("cancel"))
        },

        cancel() {
            this.$router.push({ name: "riderHome" })
        },

        // サーバーに送信
        async register() {
            try {
                const response = await axios({
                    method: "post",
                    url: "/api/selfregister",
                    data: {
                        ...this.finishForm,
                    },
                })
                const status = response.data.status

                const message =
                    status === "registered"
                        ? "フィニッシュ登録しました"
                        : status === "updated"
                            ? "登録内容を更新しました"
                            : "登録内容に変更はありません"

                const h = this.$createElement
                const _rider = this.user.info.participant

                // メダルのイメージを作成
                const canvas = await makeMedalImage(
                    this.current.brmDate.getFullYear(), // Year
                    this.current.brmName.split("_")[1], // Brm Name
                    `${_rider.name_roman_last}, ${_rider.name_roman_first}`, // Name
                    `${this.brmResult.hour}h${(
                        "00" + this.brmResult.minute
                    ).slice(-2)}m` //brmTime
                )

                const medalImg = h(
                    "img",
                    {
                        domProps: {
                            src: canvas.toDataURL("img/png"),
                        },
                        style: {
                            width: "75%",
                            //height: '100%'
                        },
                    },
                    []
                )

                const medalAd = h("div", {}, [
                    h("el-row", {}, [
                        h("el-col", { props: { span: 6 } }, [
                            h("el-tag", {}, "広告"),
                        ]),
                        h(
                            "el-col",
                            {
                                props: { span: 18 },
                                style: { textAlign: "left" },
                            },
                            [
                                h(
                                    "span",
                                    "AR中部謹製 － あなただけの刻印メダルはいかがですか? ブルベカードのメダルにOui!"
                                ),
                            ]
                        ),
                    ]),
                    h("p", {}, [medalImg]),
                ])

                const header = h(
                    "el-alert",
                    {
                        class: {
                            "my-1": true,
                        },
                        props: {
                            title: message,
                            type: "success",
                            closable: false,
                        },
                    },
                    []
                )

                this.$msgbox({
                    message: h(
                        "div",
                        {
                            style: {
                                fontWeight: "bold",
                                fontSize: "12px",
                            },
                        },
                        [header, this.brmResult.finish ? medalAd : null]
                    ),
                    confirmButtonText: "閉じる",
                    showCancelButton: false,
                    closeOnClickModal: false,
                    center: true,
                }).then(() => {
                    location.href = "/"
                })
            } catch (e) { }
        },
    },
}
</script>

<style scoped>
::v-deep .el-card__header {
    padding-top: 15px;
    padding-bottom: 5px;
}

::v-deep .el-form-item__label {
    font-weight: bold;
    font-size: 12px;
    line-height: 16px;
    padding-bottom: 0px !important;
    margin-bottom: 0px;
}
</style>