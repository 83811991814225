var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('h5',{staticClass:"alert alert-dark"},[_vm._v(_vm._s(_vm.brmTitle))]),_vm._v(" "),_c('div',{staticClass:"row mt-5"},[_c('div',{staticClass:"d-none d-lg-block col-lg-6 side"},[_c('el-table',{attrs:{"data":_vm.summary,"border":true,"size":"mini","show-summary":""}},[_c('el-table-column',{attrs:{"label":"","align":"center"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
return [_c('el-tag',{attrs:{"type":row.type}},[_vm._v("\n                            "+_vm._s(row.start_time)+"\n                        ")])]}}])}),_vm._v(" "),_c('el-table-column',{attrs:{"prop":"all","align":"center"},scopedSlots:_vm._u([{key:"header",fn:function(){return [_c('el-tooltip',{attrs:{"content":"「総数」は申込みからキャンセル分を除外"}},[_c('div',[_vm._v("\n                                総数"),_c('br'),_c('span',{staticStyle:{"font-size":"75%"}},[_vm._v("(キャンセル)")])])])]},proxy:true},{key:"default",fn:function(ref){
var row = ref.row;
return [_vm._v("\n                        "+_vm._s(row.all)+"\n                        "),(row.canceled > 0)?[_c('br'),_vm._v("("+_vm._s(row.canceled)+")")]:_vm._e()]}}])}),_vm._v(" "),_c('el-table-column',{attrs:{"label":"DNS","prop":"dns","align":"center"}}),_vm._v(" "),_c('el-table-column',{attrs:{"label":"出走","prop":"rode","align":"center"}}),_vm._v(" "),_c('el-table-column',{attrs:{"prop":"finish","align":"center"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
return [_vm._v("\n                        "+_vm._s(row.finish)),_c('br'),_vm._v("("+_vm._s(row.medal)+")\n                    ")]}}])},[_c('template',{slot:"header"},[_c('div',[_vm._v("完走"),_c('br'),_vm._v("(メダル)")])])],2),_vm._v(" "),_c('el-table-column',{attrs:{"label":"DNF","prop":"dnf","align":"center"}}),_vm._v(" "),_c('el-table-column',{attrs:{"prop":"registered","align":"center"},scopedSlots:_vm._u([{key:"header",fn:function(){return [_c('el-tooltip',{attrs:{"content":"「走行中」になっている参加者数です"}},[_c('span',[_vm._v("その他")])])]},proxy:true}])}),_vm._v(" "),(_vm.brmData.brm.multipurpose_check === 1)?_c('el-table-column',{attrs:{"prop":"multipurpose_check","label":_vm.brmData.brm.multipurpose_check_name,"align":"center"}}):_vm._e()],1)],1),_vm._v(" "),_c('div',{staticClass:"col-12 col-lg-6 leaderboard"},[_c('div',{attrs:{"slot":"header"},slot:"header"},[_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-2"}),_vm._v(" "),_c('el-checkbox',{attrs:{"indeterminate":_vm.isIndeterminate},on:{"change":_vm.handleCheckAllChange},model:{value:(_vm.checkAll),callback:function ($$v) {_vm.checkAll=$$v},expression:"checkAll"}},[_vm._v("すべて")])],1),_vm._v(" "),_c('div',{staticClass:"col-10"},[_c('el-checkbox-group',{on:{"change":_vm.handleCheckedStatusChange},model:{value:(_vm.checkedStatus),callback:function ($$v) {_vm.checkedStatus=$$v},expression:"checkedStatus"}},_vm._l((_vm.statusList),function(s){return _c('el-checkbox',{key:s.status,attrs:{"label":s.status}},[_vm._v(_vm._s(s.name))])}),1)],1)])]),_vm._v(" "),_c('el-table',{staticStyle:{"width":"100%"},attrs:{"data":_vm.list(),"border":true,"size":"mini"},on:{"sort-change":_vm.sort}},[_c('el-table-column',{attrs:{"label":"No","width":"60","prop":"start_no","align":"center","fixed":"","sortable":"custum"}}),_vm._v(" "),_c('el-table-column',{attrs:{"label":"リザルト","width":"100","align":"center"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
return [_c('el-tag',{attrs:{"size":"small","effect":"dark","type":row.status_tag_type}},[_vm._v(_vm._s(row.status_tag))])]}}])}),_vm._v(" "),_c('el-table-column',{attrs:{"label":"氏名","prop":"name"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
return [_vm._v("\n                    "+_vm._s(row.user.name.replace(/[　 ]+/, " "))),_c('i',{class:row.approvedIcon})]}}])}),_vm._v(" "),_c('el-table-column',{attrs:{"width":"100","align":"center","filters":_vm.startTimeList,"filter-method":_vm.filterStartTime},scopedSlots:_vm._u([{key:"header",fn:function(){return [_c('el-tooltip',{attrs:{"content":"絞り込みできます"}},[_c('span',[_vm._v("スタート")])])]},proxy:true},{key:"default",fn:function(ref){
var row = ref.row;
return [_c('el-tag',{attrs:{"type":row.start_time_tag_type,"size":"small"}},[_vm._v(_vm._s(row.start_time_tag))])]}}])}),_vm._v(" "),_c('el-table-column',{attrs:{"label":"ゴール","prop":"finish_at","align":"center","sortable":"custom"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
return [_vm._v("\n                    "+_vm._s(row.finishTime)+"\n                ")]}}])}),_vm._v(" "),_c('el-table-column',{attrs:{"label":"認定","prop":"brevetTime","align":"center","sortable":"custum"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
return [_vm._v("\n                    "+_vm._s(row.brevetTime)+"\n                ")]}}])}),_vm._v(" "),_c('el-table-column',{attrs:{"label":"メダル","align":"center"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
return [(row.medal)?_c('i',{staticClass:"el-icon-medal",staticStyle:{"color":"gold","font-size":"18px"}}):_vm._e()]}}])}),_vm._v(" "),(_vm.brmData.brm.multipurpose_check === 1)?_c('el-table-column',{attrs:{"label":_vm.brmData.brm.multipurpose_check_name,"align":"center"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
return [(row.multipurpose_check)?_c('i',{staticClass:"el-icon-star-on",staticStyle:{"color":"gold","font-size":"18px"}}):_vm._e()]}}],null,false,3416651895)}):_vm._e()],1),_vm._v(" "),_c('el-dialog',{attrs:{"visible":_vm.detailVisible,"width":"90%"},on:{"update:visible":function($event){_vm.detailVisible=$event}}},[_c('EntryStatusEdit',{attrs:{"entry_id":_vm.detailEntryId},on:{"terminate":_vm.onTerminate}})],1)],1)])}
var staticRenderFns = []

export { render, staticRenderFns }