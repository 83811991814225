<!--
    ブルベの登録および編集
-->

<template>
    <div>
        <el-form ref="editForm" :model="brmEdit" :rules="rules" label-width="120px">
            <el-form-item label="オプション">
                <el-row>
                    <el-col :span="4"><el-switch v-model="brmEdit.public" active-text="オン"
                            inactive-text="ブルベ公開"></el-switch></el-col>
                    <el-col :span="4"><el-switch v-model="brmEdit.active" active-text="オン"
                            inactive-text="アクティブ"></el-switch></el-col>
                    <el-col :span="4"><el-switch v-model="brmEdit.closed" active-text="オン"
                            inactive-text="ブルベ終了"></el-switch></el-col>
                    <el-col :span="4"><el-switch v-model="brmEdit.selfgoal" active-text="オン"
                            inactive-text="セルフゴール"></el-switch></el-col>
                    <el-col :span="6"><el-switch v-model="brmEdit.kinenmedal" active-text="記念メダル"
                            inactive-text="通常メダル"></el-switch></el-col>
                </el-row>
                <el-row>
                    <el-col :span="5">
                        <el-switch v-model="brmEdit.multipurpose_check" active-text="使用する"
                            inactive-text="多目的チェック"></el-switch>
                    </el-col>
                    <el-col :span="5" v-if="brmEdit.multipurpose_check">
                        <div style="display:flex">
                            <div style="width:3em;">名称</div><el-input
                                v-model="brmEdit.multipurpose_check_name"></el-input>
                        </div>
                    </el-col>
                </el-row>
            </el-form-item>
            <el-form-item label="主催クラブ">
                <el-col :span="24">
                    <el-select v-model="brmEdit.club" :disabled="brmEdit.registered" style="width: 100%">
                        <el-option v-for="club in clubs" :label="club.name" :value="club.code"
                            :key="club.code"></el-option>
                    </el-select>
                </el-col>
            </el-form-item>
            <el-form-item label="日付" prop="eventDate">
                <el-col :span="12">
                    <el-date-picker type="date" v-model="brmEdit.eventDate" style="width: 100%" @change="setStartDate()"
                        :disabled="brmEdit.registered"></el-date-picker>
                </el-col>
            </el-form-item>
            <el-form-item label="距離" prop="distance">
                <el-col :span="12">
                    <el-select v-model="brmEdit.distance" :clearable="true" style="width: 100%">
                        <el-option label="200km" value="200"></el-option>
                        <el-option label="300km" value="300"></el-option>
                        <el-option label="400km" value="400"></el-option>
                        <el-option label="600km" value="600"></el-option>
                        <el-option label="1000km" value="1000"></el-option>
                    </el-select>
                </el-col>
            </el-form-item>
            <el-form-item label="スタート時間" prop="startTime">
                <el-row>
                    <el-col :span="10">
                        <el-date-picker type="date" v-model="brmEdit.startDate" style="width: 100%"></el-date-picker>
                    </el-col>

                    <el-col :span="7" :offset="1">
                        <el-time-picker v-model="brmEdit.startTime" format="HH:mm" style="width: 100%"
                            prop="startTime"></el-time-picker>
                    </el-col>
                    <el-col :offset="1" :span="5">
                        <el-button @click="addTime">追加</el-button>
                    </el-col>
                </el-row>

                <el-row v-if="brmEdit.start.length">
                    <el-button type="success" round v-for="start in startList" @click="onDelete(start.idx)"
                        :key="start.idx" :disabled="brmEdit.registered && start.startId != null">{{ start.time
                        }}</el-button>
                </el-row>
            </el-form-item>

            <el-form-item label="担当者">
                <el-col :span="12">
                    <el-select v-model="brmEdit.director" :clearable="true" style="width: 100%">
                        <el-option v-for="staff in staffs" :label="staff.name" :value="staff.id"
                            :key="staff.id"></el-option>
                    </el-select>
                </el-col>
            </el-form-item>
            <el-form-item label="サブタイトル">
                <el-input v-model="brmEdit.subtitle" placeholder="キャッチフレーズ" style="width: 100%"></el-input>
            </el-form-item>
            <el-form-item label="リンク">
                <el-input v-model="brmEdit.link" style="width: 100%"></el-input>
            </el-form-item>
            <el-form-item label="ゴール場所" prop="goalplace">
                <el-input v-model="brmEdit.goalplace" style="width: 100%"></el-input>
            </el-form-item>

            <el-form-item>
                <el-button @click="submit" type="primary">送信</el-button>
                <el-button @click="cancel">キャンセル</el-button>
            </el-form-item>
        </el-form>
    </div>
</template>

<script>

export default {
    props: {
        // brmId
        id: { type: Number, default: null },
    },

    data() {
        var validateStartTime = (rule, value, callback) => {
            if (this.brmEdit.start.length === 0) {
                return callback(new Error("スタート時間を指定してください"))
            }
            callback()
        }

        const validateGoalPlace = (rule, value, callback) => {
            if (this.brmEdit.selfgoal === true && value === '') {
                return callback(new Error("セルフゴールのときはゴール地点を入力してください"))
            }
            callback()
        }

        return {
            staffs: [],
            clubs: [],

            brmEdit: {
                brmId: null,
                active: false,
                closed: false,
                public: true,
                club: "",
                eventDate: "",
                distance: null,
                startDate: "",
                startTime: new Date(1970, 0, 1, 0, 0, 0),
                start: [],
                director: "", // 担当者の user_id
                memo: "",
                subtitle: "",
                link: "",
                registered: false, // 参加者がすでに登録されたブルベか
                selfgoal: false,    // 無人ゴールか。参加者自身でゴール処理をしてもらう。
                goalplace: "",  // ゴール地点
                kinenmedal: false,  // 通常メダル: false, 記念メダル: true
                multipurpose_check: false,  // 多目的用チェックを使用するか
                multipurpose_check_name: "",    // 多目的用チェックの名称（例 AJ20周年メダル）
            },

            rules: {
                eventDate: [
                    {
                        required: true,
                        message: "開催日を指定してください",
                        trigger: "blur",
                    },
                ],
                distance: [
                    {
                        required: true,
                        message: "ブルベ距離を指定してください",
                        trigger: "change",
                    },
                ],
                startTime: [
                    {
                        required: true,
                        validator: validateStartTime,
                        trigger: "blur",
                    },
                ],
                goalplace: [
                    {
                        required: true,
                        validator: validateGoalPlace,
                        trigger: "blur",
                    }
                ],
            },
        }
    },

    computed: {
        startList() {
            const ev = this.brmEdit.eventDate
            let list = []

            this.brmEdit.start.forEach((s, idx) => {
                const date =
                    s.ts.getFullYear() === ev.getFullYear() &&
                        s.ts.getMonth() === ev.getMonth() &&
                        s.ts.getDate() === ev.getDate()
                        ? ""
                        : `${s.ts.getMonth() + 1}/${s.ts.getDate()} `

                const time =
                    s.ts.getHours() + ":" + ("00" + s.ts.getMinutes()).slice(-2)

                list.push({
                    idx,
                    time: date + time,
                    ts: s.ts.getTime(),
                    startId: s.startId,
                })
            })
            return _.sortBy(list, "ts")
        },
    },

    created() {
        // 担当者の選択用
        axios
            .get("/api/user/staff")
            .then((response) => {
                this.staffs = response.data
            })
            .catch((error) => console.log(error))

        this.brmEdit.club = this.$store.state.siteOptions.clubCode

        axios
            .get("/api/club")
            .then((response) => {
                this.clubs = response.data
            })
            .catch((error) => console.log(error))

        // 編集モードのとき
        if (this.id) {
            axios.get("/api/brm/" + this.id).then((res) => {
                this.brmEdit = {
                    brmId: this.id, // id が設定されていたら update モード
                    club: res.data.club_code,
                    eventDate: new Date(res.data.event_date),
                    distance: parseInt(res.data.distance),
                    startDate: new Date(res.data.event_date),
                    startTime: new Date(1970, 0, 1, 0, 0, 0),
                    start: [],
                    director: res.data.users_id,
                    memo: "",
                    subtitle: res.data.subtitle,
                    link: res.data.link,
                    registered: res.data.registered > 0, // 登録済みのときはスタート時間の消去はできない
                    closed: res.data.closed > 0,
                    active: res.data.active > 0,
                    public: res.data.public > 0,
                    selfgoal: res.data.selfgoal > 0,
                    goalplace: res.data.goalplace,
                    kinenmedal: res.data.kinenmedal > 0,
                    multipurpose_check: res.data.multipurpose_check > 0,
                    multipurpose_check_name: res.data.multipurpose_check_name,
                }
                this.brmEdit.start = res.data.starts.map((s) => {
                    return { startId: s.id, ts: new Date(s.start_at) }
                })
            })
        }
    },

    methods: {
        setStartDate() {
            this.brmEdit.startDate = this.brmEdit.eventDate
        },

        addTime() {
            const dt = this.brmEdit.startDate
            const tm = this.brmEdit.startTime
            const start = new Date(
                dt.getFullYear(),
                dt.getMonth(),
                dt.getDate(),
                tm.getHours(),
                tm.getMinutes()
            )

            if (
                !this.brmEdit.eventDate ||
                this.brmEdit.eventDate > this.brmEdit.startDate
            ) {
                return false
            }

            if (
                !this.brmEdit.start.some(
                    (s) => s.ts.getTime() === start.getTime()
                )
            ) {
                this.brmEdit.start.push({ startId: null, ts: start })
                return
            }
        },
        onDelete(index) {
            this.brmEdit.start.splice(index, 1)
        },
        submit() {
            this.$refs["editForm"].validate((validated) => {
                if (validated) {
                    if (!this.id) {
                        // 新規登録モード

                        axios
                            .post("/api/brm", this.brmEdit)
                            .then((response) => {
                                if (response.data.status === "ok") {
                                    // 書き込み成功
                                    this.$store.dispatch("updateCurrent")
                                    return this.$router.push("brmList")
                                } else {
                                    // 書き込み失敗
                                    return false
                                }
                            })
                            .catch((error) => {
                                console.log(error)
                                return false
                            })
                    } else {
                        // 編集モード
                        axios
                            .post("/api/brm/" + this.id, this.brmEdit, {
                                headers: { "X-HTTP-Method-Override": "PUT" },
                            })
                            .then((response) => {
                                if (response.data.status === "ok") {
                                    // 書き込み成功
                                    this.$store.dispatch("updateCurrent")
                                    return this.$router.push("brmList")
                                } else {
                                    // 書き込み失敗
                                    return false
                                }
                            })
                            .catch((error) => {
                                console.log(error)
                                return false
                            })
                    }
                } else {
                    // バリデート失敗
                    return false
                }
            })
        },
        cancel() {
            this.$router.push("brmList")
        },
    },
}
</script>

<style scoped></style>
