<!-- 
    Goal Bot で表示する Leader Board
-->

<template>
    <el-card :body-style="bodyStyle" ref="leaderTable">
        <div ref="leaderboard" class="col-12 leaderboard">
            <h5>
                {{ filterName }}
            </h5>
            <table
                ref="tableHeader"
                class="table leadertable"
                style="margin-bottom: 0px"
            >
                <colgroup>
                    <col span="1" style="width: 5em" class="text-center" />
                    <col span="1" style="max-width: 100vw" />
                    <col span="1" style="width: 7em" class="text-center" />
                </colgroup>
                <thead ref="tableHead">
                    <tr>
                        <th>No</th>
                        <th>氏名</th>
                        <th>リザルト</th>
                    </tr>
                </thead>
            </table>
            <div ref="bodyWrapper" class="body-wrapper">
                <table class="table" style="margin-bottom: 0px">
                    <colgroup class="align-middle">
                        <col span="1" style="width: 5em" />
                        <col span="1" style="max-width: 100vw" />
                        <col span="1" style="width: 7em" class="text-center" />
                    </colgroup>
                    <tbody ref="tableBody" class="table-body">
                        <tr v-for="entry in entryList" :key="entry.id">
                            <th style="vertical-align: middle">
                                {{ entry.no || entry.start_no }}
                            </th>
                            <td class="h4" style="vertical-align: middle">
                                {{ entry.name.replace(/[　 ]+/, " ") }}
                            </td>
                            <td
                                v-if="entry.status === 'finish'"
                                style="vertical-align: middle"
                            >
                                {{ entry.brevetTime }}
                            </td>
                            <td v-else style="vertical-align: middle">
                                <el-tag :type="entry.status_tag_type">{{
                                    entry.status_tag
                                }}</el-tag>
                            </td>
                        </tr>
                    </tbody>
                </table>
            </div>
        </div>
    </el-card>
</template>

<script>
import { mapState, mapActions } from "vuex"

const status_tags = {
    canceled: "キャンセル",
    dnf: "DNF",
    dns: "DNS",
    registered: "走行中",
    overtime: "OT",
    finish: "FIN",
}
const status_tag_types = {
    canceled: "",
    dnf: "danger",
    dns: "warning",
    registered: "info",
    overtime: "",
    finish: "success",
}

const statusList = [
    { status: "registered", name: "走行中" },
    { status: "overtime", name: "OT" },
    { status: "finish", name: "完走" },
    { status: "dns", name: "DNS" },
    { status: "dnf", name: "DNF" },
    { status: "canceled", name: "除外" },
]

export default {
    props: ["bodyStyle", "bodyHeight"],

    data() {
        return {
            bodyWrapperObj: null,
            tableBodyObj: null,
            tableBodyHeight: null,

            scrollTop: 0,
            scrolling: false,
            listType: 4,
            scrollSpeed: 1, // スクロールスピードの倍率
            scrollReverse: true, // 反転表示するか

            tableClientRect: {
                top: null,
                height: null,
                width: null,
            },

            list: [],
            filterName: "L E A D E R B O A R D",

            scrollTimer: null,
        }
    },

    computed: {
        ...mapState(["current", "goalBot"]),

        entryList() {
            return this.list.map((entry) => {
                const start = this.current.starts.find((element) => {
                    return element.id == entry.start_id
                })
                const startDay = new Date(entry.start_time).getDate()
                const finishDay = new Date(entry.finish_at).getDate()

                const finishTime =
                    (startDay !== finishDay ? `${finishDay}/ ` : "") +
                    new Date(entry.finish_at).getHours() +
                    ":" +
                    ("00" + new Date(entry.finish_at).getMinutes()).slice(-2)

                const brevetTime =
                    new Date(entry.result_at) - new Date(entry.start_time)
                const brevetTimeMinutes = Math.floor(brevetTime / (60 * 1000))
                const brevetTimeHr = Math.floor(brevetTimeMinutes / 60)
                const brevetTimeMin = brevetTimeMinutes % 60

                return {
                    ...entry,
                    status_tag: status_tags[entry.status],
                    status_tag_type: status_tag_types[entry.status],
                    start_time_tag: start.tag ? start.tag : "",
                    start_time_tag_type: start.type ? start.type : "",
                    finishTime:
                        entry.status === "finish" || entry.status === "overtime"
                            ? finishTime
                            : "",
                    brevetTimeMinutes,
                    brevetTime:
                        entry.status === "finish"
                            ? brevetTimeHr +
                              ":" +
                              ("00" + brevetTimeMin).slice(-2)
                            : "",
                    approvedIcon: entry.approved
                        ? "approved el-icon-circle-check"
                        : "not-approved el-icon-question",
                }
            })
        },
    },

    mounted() {
        console.log("leaderTable mounted")
        this.onResized()
        window.addEventListener("resize", this.onResized)

        this.scrollTimer = setTimeout(this.tableScroll,1000)
    },
    beforeDestroy() {
        window.removeEventListener("resize", this.onResized)
        clearTimeout(this.scrollTimer)
    },
    methods: {
        ...mapActions(["getEntryList"]),

        rowKey(row) {
            return row.id
        },

        listRotate() {
            const entries = this.current.entries.list
            const type = this.listType % 4

            if (type === 0) {
                // すべて・番号順
                const filtered = entries
                    .filter((entry) => {
                        return entry.status !== "canceled"
                    })
                    .sort((a, b) => a.start_no - b.start_no)
                this.list = filtered
                this.filterName = "ALL ENTRIES（DNS含む）"
                this.scrollSpeed = 2.5
                this.scrollReverse = false
            } else if (type === 2) {
                // すべて・50音順
                const filtered = entries
                    .filter((entry) => {
                        return (
                            entry.status !== "canceled" &&
                            entry.status !== "dns"
                        )
                    })
                    .sort((a, b) => a.start_no - b.start_no)
                this.list = filtered
                this.filterName = "ALL RIDERS（DNS除く）"
                this.scrollSpeed = 2
                this.scrollReverse = true
            } else if (type === 1 || type === 3) {
                // LEADER BOARD
                const filtered = entries.filter((entry) => {
                    return entry.status === "finish"
                })
                const sorted = filtered
                    .map((entry) => {
                        const brevetTime =
                            new Date(entry.result_at) -
                            new Date(entry.start_time)
                        const brevetTimeMinutes = Math.floor(
                            brevetTime / (60 * 1000)
                        )
                        const brevetTimeHr = Math.floor(brevetTimeMinutes / 60)
                        const brevetTimeMin = brevetTimeMinutes % 60
                        return { ...entry, brevetTimeMinutes }
                    })
                    .sort((a, b) => a.brevetTimeMinutes - b.brevetTimeMinutes)
                this.list = sorted.map((entry, idx) => ({
                    ...entry,
                    no: idx + 1,
                }))
                this.filterName = "L E A D E R B O A R D"
                this.scrollSpeed = 1
                this.scrollReverse = true
            }
            ++this.listType
            this.onResized()
        },

        onResized() {
            setTimeout(() => {

                this.bodyWrapperObj = this.$refs.bodyWrapper
                this.tableBodyObj = this.$refs.tableBody
                const wholeHeight = this.$refs.leaderTable.$el.getBoundingClientRect().height
                const top = this.$refs.tableHeader.getBoundingClientRect().top
                const bodyTop = this.$refs.tableBody.getBoundingClientRect().top
                this.tableHeight =wholeHeight - (bodyTop - top) - 40
                this.$refs.bodyWrapper.style.height = `${this.tableHeight}px`
            }, 10)
        },

        wait(ms) {
            return new Promise((resolve) => {
                setTimeout(() => resolve(), ms)
            })
        },

        async tableScroll() {

            if (this.scrollTimer) {
                clearTimeout(this.scrollTimer)
                this.scrollTimer = null
            }

            this.listRotate()
            await this.wait(3000)

            const tableBodyHeight =
                this.$refs.tableBody.getBoundingClientRect().height

            if (this.tableHeight > tableBodyHeight) {
                console.log('scroll mode')
                this.scrollTimer = setTimeout(this.tableScroll,1000)
                return
            }

            const scrollRange = tableBodyHeight - this.tableHeight

            let scrolled = 0
            this.$refs.bodyWrapper.scrollTo({ top: 0, left: 0 })

            // forward
            while (scrolled < scrollRange) {
                this.$refs.bodyWrapper.scrollTo({ top: scrolled, left: 0 })
                await this.wait(30)
                scrolled += 2 * this.scrollSpeed
            }
            await this.wait(3000)

            // backward
            if (this.scrollReverse) {
                while (scrolled > 0) {
                    this.$refs.bodyWrapper.scrollTo({ top: scrolled, left: 0 })
                    await this.wait(30)
                    scrolled -= 3 * this.scrollSpeed
                }
                this.$refs.bodyWrapper.scrollTo({ top: 0, left: 0 })
                await this.wait(3000)
            }

            this.scrollTimer = setTimeout(this.tableScroll, 1000)
        },
    },
}
</script>

<style scoped>
.body-wrapper {
    display: block;
    overflow: hidden;
}

.table-body tr:nth-child(1) th,
.table-body tr:nth-child(1) td {
    border-top-width: 0px;
}
</style>
