var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"row"},[_c('div',{staticClass:"d-none d-lg-block col-lg-3 side"},[_c('count-down'),_vm._v(" "),_c('el-divider'),_vm._v(" "),_c('brm-summary',[_c('template',{slot:"title"},[_c('h5',[_vm._v("ブルベサマリー")])])],2)],1),_vm._v(" "),_c('div',{staticClass:"col-12 col-lg-9 leaderboard"},[_c('div',{attrs:{"slot":"header"},slot:"header"},[_c('h5',[_vm._v("\n                リーダーボード\n                "),_c('el-tooltip',{staticClass:"item",attrs:{"effect":"dark","content":"DBに問い合わせて更新します","placement":"right"}},[_c('el-button',{staticClass:"ml-2",attrs:{"type":"info","size":"mini","icon":_vm.updateIcon,"circle":""},on:{"click":_vm.update}})],1)],1),_vm._v(" "),_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-3 col-md-3"},[_c('el-checkbox',{attrs:{"indeterminate":_vm.isIndeterminate},on:{"change":_vm.handleCheckAllChange},model:{value:(_vm.checkAll),callback:function ($$v) {_vm.checkAll=$$v},expression:"checkAll"}},[_vm._v("すべて")])],1),_vm._v(" "),_c('div',{staticClass:"col-9 col-md-7"},[_c('el-checkbox-group',{on:{"change":_vm.handleCheckedStatusChange},model:{value:(_vm.checkedStatus),callback:function ($$v) {_vm.checkedStatus=$$v},expression:"checkedStatus"}},_vm._l((_vm.statusList),function(s){return _c('el-checkbox',{key:s.status,attrs:{"label":s.status}},[_vm._v(_vm._s(s.name))])}),1)],1)])]),_vm._v(" "),_c('el-table',{staticStyle:{"width":"100%"},attrs:{"data":_vm.list(),"border":true,"size":"mini"},on:{"sort-change":_vm.sort,"header-click":_vm.headerClick}},[_c('el-table-column',{attrs:{"label":"No","width":"60","prop":"start_no","align":"center","fixed":"","sortable":"custum"}}),_vm._v(" "),_c('el-table-column',{attrs:{"label":"リザルト","width":"100","align":"center"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
return [_c('el-button',{attrs:{"size":"mini","type":row.status_tag_type},on:{"click":function($event){return _vm.btnClick(row)}}},[_vm._v(_vm._s(row.status_tag))])]}}])}),_vm._v(" "),_c('el-table-column',{attrs:{"label":"氏名","prop":"name"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
return [_vm._v("\n                    "+_vm._s(row.name.replace(/[　 ]+/, " "))),_c('i',{class:row.approvedIcon})]}}])}),_vm._v(" "),_c('el-table-column',{attrs:{"label":"スタート","width":"80","align":"center"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
return [_c('el-tag',{attrs:{"type":row.start_time_tag_type}},[_vm._v(_vm._s(row.start_time_tag))])]}}])}),_vm._v(" "),_c('el-table-column',{attrs:{"label":"ゴール","prop":"finish_at","align":"center","sortable":"custom"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
return [_vm._v("\n                    "+_vm._s(row.finishTime)+"\n                ")]}}])}),_vm._v(" "),_c('el-table-column',{attrs:{"label":"認定","prop":"brevetTime","align":"center","sortable":"custum"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
return [_vm._v("\n                    "+_vm._s(row.brevetTime)+"\n                ")]}}])}),_vm._v(" "),_c('el-table-column',{attrs:{"label":"メダル","align":"center","width":"80px"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
return [(_vm.current.kinenmedal && row.medal)?[_c('el-popconfirm',{attrs:{"title":"記念メダル用ですか","confirm-button-text":"はい","cancel-button-text":"いいえ"},on:{"confirm":function($event){return _vm.downloadEngraveImage(row, true)},"cancel":function($event){return _vm.downloadEngraveImage(row, false)}}},[(row.medal)?_c('el-button',{attrs:{"slot":"reference","type":"warning","icon":"el-icon-medal"},slot:"reference"}):_vm._e()],1)]:[(row.medal)?_c('el-button',{attrs:{"slot":"reference","type":"warning","icon":"el-icon-medal"},on:{"click":function($event){return _vm.downloadEngraveImage(row, false)}},slot:"reference"}):_vm._e()]]}}])}),_vm._v(" "),(_vm.current.multipurpose_check === 1)?_c('el-table-column',{attrs:{"label":_vm.current.multipurpose_check_name,"align":"center"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
return [(row.multipurpose_check === 1)?_c('b',{staticClass:"el-icon-star-on",staticStyle:{"color":"gold","font-size":"18px"}}):_vm._e()]}}],null,false,3149251248)}):_vm._e(),_vm._v(" "),(_vm.viewComment)?_c('el-table-column',{attrs:{"label":"コメント","align":"center"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
return [_c('p',{staticClass:"text-left",domProps:{"innerHTML":_vm._s(row.cellComment)}})]}}],null,false,1154356835)}):_vm._e()],1),_vm._v(" "),_c('el-dialog',{attrs:{"visible":_vm.detailVisible,"width":"90%"},on:{"update:visible":function($event){_vm.detailVisible=$event}}},[_c('EntryStatusEdit',{attrs:{"entry_id":_vm.detailEntryId},on:{"terminate":_vm.onTerminate}})],1),_vm._v(" "),_c('el-dialog',{attrs:{"visible":_vm.anonymousMedal,"width":"70%"},on:{"update:visible":function($event){_vm.anonymousMedal=$event}},scopedSlots:_vm._u([{key:"title",fn:function(){return [_c('h3',[_vm._v("メダル作成")]),_vm._v(" "),_c('p',[_vm._v("任意のメダルを作成します")])]},proxy:true}])},[_vm._v(" "),_c('anonymous-medal',{on:{"close":_vm.closeAnonymousMedal}})],1)],1)])}
var staticRenderFns = []

export { render, staticRenderFns }