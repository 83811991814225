/* 
 * Vuex store 設定
 */

import Vue from 'vue'
import Vuex from 'vuex'
import axios from 'axios'
import createPersistedState from 'vuex-persistedstate'

import goalBotStore from '@/store/goalBotStore.js'
import voiceStore from '@/store/voiceStore.js'

Vue.use(Vuex)

export default new Vuex.Store({

    plugins: [
        createPersistedState(
            {
                key: 'archubuBrmApp',
                storage: window.localStorage,
                paths: ['siteOptions', 'current',
                    'goalBot.fixedGreetings', 'voice.queue', 'voice.lastSpoke',
                    'goalBot.gmapView.weatherCache'
                ]
            }
        )],

    modules: {
        goalBot: goalBotStore,
        voice: voiceStore,
    },

    state: {

        reloadCount: 5, // gmapView に切り替わるたびに減らしていってアプリをリロードする

        siteOptions: {
            appName: 'AR中部ブルベ',
            clubCode: 600008,
            email: 'ar.chubu@gmail.com'
        },

        current: {
            brmId: null,
            brmDate: null,
            closed: null,
            selfgoal: null,
            brmTitle: '',
            brmName: '',
            brmDistance: null,
            brmTimeLimit: null, // in hours
            lastUpdate: null,
            starts: [],
            link: '',
            goalplace: '',  // セルフゴール時 ライダーの説明に表示するために使用
            kinenmedal: null,
            responseDelay: null,    // network の遅延時間（往復の時間の半分） millisecond
            clientClockDifference: null,    // サーバーの時計とのずれ
            multipurpose_check: false,  // 多目的チェックの有無
            multipurpose_check_name: "",    // 多目的チェックの名称

            summary: {
                updated_at: null,
                title: '',
                total: null,
                canceled: null,
                registered: null,
                dns: null,
                dnf: null,
                finish: null,
                overtime: null,
                medal: null
            },

            entries: {
                updated_at: null,
                last_query_at: null,
                list: []
            }
        },

        user: {
            id: null,
            role: null,
            systemAdmin: false,     // role: 10
            staffHigher: false,     // role: 9
            staffModerate: false,   // role: 7
            staff: false,           // role: 5
            bot: false,             // role: 4
            rider: false,           // role: 3
            riderBrmId: null,
            riderStatus: '',         // registered, canceled, dns, dnf, finish, overtime
            info: {}
        },

        dimension: {
            width: null,
            height: null,
            menuHeight: null
        },
        communications: {
            updated_at: null,
            last_read_at: null,
            last_query_at: null,
            current_brm: true, // current true:ブルベに限定, false: すべて
            max_messages: 100,  // 最大メッセージ数（一回の query で取ってくる数）
            messages: []
        },
    },

    getters: {
        siteOptions(state) {
            return state.siteOptions
        },
        isSystemAdmin(state) {
            return state.user.systemAdmin
        },
        isStaffHigher(state) {
            return state.user.staffHigher
        },
        isStaff(state) {
            return state.user.staff
        },
        isBot(state) {
            return state.user.bot
        },
        isRider(state) {
            return state.user.rider
        },
        // 現在のブルベのサマリー
        summary(state) {
            const u = new Date(state.current.summary.updated_at)
            const ud = {
                year: u ? u.getFullYear() : null,
                month: u.getMonth() + 1,
                day: u.getDate(),
                hour: u.getHours(),
                minute: u.getMinutes(),
                second: u.getSeconds()
            }

            return {
                ...state.current.summary,
                updatedAt: {
                    ...ud,
                    format: `${ud.month}月${ud.day}日 ${ud.hour}時${('00' + ud.minute).slice(-2)}分 現在`
                }
            }
        },

        detailedSummary(state) {
            const obj = {}
            obj.updated_at = state.current.entries.updated_at
            obj.last_query_at = state.current.entries.last_query_at
            obj.starts = []

            state.current.starts.forEach(s => {
                const sId = s.id
                const sEntries = state.current.entries.list.filter(e => e.start_id === sId)
                const registered = sEntries.reduce((total, rider) => {
                    return rider.status === 'registered' ? ++total : total
                }, 0)
                const dns = sEntries.reduce((total, rider) => {
                    return rider.status === 'dns' ? ++total : total
                }, 0)
                const dnf = sEntries.reduce((total, rider) => {
                    return rider.status === 'dnf' ? ++total : total
                }, 0)
                const finish = sEntries.reduce((total, rider) => {
                    return rider.status === 'finish' ? ++total : total
                }, 0)
                const overtime = sEntries.reduce((total, rider) => {
                    return rider.status === 'overtime' ? ++total : total
                }, 0)
                const canceled = sEntries.reduce((total, rider) => {
                    return rider.status === 'canceled' ? ++total : total
                }, 0)
                const medal = sEntries.reduce((total, rider) => {
                    return rider.medal === 1 ? ++total : total
                }, 0)

                obj.starts.push({
                    ...s,
                    entries: sEntries,
                    registered,
                    dns,
                    dnf,
                    finish,
                    overtime,
                    canceled,
                    medal
                })
            })

            return obj
        },

        currentBrm(state) {
            return state.current
        },

        reloadCount(state) {
            return state.reloadCount
        },

        // 参加者用ページで使うデータを集約
        riderInfo(state) {
            if (!state.user.rider) {
                return null
            }

            const status = state.user.info.entry.status ?? null

            return ({
                status,
                displayName: state.user.info.name.replace(/　/, " ") ?? '',
                isSelfGoal: state.current.selfgoal,
                selfGoalPlace: state.current.goalplace,
                isApproved: state.user.info.approved ?? 0,
            })

        },
        isSelfGoal(state) {
            return state.current.selfgoal === 1 ? true : false
        },

        mailtoLink(state) {
            const address = state.siteOptions.email
            const start_no = state.user.info.entry?.start_no
            const rider = state.user.info.name
            const subject = `[${state.current.brmTitle}] No.${start_no} ${rider}`
            const body = `[参加者] No.${start_no} ${rider}\r\n\r\n` +
                `フォトチェックの写真などをこのメールに添付してお送りください。\r\n集計の都合上メールタイトルは書き換えないでください。\r\n\r\n` +
                `----- 以下ご自由にどうぞ ----- \r\n\r\n` +
                `(例「フォトチェックの写真です」など)`
            const mailto = `mailto:${address}?subject=${subject}&body=${body}`
            return encodeURI(mailto)
        }


    },

    mutations: {

        setCurrentBrm(state, response) {

            const responseDelay = (response.endTs - response.startTs - (response.server_end_ts - response.server_start_ts)) / 2

            state.current.responseDelay = responseDelay
            state.current.clientClockDifference = response.startTs + responseDelay - response.server_start_ts

            if (response.status === 'none') {
                state.current = {
                    brmId: null,
                    closed: null,
                    selfgoal: null,
                    goalplace: '',
                    kinenmedal: null,
                    brmTitle: '',
                    brmName: '',
                    brmDistance: null,
                    brmDate: null,
                    brmStartDateTime: null,
                    brmTimeLimit: null, // in hours
                    lastUpdate: null,
                    multipurpose_check: false,
                    multipurpose_check_name: "",
                    starts: [],
                    summary: {
                        updated_at: null,
                        title: '',
                        total: null,
                        canceled: null,
                        registered: null,
                        dns: null,
                        dnf: null,
                        finish: null,
                        overtime: null
                    },
                    entries: {
                        updated_at: null,
                        last_query_at: null,
                        list: []
                    }
                }
            }

            else if (response.status === 'ok') {

                const data = response.current
                const ts = new Date(data.event_date)

                if (state.current.brmId !== data.id) {  // ブルベが変わった. エントリーリストをクリア
                    state.current.entries = {
                        updated_at: null,
                        last_query_at: null,
                        list: []
                    }
                }

                state.current.brmId = data.id
                state.current.closed = data.closed
                state.current.selfgoal = data.selfgoal
                state.current.goalplace = data.goalplace
                state.current.kinenmedal = data.kinenmedal
                state.current.brmDate = ts
                state.current.link = data.link ?? ''
                state.current.brmDistance = data.distance
                state.current.brmTimeLimit = ({ 200: 13.5, 300: 20, 400: 27, 600: 40, 1000: 75 })[data.distance]
                state.current.brmTitle = `BRM${ts.getMonth() + 1}${('00' + ts.getDate()).slice(-2)} ${data.distance}km`
                state.current.brmName = `${ts.getFullYear()}_BRM${ts.getMonth() + 1}${('00' + ts.getDate()).slice(-2)}_${data.distance}km`
                state.current.lastUpdate = new Date()
                state.current.starts = data.starts.map((start, index) => {
                    const start_time = new Date(start.start_at)
                    const close_time = new Date(start_time.getTime() + state.current.brmTimeLimit * 3600 * 1000)
                    const tag = `${start_time.getHours()}:${('00' + start_time.getMinutes()).slice(-2)}`
                    const type = (['', 'success', 'danger', 'warning', 'info'])[index % 5]
                    return { id: start.id, start_time, close_time, tag, type }
                })
                const startDT = data.starts.reduce((ts, start) => {
                    const currentTs = new Date(start.start_at).getTime()
                    return currentTs < ts ? currentTs : ts
                }, new Date('2999/12/31').getTime())
                state.current.brmStartDateTime = new Date(startDT)
                state.current.multipurpose_check = data.multipurpose_check
                state.current.multipurpose_check_name = data.multipurpose_check_name
            }
        },

        setUserInfo(state, data) {

            state.user.id = data.id
            state.user.role = data.role
            state.user.systemAdmin = data.role >= 10 ? true : false
            state.user.staffHigher = data.role >= 9 ? true : false
            state.user.staffModerate = data.role >= 7 ? true : false
            state.user.staff = data.role >= 5 ? true : false
            state.user.bot = data.role == 4 ? true : false
            state.user.rider = data.role == 3 ? true : false
            if (data.entry) {
                state.user.riderBrmId = data.entry.brm_id ?? null
                state.user.riderStatus = data.entry.status ?? ''
            }
            state.user.info = data

        },

        setSummary(state, data) {
            const summary = state.current.summary
            summary.updated_at = new Date()
            summary.title = data.brm
            summary.total = data.total
            summary.canceled = data.canceled
            summary.registered = data.registered
            summary.dns = data.dns
            summary.dnf = data.dnf
            summary.finish = data.finish
            summary.overtime = data.overtime
            summary.medal = data.medal
        },

        setEntryList(state, data) {

            const entries = state.current.entries
            const summary = state.current.summary

            entries.updated_at = data.updated_at
            entries.last_query_at = new Date()

            data.entry_list.forEach(entry => {
                const entryObj = {
                    id: entry.id,   // ユーザーID   (ハッシュではない 'users'テーブルのprimary key)
                    name: entry.user.name,  // 漢字名
                    kana_name: entry.user.participant.name_kana,    // かな名　読み上げ用
                    start_no: entry.start_no,   // 出走番号（各ブルベでユニーク）
                    start_id: entry.start.id,
                    start_time: entry.start.start_at,
                    status: entry.status,
                    finish_at: entry.finish_at,
                    result_at: entry.result_at,
                    name_roman_last: entry.user.participant.name_roman_last,
                    name_roman_first: entry.user.participant.name_roman_first,
                    sex: entry.user.participant.sex,
                    medal: entry.medal,
                    approved: entry.approved,
                    approved_by: entry.approved_by,
                    updated_at: entry.updated_at,// リストのソート用
                    comment: entry.comment,
                    multipurpose_check: entry.multipurpose_check,
                }

                const idx = entries.list.findIndex(element => { return element.id == entry.id }) // エントリーリストにダウンロードしてきたデータがあるか

                if (idx !== -1) {   // 見つからなければ arr.findIndex は -1 を返す→リストにすでにある→update
                    entries.list.splice(idx, 1, entryObj)
                } else {
                    entries.list.push(entryObj)
                }
            })

            // スタッフはエントリーデータをリアルタイムで持っているのでそこからサマリーを出す。
            if (state.user.staff) {
                summary.updated_at = new Date()
                summary.total = entries.list.length
                summary.canceled = entries.list.reduce((total, e) => e.status === 'canceled' ? ++total : total, 0)
                summary.registered = entries.list.reduce((total, e) => e.status === 'registered' ? ++total : total, 0)
                summary.dns = entries.list.reduce((total, e) => e.status === 'dns' ? ++total : total, 0)
                summary.dnf = entries.list.reduce((total, e) => e.status === 'dnf' ? ++total : total, 0)
                summary.finish = entries.list.reduce((total, e) => e.status === 'finish' ? ++total : total, 0)
                summary.overtime = entries.list.reduce((total, e) => e.status === 'overtime' ? ++total : total, 0)
                summary.medal = entries.list.reduce((total, e) => e.medal === 1 ? ++total : total, 0)
            }


        },

        setCommunications(state, data) {
            const coms = state.communications

            coms.last_query_at = new Date()
            coms.messages = data.messages
        },

        setWindowSize(state, size) {
            state.dimension.width = size.width
            state.dimension.height = size.height
            state.dimension.menuHeight = size.menuHeight
        },

        setEveryStart(state, arr) {
            state.goalBot.everyStart = arr
        },

        decrementReloadCount(state) {
            state.reloadCount -= 1
        },

        resetReloadCount(state, num) {
            state.reloadCount = num
        }

    },

    actions: {

        async updateCurrent({ state, commit }) {

            const startTs = Date.now()      // サーバーとの時計のズレを計測するためのタイムスタンプ
            const response = await axios.get('/api/current')
            const endTs = Date.now()        // ≒ APIからレスポンスがあったタイムスタンプ

            commit('setCurrentBrm', Object.assign({}, response.data, { startTs, endTs }))

        },

        getUserInfo({ state, commit }) {
            // /aip/user : Route::resource('/user', 'UserController');
            axios.get('/api/user')
                .then(res => {
                    // console.log(res.data)
                    commit('setUserInfo', res.data)
                })
                .catch(error => {
                    commit('setUserInfo', { id: null, role: null })
                    if (error.response) {
                        console.error(error.response.data)
                    }
                })
        },

        async getSummary({ state, commit }) {
            if (!state.current.brmId) return false

            await axios.get('/api/summary/' + state.current.brmId)
                .then(res => {
                    commit('setSummary', res.data)
                })
                .catch(error => {
                    if (error.response) {
                        console.error(error.response.data)
                    }
                })
        },

        // 差分だけ取り込むつもりだが現在はすべて取り込むようにしている。
        async getEntryList({ state, commit }) {

            if (!state.current.brmId) return false

            await axios.get('/api/entrylist/' + state.current.brmId, {
                params: { update: null /* state.current.entries.updated_at*/ /* null */ }
            })

                .then(res => {
                    commit('setEntryList', res.data)
                })
        },

        async getCommunications({ state, commit }) {

            await axios.get('/api/communication', {
                params: {
                    updated_at: state.communications.updated_at,
                    brm_id: state.communications.current_brm ? state.current.brmId : null,
                    max_messages: state.communications.max_messages
                }
            })
                .then(res => {
                    commit('setCommunications', res.data)
                })
        },

        getWindowSize({ commit }) {

            const brmNavTop = document.getElementById('brm-nav-top').getBoundingClientRect()
            const brmNavObj = document.getElementById("brm-nav")

            const brmNav = brmNavObj ? brmNavObj.getBoundingClientRect() : { height: 0 }

            const size = {
                menuHeight: brmNavTop.height + brmNav.height,
                width: window.innerWidth,
                height: window.innerHeight

            }
            commit('setWindowSize', size)
        },

        checkReload({ commit, getters }) {
            commit('decrementReloadCount')
            return getters.reloadCount === 0 ? true : false
        },

        resetReloadCount({ commit }, initNum = 5) {
            commit('resetReloadCount', initNum)
        }
    }
})
