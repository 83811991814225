<template>
    <div>
        <el-row><el-button @click="newEntry()">新規登録</el-button></el-row>
        <el-table :data="entryList" size="mini">
            <el-table-column type="expand">
                <template v-slot="{ row }">
                    <table class="table">
                        <tr>
                            <th>住所</th>
                            <td>{{ row.address }}</td>
                        </tr>
                        <tr>
                            <th>携帯電話</th>
                            <td>
                                {{ row.portable }}
                                <a
                                    class="d-md-none"
                                    :href="'tel:' + row.portable"
                                    ><i class="el-icon-phone-outline"></i
                                ></a>
                            </td>
                        </tr>
                        <tr>
                            <th>緊急電話</th>
                            <td>
                                {{ row.emergency }}({{ row.relationship }})
                                <a
                                    class="d-md-none"
                                    :href="'tel:' + row.emergency"
                                    ><i class="el-icon-phone-outline"></i
                                ></a>
                            </td>
                        </tr>
                        <tr>
                            <th>メール</th>
                            <td>
                                {{ row.user.email }}
                                <a :href="'mailto:' + row.user.email"
                                    ><i class="el-icon-postcard"></i
                                ></a>
                            </td>
                        </tr>
                        <tr>
                            <th>
                                QR<br />
                                <pre>{{ row.user.userid }}</pre>
                            </th>
                            <td>
                                <qriously
                                    :value="`https://brm.ar-chubu.org/${row.user.userid}`"
                                    :size="200"
                                ></qriously>
                            </td>
                        </tr>
                    </table>
                </template>
            </el-table-column>
            <el-table-column prop="user_id" label="ID" width="100" />
            <el-table-column prop="start_no" label="番号" width="50" />
            <el-table-column label="スタート" width="60">
                <template v-slot="{ row }">
                    <el-tag type="success">{{ row.startLabel }}</el-tag>
                </template>
            </el-table-column>
            <el-table-column label="氏名" width="100">
                <template v-slot="{ row }">
                    {{ row.user.name }}
                    <span
                        v-if="row.user.participant.sex === 'F'"
                        style="color: red"
                        >F</span
                    >
                </template>
            </el-table-column>
            <el-table-column>
                <template v-slot="{ row }">
                    <el-button @click="edit(row.user_id)">編集</el-button>
                </template>
            </el-table-column>
        </el-table>

        <el-dialog
            :visible.sync="editVisible"
            :key="'key' + editUserId"
            width="80%"
        >
            <participant-edit
                :brm="id"
                :id="editUserId"
                @close-dialog="editVisible = false"
                @saved="saved()"
            />
        </el-dialog>
    </div>
</template>

<script>
import ParticipantEdit from "@/components/ParticipantEdit.vue"

export default {
    props: {
        id: Number,
    },

    components: {
        ParticipantEdit,
    },

    data() {
        return {
            entry: [],
            editVisible: false,
            editUserId: null,
        }
    },
    computed: {
        entryList() {
            return this.entry.map((entry) => {
                const ts = new Date(entry.start.start_at)
                const startLabel = `${ts.getHours()}:${(
                    "00" + ts.getMinutes()
                ).slice(-2)}`

                return {
                    ...entry,
                    startLabel,
                    address: `${entry.user.participant.zip ?? ""} ${
                        entry.user.participant.prefecture
                    }${entry.user.participant.address}${
                        entry.user.participant.address2 ?? ""
                    }`,
                    portable: entry.user.participant.portable_phone ?? "",
                    emergency: entry.user.participant.emergency_phone ?? "",
                    relationship:
                        entry.user.participant.emergency_relationship ?? "",
                }
            })
        },
    },

    created() {
        this.getEntryList()
    },
    methods: {
        newEntry() {
            console.log("新規登録")
            this.editUserId = null
            this.editVisible = true
        },
        edit(id) {
            console.log("ユーザー編集id: %d", id)
            this.editUserId = id
            this.editVisible = true
        },
        saved() {
            this.editVisible = false
            this.getEntryList()
        },
        getEntryList() {
            axios.get(`/api/entrylist/${this.id}`).then((res) => {
                this.entry = res.data.entry_list
            })
        },
    },
}
</script>

<style scoped>
</style>
