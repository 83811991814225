import { render, staticRenderFns } from "./BrmEdit.vue?vue&type=template&id=08fffc33&scoped=true&"
import script from "./BrmEdit.vue?vue&type=script&lang=js&"
export * from "./BrmEdit.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "08fffc33",
  null
  
)

export default component.exports