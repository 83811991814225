<template>
    <div>
        <el-row
            ><el-button @click="newBrm()" type="primary" plain
                >新規登録</el-button
            ></el-row
        >

        <el-table
            class="brm-list"
            :data="brmList"
            style="width: 100%"
            size="mini"
            :row-class-name="rowClass"
        >
            <el-table-column type="expand">
                <template v-slot="{ row }">
                    <table
                        class="
                            table
                            table-borderless
                            table-success
                            table-brm-detail
                        "
                    >
                        <tr>
                            <th>BRM ID</th>
                            <td>{{ row.brm_id }}</td>
                        </tr>
                        <tr>
                            <th>タイトル</th>
                            <td>{{ row.subtitle }}</td>
                        </tr>
                        <tr>
                            <th>担当</th>
                            <td>{{ row.director }}</td>
                        </tr>
                        <tr>
                            <th>リンク</th>
                            <td>
                                <a :href="row.link">{{ row.link }}</a>
                            </td>
                        </tr>
                    </table>
                </template>
            </el-table-column>
            <el-table-column label="ID">
                <template v-slot="{ row }">
                    {{ row.brm_id }}
                </template>
            </el-table-column>
            <el-table-column label="開催日" prop="event_date"></el-table-column>
            <el-table-column label="距離">
                <template v-slot="{ row }"> {{ row.distance }} km </template>
            </el-table-column>
            <el-table-column label="スタート">
                <template v-slot="{ row }">
                    <el-tag
                        type="success"
                        :key="row.label"
                        style="margin: 2px"
                        v-for="start in row.starts"
                        >{{ start.label }}</el-tag
                    >
                </template>
            </el-table-column>
            <el-table-column>
                <template v-slot="{ row }">
                    <el-button type="primary" @click="showMenu(row)"
                        >選択</el-button
                    >
                </template>
            </el-table-column>
        </el-table>
        <el-dialog
            :visible.sync="dialogVisible"
            width="80%"
            title="操作メニュー"
        >
            <el-card>
                <dl class="row">
                    <dt class="col-sm-3">日付</dt>
                    <dd class="col-sm-9">{{ editBrm.event_date }}</dd>
                    <dt class="col-sm-3">距離</dt>
                    <dd class="col-sm-9">{{ editBrm.distance }}km</dd>
                </dl>
            </el-card>
            <el-card>
                <el-row type="flex" class="my-1" align="middle" :gutter="10">
                    <el-col :xs="24" :sm="6"
                        ><el-button
                            v-if="isStaffHigher"
                            @click="editBrmInfo()"
                            style="width: 100%"
                            >編集</el-button
                        ></el-col
                    >
                    <el-col class="hidden-xs-only" :sm="18"
                        >ブルベ情報を編集します</el-col
                    >
                </el-row>
                <el-row
                    type="flex"
                    class="my-1"
                    align="middle"
                    v-if="isSystemAdmin"
                    :gutter="10"
                >
                    <el-col :xs="24" :sm="6"
                        ><el-button
                            type="danger"
                            @click="deleteBrm()"
                            style="width: 100%"
                            >削除</el-button
                        ></el-col
                    >
                    <el-col class="hidden-xs-only" :sm="18"
                        >ブルベを削除します</el-col
                    >
                </el-row>

                <el-row type="flex" class="my-1" align="middle" :gutter="10">
                    <el-col :xs="24" :sm="6"
                        ><el-button
                            v-if="isStaff"
                            @click="entryListBrm()"
                            style="width: 100%"
                            >参加者編集</el-button
                        ></el-col
                    >
                    <el-col class="hidden-xs-only" :sm="18"
                        >参加者の個人情報を編集します</el-col
                    >
                </el-row>
                <el-row type="flex" class="my-1" align="middle" :gutter="10">
                    <el-col :xs="24" :sm="6"
                        ><el-button
                            v-if="isStaff"
                            @click="reportBrm()"
                            style="width: 100%"
                            >集計</el-button
                        ></el-col
                    >
                    <el-col class="hidden-xs-only" :sm="18"
                        >リザルトを集計して出力します</el-col
                    >
                </el-row>
                <el-row type="flex" class="my-1" align="middle" :gutter="10">
                    <el-col :xs="24" :sm="6"
                        ><el-button
                            v-if="isStaffHigher"
                            @click="registerBrm()"
                            style="width: 100%"
                            >登録</el-button
                        ></el-col
                    >
                    <el-col class="hidden-xs-only" :sm="18"
                        >参加者情報を登録します</el-col
                    >
                </el-row>
                <el-row type="flex" class="my-1" align="middle" :gutter="10">
                    <el-col :xs="24" :sm="6"
                        ><el-button
                            v-if="isStaff"
                            @click="showResult()"
                            style="width: 100%"
                            >リザルト</el-button
                        ></el-col
                    >
                    <el-col class="hidden-xs-only" :sm="18"
                        >ブルベの結果を表示します</el-col
                    >
                </el-row>
                <el-row type="flex" class="my-1" align="middle" :gutter="10">
                    <el-col :xs="24" :sm="6"
                        ><el-button
                            type="primary"
                            style="width: 100%"
                            @click="dialogVisible = false"
                            >キャンセル</el-button
                        ></el-col
                    >
                </el-row>
            </el-card>
        </el-dialog>
    </div>
</template>

<script>
import { mapState, mapGetters } from "vuex"

export default {
    data() {
        return {
            brms: [],
            dialogVisible: false,
            editIndex: null,
            editBrm: {
                brmId: null,
                event_date: "",
                distance: "",
            },
        }
    },

    computed: {
        ...mapGetters(["isSystemAdmin", "isStaffHigher", "isStaff"]),
        ...mapState(["current"]),
        brmList() {
            return this.brms.map((brm) => {
                return {
                    brm_id: brm.id,
                    active: brm.active,
                    event_date: brm.event_date,
                    distance: brm.distance,
                    starts: brm.starts.map((s) => {
                        const ts = new Date(s.start_at)
                        const label =
                            ts.getHours() +
                            ":" +
                            ("00" + ts.getMinutes()).slice(0, 2)
                        return { ts, label }
                    }),
                    director: brm.director_name,
                    club_code: brm.club_code,
                    club_name: brm.club_name,
                    subtitle: brm.subtitle,
                    link: brm.link,
                }
            })
        },
    },

    created() {
        this.getBrms()
    },

    mounted() {},

    methods: {
        getBrms() {
            axios.get("/api/brm").then((response) => {
                this.brms = response.data
            })
        },

        newBrm() {
            this.$router.push({ name: "brmEdit", params: { id: null } })
        },

        showMenu(row) {
            this.editBrm.brmId = row.brm_id
            this.editBrm.event_date = row.event_date
            this.editBrm.distance = row.distance
            this.dialogVisible = true
        },
        setOption() {
            this.$router.push({
                name: "brmSetOption",
                params: { id: this.editBrm.brmId },
            })
        },
        editBrmInfo() {
            this.$router.push({
                name: "brmEdit",
                params: { id: this.editBrm.brmId },
            })
        },

        deleteBrm() {
            this.$confirm(
                "このブルベを消去します. よろしいですか?",
                "Warning",
                {
                    confirmButtonText: "はい",
                    cancelButtonText: "キャンセル",
                    type: "warning",
                }
            )
                .then(() => {
                    axios
                        .post("/api/brm/" + this.editBrm.brmId, null, {
                            headers: { "X-HTTP-Method-Override": "DELETE" },
                        })
                        .then((res) => {
                            this.$message("ブルベを削除しました.")
                            this.getBrms()
                            this.dialogVisible = false
                        })
                        .catch((res) => {
                            this.$message("削除できませんでした.")
                            this.dialogVisible = false
                        })
                })
                .catch(() => {
                    this.$message("キャンセルしました.")
                    this.dialogVisible = false
                    return false
                })
        },
        /*
         * ACP 提出用のエクセルファイルを出力（ダウンロード）
         */
        reportBrm() {
            const t = new Date(this.editBrm.event_date)
            const fileName = `${t.getFullYear()}_BRM${t.getMonth() + 1}${(
                "00" + t.getDate()
            ).slice(-2)}_${this.editBrm.distance}km`

            //const fileName = this.current.brmName

            axios({
                method: "GET",
                url: "/api/report",
                params: { id: this.editBrm.brmId },
                responseType: "blob",
            })
                .then((response) => {
                    const fileURL = window.URL.createObjectURL(
                        new Blob([response.data])
                    )
                    const fileLink = document.createElement("a")
                    fileLink.href = fileURL
                    fileLink.setAttribute("download", `${fileName}.xlsx`)
                    document.body.appendChild(fileLink)
                    fileLink.click()
                })
                .then(() => {
                    setTimeout(() => {
                        this.$message("エクセルファイルがダウンロードされます.")
                        this.dialogVisible = false
                    }, 2000)
                })
        },

        entryListBrm() {
            this.$router.push({
                name: "brmEntryList",
                params: { id: this.editBrm.brmId },
            })
        },

        showResult(){
            this.$router.push({
                name: 'showResult',
                params: { id: this.editBrm.brmId }
            })
        },

        registerBrm() {
            this.$router.push({
                name: "brmRegister",
                params: { id: this.editBrm.brmId },
            })
        },
        rowClass({ row, rowIndex }) {
            return row.active === 1 ? "active-brm" : ""
        },
    },
}
</script>

<style scoped>
.brm-list ::v-deep .active-brm {
    background: pink;
}
.brm-list ::v-deep .el-table__expanded-cell {
    padding: 5px 20px;
}
.table-brm-detail ::v-deep td {
    border: 0px;
}
</style>
