<!--
    ライダー個人情報
-->

<template>
    <div>
        <el-row>
            登録されているあなたの情報です. <br>間違いがあればスタッフまでお申し出ください.
        </el-row>
        <el-table :data='profile'>
            <el-table-column prop='item' label='項目' fixed></el-table-column>
            <el-table-column prop='value' label='登録値'></el-table-column>
            
        </el-table>
    </div>
</template>

<script>
    
    import { mapState } from 'vuex'
    
export default {
        data(){
            return {
                profile: []
            }
        },
        
        created(){
            axios.get('/api/user/' + this.user.id)
                    .then( res => {
                        const prof = res.data
                        const startTs = new Date( prof.entry.start.start_at)
                        const data = [
                            { item: '番号', value: prof.entry.start_no },
                            { item: 'スタート', value: `${startTs.getHours()}:${('00'+startTs.getMinutes()).slice(-2)}` },
                            { item: '氏名', value: prof.participant.name_kanji.replace(/　/,' ') },
                            { item: 'ローマ字', value: `${prof.participant.name_roman_last}, ${prof.participant.name_roman_first}`},
                            { item: '性別', value: prof.participant.sex === 'M' ? '男' : prof.participant.sex === 'F' ? '女' : ''},
                            { item: '生年月日', value: null },
                            { item: '住所', value: null },
                            
                            { item: 'メール', value: prof.participant.email },
                            { item: '携帯電話', value: null },
                            { item: '緊急電話', value: null }
                        ];
                        
                        this.profile = data
                        
                })
        },
        
        computed: {
            ...mapState(['user']),
        }
        
}    
</script>



    