<!-- 
    参加者ステータス
-->

<template>
    <div class="mb-2">
        <div class="mb-4 text-center">
            <el-tag :type="brm.type" effect="dark" style="width: 100%">{{
                brm.tag
            }}</el-tag>
            <p>{{ brm.message }}</p>
        </div>

        <!-- お知らせ -->
        <el-row :gutter="10" class="mb-2">
            <el-col :span="12">
                <el-tag
                    class="mb-1 mr-2 text-center"
                    style="width: 100%"
                    :type="infoProp.type"
                    plain
                    :effect="infoProp.effect"
                    >本部からの連絡</el-tag
                >
            </el-col>
            <el-col :span="12">
                <div class="d-inline" v-if="infoCount > 0">
                    <b>{{ infoCount }}件 あります</b>
                    <el-button @click="info()" type="success" round size="small"
                        >開く</el-button
                    >
                </div>
                <div v-else>0件 です</div>
            </el-col>
        </el-row>

        <el-row :gutter="10">
            <el-col :span="12">
                <el-tag class="mb-1 mr-2 text-center" plain style="width: 100%"
                    >あなたのステータス</el-tag
                >
            </el-col>
            <el-col :span="12">
                <el-tag :type="rider.type" effect="dark">{{
                    rider.tag
                }}</el-tag>
                <template v-if="rider.registerVisible">
                    <el-tag
                        v-if="rider.status === 'registered'"
                        size="small"
                        type="danger"
                        @click="goRegister"
                        >登録する</el-tag
                    >
                    <el-tag
                        v-else-if="!rider.registered"
                        size="small"
                        effect="dark"
                        type="info"
                        @click="goRegister"
                        >登録して下さい</el-tag
                    >
                    <el-tag v-else size="small" type="success"
                        >登録ずみ</el-tag
                    >
                </template>
            </el-col>
        </el-row>

        <el-row>
            <el-col class="text-center" :span="24">
                {{ rider.comment }}
            </el-col>
        </el-row>
    </div>
</template>

<script>
import { mapState, mapGetters, mapActions } from "vuex"
import { limitHours } from "@/lib/brevet.js"

const status_tags = {
    canceled: "キャンセル",
    dnf: "DNF",
    dns: "DNS",
    registered: "走行中",
    overtime: "OT",
    finish: "FINISH",
}
const status_tag_types = {
    canceled: "",
    dnf: "danger",
    dns: "warning",
    registered: "info",
    overtime: "",
    finish: "success",
}
const status_comments = {
    canceled: "",
    dnf: "お疲れさまでした! 取り消せるって知ってました?",
    dns: "間違ってたら連絡ください",
    registered: "頑張ってゴールしてくださいね！",
    overtime: "",
    finish: "おめでとうございます!",
}

export default {
    data() {
        return {
            infoCount: 0,
            timerId: null,
        }
    },

    computed: {
        ...mapState({
            current: (state) => state.current,
            user: (state) => state.user.info,
        }),
        ...mapGetters(["riderInfo"]),

        brm() {
            // データ取得未
            if (!this.current || !this.user.entry) {
                return { tag: "---", type: "primary" }
            }

            if (this.current.brmId === this.user.entry.brm_id) {
                // 開催中の大会にエントリー

                const startAt = new Date(this.user.entry.start.start_at)
                const finishAt = new Date(
                    startAt.getTime() +
                        limitHours.get(this.current.brmDistance) * 3600_000
                )

                if (Date.now() < startAt.getTime()) {
                    return {
                        tag: "ブルベのスタート前です",
                        type: "warning",
                        message: "頑張ってください",
                    }
                } else if (Date.now() > finishAt.getTime() + 3600_000) {
                    return {
                        tag: "ブルベは終了しています",
                        type: "warning",
                        message: "お疲れさまでした",
                    }
                } else {
                    return {
                        tag: "ブルベ開催中です",
                        type: "primary",
                        message: "調子はどうですか",
                    }
                }
            } else {
                // 開催ブルベは終了
                const dt = new Date(this.user.entry.brm.event_date)
                const dist = this.user.entry.brm.distance
                const entryBrm = `BRM${dt.getMonth() + 1}${(
                    "00" + dt.getDate()
                ).slice(-2)} ${dist}km`

                return {
                    tag: `${entryBrm} は終了しました`,
                    type: "warning",
                    message: "ありがとうございました",
                }
            }
        },

        rider() {
            if (!this.user.entry) {
                return
            }
            const status = this.user.entry.status
            const registerVisible =
                this.user.entry.brm.selfgoal === 1 &&
                (status === "registered" ||
                    status === "overtime" ||
                    status === "finish")
            const registered = this.user.entry.finish_at !== null

            return {
                status,
                tag: status_tags[status],
                type: status_tag_types[status],
                comment: status_comments[status],
                registerVisible,
                registered,
            }
        },

        infoProp() {
            return {
                type: this.infoCount > 0 ? "danger" : "info",
                effect: this.infoCount > 0 ? "dark" : "light",
            }
        },
    },

    created() {
        this.checkInfo()
        this.timerId = setInterval(() => this.checkInfo(), 300 * 1000)
    },

    methods: {
        checkInfo() {
            axios
                .get("/api/communication/hasinfo")
                .then((res) => (this.infoCount = res.data.count))
        },
        info() {
            if (this.infoCount > 0) {
                this.$router.push({ name: "riderInfo" })
            }
        },
        goRegister() {
            this.$router.push({ name: "riderSelfFinishRegister" })
        },
    },

    beforeDestroy() {
        clearInterval(this.timerId)
    },
}
</script>

<style scoped>
</style>
