<!-- 

    個人のステータス編集

-->

<template>
    <el-card>
        <div slot="header" class="row d-flex align-items-center">
            <div class="h5 col-auto">
                <el-tag effect="dark">{{ entryData.start_no }}</el-tag>
                <b>{{ entryData.name }}</b> さん
            </div>
            <div class="col-auto">
                <div class="font-tiny"><b>スタート時間</b></div>
                <div>
                    <el-tag :type="startData.type" effect="dark">{{
                        startData.tag
                        }}</el-tag>
                </div>
            </div>
            <div class="col-auto">
                <div class="font-tiny"><b>現在の登録</b></div>
                <div>
                    <el-tag :type="status_tag_type" effect="dark">{{
                        status_tag
                        }}</el-tag>
                </div>
            </div>
        </div>
        <div class="row card-item change-status">
            <div class="col-12 col-sm-4"><b>ステータス変更</b></div>
            <div class="col-12 col-sm-8">
                <el-button :disabled="isDisabled('registered')" type="info" size="small"
                    @click="onRegistered">走行中</el-button>
                <el-button :disabled="isDisabled('dns')" type="warning" size="small" @click="onDns">DNS</el-button>
                <el-button :disabled="isDisabled('dnf')" type="danger" size="small" @click="onDnf">DNF</el-button>
                <el-button :disabled="isDisabled('finish')" type="success" size="small"
                    @click="onFinish">FINISH</el-button>
                <el-button :disabled="isDisabled('canceled')" size="small" @click="onCanceled">除外</el-button>
            </div>
        </div>
        <!-- フィニッシュ設定-->
        <div class="card-item" v-if="visibleForm">
            <div class="row py-1">
                <div class="col-12 col-sm-3"><b>フィニッシュ時間</b></div>
                <div class="col-12 col-sm-auto">
                    <div class="row">
                        <div class="col-12 col-sm">
                            <el-date-picker v-model="finishForm.finishTime" type="datetime" format="MM月dd日 HH:mm"
                                @change="onChangeFinishTime"></el-date-picker>
                        </div>
                        <div class="col-12 col-sm">
                            <small>実際のゴール時間</small>
                        </div>
                    </div>
                </div>
            </div>

            <div class="row py-1">
                <div class="col-12 col-sm-3"><b>リザルト時間</b></div>
                <div class="col-12 col-sm-auto">
                    <div class="row">
                        <div class="col-12 col-sm">
                            <el-date-picker v-model="finishForm.resultTime" type="datetime"
                                format="MM月dd日 HH:mm"></el-date-picker>
                        </div>
                        <div class="col-12 col-sm-auto">
                            <span v-if="!visibleBtnBrevet"><small>調整後のゴール時間</small></span><el-button
                                v-if="visibleBtnBrevet" @click="onBrevet" type="primary" plain>認定する</el-button>
                        </div>
                    </div>
                </div>
            </div>

            <div class="row py-1" v-if="visibleResultTime">
                <div class="col-12 col-sm-3"><b>認定時間</b></div>
                <div class="col-12 col-sm-auto">
                    {{ brevetTime }}
                </div>
            </div>

            <div class="row py-1">
                <div class="col-12 col-sm-3"><b>完走メダル</b></div>
                <div class="col-12 col-sm-auto">
                    <el-checkbox v-model="finishForm.medal" :disabled="disabledMedal"><i class="el-icon-medal"></i>
                        購入</el-checkbox>
                </div>
            </div>

            <div class="row py-1" v-if="current.multipurpose_check">
                <div class="col-12 col-sm-3"><b>{{ current.multipurpose_check_name }}</b></div>
                <div class="col-12 col-sm-auto">
                    <el-checkbox v-model="finishForm.multipurpose_check">チェック</el-checkbox>
                </div>
            </div>

        </div>
        <div class="row card-item align-items-center" v-if="visibleForm">
            <el-button @click="onSubmit()" type="primary">設定</el-button>
            <el-button @click="onCancel()">キャンセル</el-button>
        </div>
    </el-card>
</template>

<script>
import { mapState, mapGetters, mapActions } from "vuex"

const status_tags = {
    canceled: "キャンセル",
    dnf: "DNF",
    dns: "DNS",
    registered: "走行中",
    overtime: "OT",
    finish: "FINISH",
}
const status_tag_types = {
    canceled: "",
    dnf: "danger",
    dns: "warning",
    registered: "info",
    overtime: "",
    finish: "success",
}

export default {
    props: ["entry_id"],

    data() {
        return {
            entry: {
                status: "",
                lifeSaved: false, // OTが救済されたか
            },
            finishForm: {
                finishTime: null,
                resultTime: null,
                medal: false,
                multipurpose_check: false
            },
            visibleForm: false, // 認定時間設定のフォームを表示するか
            visibleResultTime: false,
            visibleBtnBrevet: false,
            disabledMedal: true, // メダル購入のチェックボックス
        }
    },

    computed: {
        ...mapState(["current"]),
        ...mapGetters([]),
        entryId() {
            return this.entry_id
        },
        entryData() {
            return this.current.entries.list.find(
                (element) => element.id === this.entryId
            )
        },
        startData() {
            return this.current.starts.find(
                (element) => element.id === this.entryData.start_id
            )
        },
        status_tag() {
            return status_tags[this.entry.status]
        },
        status_tag_type() {
            return status_tag_types[this.entry.status]
        },
        brevetTime() {
            const brevetTime =
                this.finishForm.resultTime - new Date(this.entryData.start_time)
            const brevetTimeMinutes = Math.floor(brevetTime / (60 * 1000))
            const brevetTimeHr = Math.floor(brevetTimeMinutes / 60)
            const brevetTimeMin = brevetTimeMinutes % 60

            return brevetTimeHr + ":" + ("00" + brevetTimeMin).slice(-2)
        },
    },
    watch: {
        entryId: function (val, oldVal) {
            this.getEntry()
        },
    },
    created() {
        this.getEntry()
    },

    methods: {
        ...mapActions(["getEntryList"]),

        getEntry() {
            axios
                .get("/api/getentry", { params: { entryId: this.entryId } })
                .then((res) => {
                    this.entry.status = res.data.status
                    if (this.entry.status === "finish") {
                        this.finishForm.finishTime = new Date(
                            res.data.finish_at
                        )
                        this.finishForm.resultTime = new Date(
                            res.data.result_at
                        )
                        this.finishForm.medal = res.data.medal ? true : false

                        this.visibleForm = true
                        this.visibleResultTime = true
                        this.visibleBtnBrevet = false
                        this.disabledMedal = false
                    } else if (this.entry.status === "overtime") {
                        this.finishForm.finishTime = new Date(
                            res.data.finish_at
                        )
                        this.finishForm.resultTime = null
                        this.finishForm.medal = false
                        this.visibleForm = true
                        this.visibleResultTime = true
                        this.visibleBtnBrevet = true
                        this.disabledMedal = true
                    } else {
                        this.visibleForm = false
                        this.visibleReslutTime = false
                        this.visibleBtnBrevet = false
                    }
                    this.finishForm.multipurpose_check = res.data.multipurpose_check ? true : false
                })
        },

        isDisabled(status) {
            if (status === "finish" && this.entry.status === "overtime")
                return true // OT では FINISH ボタンは押せない（代わりに下の救済ボタンで解決）
            return this.entry.status === status // 現在と同じ status のボタンは選択不可に
        },

        resetForm() {
            this.entry = {
                status: "",
                lifeSaved: false, // OTが救済されたか
            }

            this.finishForm = Object.assign(
                {},
                {
                    finishTime: null,
                    resultTime: null,
                    medal: false,
                    multipurpose_check: false,
                }
            )

            this.visibleForm = false
            this.visibleResultTime = false
            this.visibleBtnBrevet = false
            this.disabledMedal = true
        },

        // → 走行中に変更
        onRegistered() {
            this.$confirm(
                `${this.entryData.name} さんを ${this.status_tag} から 走行中 に変更します. <br/>よろしいですか?`,
                "変更確認",
                {
                    type: "warning",
                    dangerouslyUseHTMLString: true,
                    center: true,
                }
            )
                .then(() => {
                    const request = {
                        entryId: this.entryId,
                        setStatus: "registered",
                    }

                    axios
                        .post("/api/setstatus", request, {
                            headers: { "X-HTTP-Method-Override": "PUT" },
                        })
                        .then((res) => {
                            this.$message("変更しました")
                            this.getEntryList()
                            this.getEntry()
                            this.$emit("terminate")
                        })
                })
                .catch(() => {
                    this.$message("取り消しました.")
                    this.$emit("terminate") // 取り消しても dialog を閉じる.
                })
        },
        onDns() {
            this.$confirm(
                `${this.entryData.name} さんを ${this.status_tag} から DNS に変更します. <br/>よろしいですか?`,
                "変更確認",
                {
                    type: "warning",
                    dangerouslyUseHTMLString: true,
                    center: true,
                }
            )
                .then(() => {
                    const request = {
                        entryId: this.entryId,
                        setStatus: "dns",
                    }

                    axios
                        .post("/api/setstatus", request, {
                            headers: { "X-HTTP-Method-Override": "PUT" },
                        })
                        .then((res) => {
                            this.$message("変更しました")
                            this.getEntryList()
                            this.getEntry()
                            this.$emit("terminate")
                        })
                })
                .catch(() => {
                    this.$message("取り消しました.")
                    this.$emit("terminate") // 取り消しても dialog を閉じる.
                })
        },

        onDnf() {
            this.$confirm(
                `${this.entryData.name} さんを ${this.status_tag} から DNF に変更します. <br/>よろしいですか?`,
                "変更確認",
                {
                    type: "warning",
                    dangerouslyUseHTMLString: true,
                    center: true,
                }
            )
                .then(() => {
                    const request = {
                        entryId: this.entryId,
                        setStatus: "dnf",
                    }

                    axios
                        .post("/api/setstatus", request, {
                            headers: { "X-HTTP-Method-Override": "PUT" },
                        })
                        .then((res) => {
                            this.$message("変更しました")
                            this.getEntryList()
                            this.getEntry()
                            this.$emit("terminate")
                        })
                })
                .catch(() => {
                    this.$message("取り消しました.")
                    this.$emit("terminate") // 取り消しても dialog を閉じる.
                })
        },

        onCanceled() {
            this.$confirm(
                `${this.entryData.name} さんをスタートリストから除外します. <br/>よろしいですか?`,
                "変更確認",
                {
                    type: "warning",
                    dangerouslyUseHTMLString: true,
                    center: true,
                }
            )
                .then(() => {
                    this.$confirm("本当にいいですか?", "再確認", {
                        type: "warning",
                    })
                        .then(() => {
                            const request = {
                                entryId: this.entryId,
                                setStatus: "canceled",
                            }

                            axios
                                .post("/api/setstatus", request, {
                                    headers: {
                                        "X-HTTP-Method-Override": "PUT",
                                    },
                                })
                                .then((res) => {
                                    this.$message("変更しました")
                                    this.getEntryList()
                                    this.getEntry()
                                    this.$emit("terminate")
                                })
                        })
                        .catch(() => {
                            this.$message("取り消しました.")
                            this.$emit("terminate") // 取り消しても dialog を閉じる.
                        })
                })
                .catch(() => {
                    this.$message("取り消しました.")
                    this.$emit("terminate") // 取り消しても dialog を閉じる.
                })
        },

        /*
         * フィニッシュ・OT の処理
         */

        // リザルト時間の設定（ゴールリミットに設定する）
        onBrevet() {
            let result = new Date(this.entryData.start_time)
            this.finishForm.resultTime = new Date(
                result.setMinutes(
                    result.getMinutes() + this.current.brmTimeLimit * 60
                )
            )
            this.entry.lifeSaved = true
            this.disabledMedal = false
        },
        onFinish() {
            this.visibleForm = true
            this.visibleResultTime = true
            this.visibleBtnBrevet = false
            this.disabledMedal = false
            this.finishForm.finishTime = this.finishForm.resultTime = new Date(
                parseInt(new Date().getTime() / 60000) * 60000
            )
            this.finishForm.medal = false
        },
        onChangeFinishTime() {
            if (this.entry.status === "finish") {
                // フィニッシュ時刻の調整
                this.finishForm.resultTime = this.finishForm.finishTime
            }
        },
        onSubmit() {
            const request = {
                entryId: this.entryId,
                setStatus: "finish",
                finishTime: this.finishForm.finishTime,
                resultTime: this.finishForm.resultTime,
                medal: this.finishForm.medal,
                multipurpose_check: this.finishForm.multipurpose_check,
            }

            axios
                .post("/api/setstatus", request, {
                    headers: { "X-HTTP-Method-Override": "PUT" },
                })
                .then((res) => {
                    this.$message("変更しました")
                    this.getEntryList()
                    this.getEntry()
                    this.resetForm()
                    this.$emit("terminate")
                })
        },

        onCancel() {
            this.resetForm()
            this.$message("取り消しました.")
            this.$emit("terminate")
        },
    },
}
</script>

<style scoped>
.font-tiny {
    font-size: 8px;
}

.card-item {
    padding: 18px 20px;
    border-bottom: 1px solid #ebeef5;
    box-sizing: border-box;
}

.change-status>>>.el-button+.el-button {
    margin-left: 0px;
    margin-bottom: 2px;
}
</style>
