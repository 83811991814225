const canvas = document.createElement('canvas')
const ctx = canvas.getContext('2d')

const temperatureIcon = (temp = null, width = 32, height = 32) => {

    canvas.width = width
    canvas.height = height

    let liqColor    // メジャーの色
    if (temp < 0) {
        liqColor = 'purple'
    } else if (temp < 10) {
        liqColor = 'blue'
    } else if (temp < 25) {
        liqColor = 'orange'
    } else if (temp > 25) {
        liqColor = 'red'
    } else {
        liqColor = 'orange'
    }

    const outlineColor = 'darkgrey'

    const thermo = new Path2D('M12.5,17.605V5.442h-0.006C12.462,2.708,10.242,0.5,7.5,0.5 S2.538,2.708,2.506,5.442H2.5v12.164c-1.236,1.262-2,2.988-2,4.895c0,3.866,3.134,7,7,7s7-3.134,7-7 C14.5,20.594,13.736,18.867,12.5,17.605z')


    ctx.clearRect(0, 0, width, height)

    // 温度計

    // バックグラウンド
    ctx.save()
    ctx.strokeStyle = 'white'
    ctx.fillStyle = 'white'
    ctx.lineWidth = 3.0
    ctx.fill(thermo)
    ctx.stroke(thermo)
    // 輪郭
    ctx.strokeStyle = outlineColor
    ctx.lineWidth = 1.0
    ctx.stroke(thermo)
    ctx.restore()

    // 中身
    ctx.save()
    ctx.fillStyle = liqColor
    ctx.beginPath()
    ctx.arc(7.5, 23, 5, 0, 2 * Math.PI)
    ctx.fill()
    ctx.beginPath()
    ctx.rect(5, 23.5, 5, -15)
    ctx.fill()
    ctx.restore()

    // 温度
    ctx.save()

    ctx.strokeStyle = 'white'
    ctx.lineWidth = 3.0
    ctx.textBaseline = 'bottom'
    ctx.strokeText(`${Math.floor(temp)}`, 15, 15)
    ctx.textBaseline = 'top'
    ctx.strokeText('℃', 15, 16)

    ctx.strokeStyle = 'black'
    ctx.lineWidth = 1.0
    ctx.textBaseline = 'bottom'
    ctx.strokeText(`${Math.floor(temp)}`, 15, 15)
    ctx.textBaseline = 'top'
    ctx.strokeText('℃', 15, 16)
    ctx.restore()

    return canvas.toDataURL()
}

// 風向・風速アイコン
//  48 x 32px
const windIcon = (velocity, direction) => {

    canvas.width = 48
    canvas.height = 32
    ctx.clearRect(0, 0, 48, 32)

    // 矢印部分（原点を中央にして描画）
    let arrowPath = new Path2D()
    if (velocity === 0.0) {   // 完全無風
        arrowPath.arc(0, 0, 5, 0, 2 * Math.PI)
    } else if (velocity < 10) { // 風速 0～10m/s
        const arrowHeight = 7.0
        const rodHeight = Math.ceil(velocity / 10 * 22)
        const arrowTop = -(arrowHeight + rodHeight) / 2
        arrowPath.moveTo(0, arrowTop)
        arrowPath.lineTo(-6, arrowTop + arrowHeight)
        arrowPath.lineTo(-3, arrowTop + arrowHeight)
        arrowPath.lineTo(-3, -arrowTop)
        arrowPath.lineTo(3, -arrowTop)
        arrowPath.lineTo(3, arrowTop + arrowHeight)
        arrowPath.lineTo(6, arrowTop + arrowHeight)
        arrowPath.closePath()
    } else if (velocity < 15) {  // 風速 10～15m/s・中位の矢印・同じ形
        arrowPath.moveTo(0, -14.5)
        arrowPath.lineTo(-9, -4.5)
        arrowPath.lineTo(-4, -4.5)
        arrowPath.lineTo(-4, 14.5)
        arrowPath.lineTo(4, 14.5)
        arrowPath.lineTo(4, -4.5)
        arrowPath.lineTo(9, -4.5)
        arrowPath.closePath()
    } else {    // 風速 15m/s～・大きな矢印
        arrowPath.moveTo(0, -14.5)
        arrowPath.lineTo(-11, -2.5)
        arrowPath.lineTo(-5, -2.5)
        arrowPath.lineTo(-5, 14.5)
        arrowPath.lineTo(5, 14.5)
        arrowPath.lineTo(5, -2.5)
        arrowPath.lineTo(11, -2.5)
        arrowPath.closePath()
    }

    ctx.save()

    ctx.translate(16, 16)
    ctx.rotate(Math.PI) // 0° で下向き（北風）
    ctx.rotate(direction / 180 * Math.PI)
    ctx.strokeStyle = 'white'
    ctx.lineWidth = 3.0
    ctx.stroke(arrowPath)
    ctx.fillStyle = 'black'
    ctx.fill(arrowPath)
    ctx.restore()

    // 風速
    ctx.save()
    // 背景
    ctx.strokeStyle = 'white'
    ctx.lineWidth = 3.0
    ctx.textBaseline = 'bottom'
    ctx.textAlign = 'end'
    ctx.strokeText(`${velocity.toFixed(1)}`, 48, 16)
    ctx.textBaseline = 'top'
    ctx.textAlign = 'end'
    ctx.strokeText('m/s', 48, 16)
    // 全景
    ctx.strokeStyle = 'black'
    ctx.lineWidth = 1.0
    ctx.textBaseline = 'bottom'
    ctx.textAlign = 'end'
    ctx.strokeText(`${velocity.toFixed(1)}`, 48, 16)
    ctx.textBaseline = 'top'
    ctx.textAlign = 'end'
    ctx.strokeText('m/s', 48, 16)
    ctx.restore()

    return canvas.toDataURL()

}

export default {

    temp: temperatureIcon,
    wind: windIcon
}







