const wait = (ms) => new Promise(resolve => {
    setTimeout(resolve, ms)
})

import { polyline } from "@/lib/polyline.js"
import { hubeny } from "@/lib/hubeny.js"
import { simplifyPath } from "../lib/douglasPeucker"
import { markerIcon } from "@/components/goalBot/brmtool.gmapcueicon.js"
import gmapIcon from "@/components/goalBot/gmapIcon.js"
import { worldCoord, pixelDistance } from "@/lib/mapcoord.js"
import openWeather from "@/OpenWeather.js"

const POLYCOLOR = new Map([
    ["", "#3490dc"], // 青
    ["success", "#38c172"], // 緑
    ["danger", "#f66d9b"], // ピンク
    ["warning", "#f6993f"], // オレンジ
    ["info", "#6cb2eb"], // 水色
])
const POLYLINE_ZINDEX = 7 // スタート毎の polyline の zIndex の基準

const FASTEST_AVG_SPEED = 25 // km/h
const SLOWEST_AVG_SPEED = 15 // km/h
const MAJORITY_AVG_SPEED = 16

const WEATHER_DISTANCE = 5_000  // メートル
const WEATHER_FETCH_INTERVAL = 15 * 60_000  // 天気取得間隔
const WEATHER_DIVIDE_NUM = 3    // ルートの LatLngBounds を n等分したぐらいの地点の天気を取得
const WEATHER_MARKER_DENSITY = 100  // マーカー間の最低距離（ピクセル）

const WEATHER_ICON = new Map([
    ["01d", ["7688-02", "shine"]], // clear sky
    ["02d", ["7688-03", "cloud"]], // few clouds
    ["03d", ["7688-04", "cloud"]], // scatterd clouds
    ["04d", ["7688-04", "cloud"]], // broken clouds
    ["09d", ["7688-01", "rain"]], // shower rain
    ["10d", ["7688-01", "rain"]], // rain
    ["11d", ["7688-16", "thunder"]], // thunderstorm
    ["13d", ["7688-07", "snow"]], // snow
    ["50d", ["7688-04", "cloud"]], // mist
    ["01n", ["7688-02", "shine"]], // clear sky
    ["02n", ["7688-03", "cloud"]], // few clouds
    ["03n", ["7688-04", "cloud"]], // scatterd clouds
    ["04n", ["7688-04", "cloud"]], // broken clouds
    ["09n", ["7688-01", "rain"]], // shower rain
    ["10n", ["7688-01", "rain"]], // rain
    ["11n", ["7688-16", "thunder"]], // thunderstorm
    ["13n", ["7688-07", "snow"]], // snow
    ["50n", ["7688-04", "cloud"]], // mist
])


const checkGoogle = () => {
    return new Promise(async (resolve, reject) => {
        setTimeout(() => reject('timeout '), 10_000)
        do {
            if (window.google) {
                return resolve()
            } else {
                await wait(50)
            }
        } while (true)
    })
}

const MOCK_Date = (dt, mag = 10) => {
    const ts = Date.now()

    return function () {
        const current = Date.now()
        return new Date(dt).getTime() + (current - ts) * mag
    }
}
const MockDateElapsed = 5 // スタートの n時間後
const MockDateMagnify = 10 // n倍速

export default {

    namespaced: true,

    state: () => ({

        activated: false,

        previousBrmId: null,
        dataUpdated: false,

        brmReady: false,
        trackReady: false,
        queryPointsReady: false,

        mapObj: null,
        polyObj: null,
        latLngBounds: null,

        currentBrm: null,
        linkCode: null,
        brmRoute: null,
        totalDistance: 0, // ブルベ距離（トラックから）メートル
        wholeTrack: [],
        track: [],
        pois: [],

        everyStart: [], // < start, close, polyObj, color, polyPath, distance, points, latLngBounds >
        polyOrder: 1, // polyline の順序を入れ替えるために毎回インクリメントする

        // POI のマーカー
        poiMarkers: [],
        visible: true,
        stopBlinkPoiMarkers: false, // マーカーの点滅を中断
        blinkInterval: 30_000, // 30秒間隔でブリンク

        // INFO
        info: {
            visible: false,
            title: "",
            content: "",
        },

        // WEATHER
        queryPoints: [], // < { cityID: null, latLng: { lat, lng }}
        weatherReports: new Map(), // cityID をキーとする
        cities: [],
        weatherReportUpdate: 0,
        lastQuery: null, // 最終問い合わせ
        weatherMarkerType: "wind", // 'weather', 'wind', 'temperature'
        weatherMarkers: [], // 毎回書き換える
        raderUpdate: null, // 雨雲レーダー更新
        iconRotateCounter: 1,

        weatherCache: [],   // API を呼んだ結果をプールする. persistent で次回に引き継ぐ

        // 雨雲レーダー（～1時間後）
        raderShort: Function,
        raderUpdate: null,

        mock: {
            func: null,
        }
    }),

    getters: {

        mapObj(state) {
            return state.mapObj
        },

        zoom: (state) => () => {
            return state.mapObj.getZoom()
        },

        latLngBounds(state) {
            return state.latLngBounds
        },

        track(state) {
            return state.track
        },

        brmReady(state) {
            return state.brmReady
        },

        everyStart(state) {
            return state.everyStart
        },

        raderUpdate(state) {
            return state.raderUpdate
        },

        info(state) {
            return state.info
        },

        weatherReportUpdate(state) {
            return state.weatherReportUpdate
        },

        cachedWeather: (state) => (position) => {
            if (state.weatherCache.length === 0) {
                return undefined
            }
            return state.weatherCache.find(rep => rep.position.lat === position.lat && rep.position.lng === position.lng)
        },

        // スタートグループごとのスタート後の経過時間（ms）
        // Option:
        //  start: everyStart の要素
        //  [ts]: 現在時刻 ts
        elapsed: (state) => (option) => {
            const group = option.group
            const ts = option.ts ?? Date.now()
            return ts - group.start.getTime()
        },

        // スピード・経過時間からのルート上の位置
        distance: (state) => (option) => {
            const elapsedSecond = option.elapsed / 1000
            const speed = option.speed * 1000 / 3600
            return Math.max(0, Math.min(state.totalDistance, speed * elapsedSecond)) // メートル
        },

        // 距離 → ポイントインデックス
        point: (state) => (option) => {
            const targetDistance = option.distance
            const closest = state.track.reduce((closePt, pt, index) => {
                const currentGap = Math.abs(pt.distance - targetDistance)
                return (currentGap < closePt.gap) ? { index, point: pt, gap: currentGap } : closePt
            }, { index: null, point: null, gap: Infinity })

            return closest.index
        },

        // インデックス間のポイント
        slice: (state) => (option) => {
            const start = Math.max(0, option.begin)
            const end = Math.min(option.end + 1, state.track.length)
            return state.track.slice(start, end)
        },

        // Coord: { lat, lng }
        // 指定位置の一番そばのマーカー
        // RETURN: ピクセル距離
        closestMarker: (state, getters) => (coord) => {
            const markers = state.weatherMarkers
            const zoom = getters.zoom()
            const lat1 = coord.lat
            const lng1 = coord.lng

            return markers.reduce((closest, marker) => {
                const pos = marker.getPosition()
                const lat2 = pos.lat()
                const lng2 = pos.lng()
                const dist = pixelDistance(lat1, lng1, lat2, lng2, zoom)
                return dist < closest ? dist : closest

            }, Infinity)


        },

        mockFunc(state) {
            return state.mock.func
        }

    },

    mutations: {

        initMap(state, mapObj) {
            state.mapObj = mapObj
        },
        removeMapObj(state) {
            state.polyObj.setMap(null)
            state.poiMarkers.forEach(marker => marker.markerObj.setMap(null))
            state.everyStart.forEach(st => st.polyObj.setMap(null))
        },
        initRaderShort(state, raderFunc) {
            state.raderShort = raderFunc
        },
        setRaderUpdate(state, text) {
            state.raderUpdate = text
        },
        currentBrm(state, currentBrm) {
            state.currentBrm = currentBrm
            state.linkCode = currentBrm.link.match(/\/([0-9a-f]{5,8})\/?/)[1]
        },
        everyStart(state, everyStart) {
            state.everyStart = everyStart
        },
        brmRoute(state, payload) {
            state.brmRoute = payload
        },
        setBrmReady(state) {
            state.brmReady = true
        },
        setBrmId(state, id) {
            state.previousBrmId = id
        },
        setUpdateFlag(state, bool) {
            state.dataUpdated = bool
        },
        setPoiMarkers(state, markers) {
            state.poiMarkers = markers
            state.poiReady = true
        },
        setTrack(state, { wholeTrack, track, totalDistance, latLangBounds }) {
            state.wholeTrack = wholeTrack
            state.track = track
            state.totalDistance = totalDistance
            state.latLangBounds = latLangBounds
            state.trackReady = true
        },
        setPoly(state, { bb, routePoly, everyStart }) {
            state.latLngBounds = bb
            state.polyObj = routePoly
            state.everyStart = everyStart
            state.polyReady = true
        },
        updateEveryStart(state, { index, points, zIndex, distance, point }) {
            const group = state.everyStart[index]
            const polyObj = group.polyObj
            const polyPath = group.polyPath
            const bb = new google.maps.LatLngBounds()
            polyPath.clear()
            points.forEach(pt => {
                const latLngObj = new google.maps.LatLng(pt)
                polyPath.push(latLngObj)
                bb.extend(latLngObj)
            })
            group.latLngBounds = bb
            group.distance = distance
            group.point = point
            polyObj.setOptions({ zIndex })
        },
        incrementPolyOrder(state) {
            state.polyOrder += 1
        },
        setQueryPoints(state, queryPoints) {
            state.queryPoints = queryPoints
            state.queryPointsReady = true
        },
        updateQueryPoint(state, payload) {
            const queryPt = state.queryPoints[payload.index]
            queryPt.cityID = payload.cityID
            queryPt.ts = payload.ts
            queryPt.data = payload.data
        },
        updateWeather(state, weatherReports) {
            weatherReports.forEach(rep => {
                state.weatherReports.set(rep.cityID, rep)
            })
        },

        gcWeatherCache(state) {

            if (state.weatherCache.length === 0) {
                return
            }

            const alive = state.weatherCache.filter((rep) => {
                return rep.ts + WEATHER_FETCH_INTERVAL * 2 > Date.now()
            })
            state.weatherCache.splice(0, state.weatherCache.length, ...alive)
        },

        storeWeatherCache(state, payload) {

            const pos = payload.position
            if (state.weatherCache.length > 0) {

                const index = state.weatherCache.findIndex(rep => rep.position.lat === pos.lat && rep.position.lng === pos.lng)
                if (index !== -1) {
                    state.weatherCache.splice(index, 1)
                }
            }

            state.weatherCache.push({
                position: pos,
                data: payload.data,
                ts: Date.now()
            })
        },

        incrementWeatherReportUpdate(state) {
            state.weatherReportUpdate += 1
        },

        setCities(state, cities) {
            state.cities = cities
        },

        clearWeatherMarkers(state) {
            state.weatherMarkers.forEach(marker => {
                marker.setVisible(false)
                marker.setIcon("")
                marker.setMap(null)
            })
            state.weatherMarkers.splice(0)
        },

        addWeatherMarker(state, markerObj) {
            state.weatherMarkers.push(markerObj)
        },

        setInfo(state, info) {
            state.info = Object.assign({}, state.info, info)
        },

        setMockFunc(state) {
            const startDt = state.currentBrm.brmStartDateTime.getTime() +
                MockDateElapsed * 3600_000

            state.mock.func = MOCK_Date(startDt, MockDateMagnify)
        }
    },

    actions: {

        async initMap({ commit }, el) {

            await checkGoogle()

            const mapObj = await new google.maps.Map(
                el,
                {
                    zoom: 15,
                    center: { lat: 35.24191, lng: 137.11446 }, // せとしなの
                    fullscreenControl: false,
                    mapTypeControl: false,
                    scaleControl: false,
                    streetViewControl: false,
                    zoomControl: false,
                }
            )

            commit('initMap', mapObj)
            return 'initMap end'

        },

        // 効くかどうかは不明
        removeMapObj({ commit }) {
            commit('clearWeatherMarkers')
            commit('removeMapObj')
        },

        // ImageOverlay に指定する google.maps.ImageMapType を返す関数を作成
        async initRader({ commit }) {

            await checkGoogle()

            const raderShortFunc = () => {

                const analizedAt = new Date(Date.now() - 120_000) // 解析のタイムラグを考えて 2分経ってからのデータとする
                const getAt = new Date()

                const format = (dateObj) => {
                    const tsUTC = {
                        year: dateObj.getUTCFullYear(),
                        month: `00${dateObj.getUTCMonth() + 1}`.slice(-2),
                        day: `00${dateObj.getUTCDate()}`.slice(-2),
                        hour: `00${dateObj.getUTCHours()}`.slice(-2),
                        minute: `00${Math.floor(dateObj.getUTCMinutes() / 5) * 5
                            }`.slice(-2),
                    }
                    const tsLocal = {
                        year: dateObj.getFullYear(),
                        month: `00${dateObj.getMonth() + 1}`.slice(-2),
                        day: `00${dateObj.getDate()}`.slice(-2),
                        hour: `00${dateObj.getHours()}`.slice(-2),
                        minute: `00${Math.floor(dateObj.getMinutes() / 5) * 5
                            }`.slice(-2),
                    }

                    const formatUTC = `${tsUTC.year}${tsUTC.month}${tsUTC.day}${tsUTC.hour}${tsUTC.minute}00`
                    const toLocalString = `${tsLocal.year}年${tsLocal.month}月${tsLocal.day}日 ${tsLocal.hour}時${tsLocal.minute}分`
                    return {
                        tsUTC,
                        tsLocal,
                        format: formatUTC,
                        toLocalString,
                    }
                }

                const analysisTs = format(analizedAt)
                const reportTs = format(getAt)

                const mapTypeObj = new google.maps.ImageMapType({
                    getTileUrl: (coord, zoom) => {
                        return `https://www.jma.go.jp/bosai/jmatile/data/nowc/${analysisTs.format}/none/${reportTs.format}/surf/hrpns/${zoom}/${coord.x}/${coord.y}.png`
                    },
                    tileSize: new google.maps.Size(256, 256),
                })

                return { analysisTs, reportTs, mapTypeObj }
            }

            return commit('initRaderShort', raderShortFunc)

        },

        // 雨雲レーダーの更新(一定時間ごとに自分自身を呼ぶ)
        // Option
        //  opacity: 一定時間経過後の透過度
        //  duration: 上記 opacity になるまでの時間
        async updateRader({ state, commit, dispatch }, option = {}) {

            const opacity = option.opacity ?? 0.5
            const duration = option.duration ?? 5000  // ms
            const raderObj = state.raderShort()
            const mapObj = state.mapObj

            if (mapObj.overlayMapTypes.getLength() > 0) {
                mapObj.overlayMapTypes.removeAt(0)
            }

            mapObj.overlayMapTypes.insertAt(0, raderObj.mapTypeObj)

            commit('setRaderUpdate', `${raderObj.analysisTs.toLocalString}`)

            raderObj.mapTypeObj.setOpacity(1.0)
            for (let opc = 1.0; opc >= opacity; opc -= (1 - opacity) / 5) {
                await wait(duration / 5)
                raderObj.mapTypeObj.setOpacity(opc)
            }

            return setTimeout(() => dispatch('updateRader'), 10 * 60 * 1000)
        },

        async setBrm({ commit, state, rootGetters }) {

            const currentBrm = rootGetters['currentBrm']
            const currentBrmId = currentBrm.brmId

            commit('currentBrm', currentBrm)

            // 開発用の mock timestamp を返す関数を設定
            commit('setMockFunc')

            if (state.brmReady) {
                //commit('setUpdateFlag', false)
                return 'brmAlreadyLoaded'
            }
            // everyStart は initPoly の中で設定
            try {
                // ブルベルートデータを取得
                if (state.linkCode) {
                    const result = await axios.get(`/api/link/${state.linkCode}`)

                    commit('brmRoute', result.data)
                    commit('setBrmReady')
                } else {
                    commit('brmRoute', null)
                    throw new Error(`setBrm: リンクコードがありません.`)
                }
            } catch (error) {
                commit('brmRoute', null)
                throw new Error(`setBrm: BRMデータの取り込みエラー. ${error}`)
            }
        },

        async initTrack({ commit, state }) {
            // BRMTOOL と距離の計算結果を揃えるための係数
            const DIST_CORRECTION = 1.0013
            const encoded = state.brmRoute.brmData.brm.encodedPath
            const wholeTrack = polyline.decode(encoded)
            const bb = new google.maps.LatLngBounds()

            if (state.trackReady) {
                return 'track already loaded'
            }

            const pointsArray = wholeTrack.map((pt, index) => {
                return { x: pt.lat, y: pt.lng, index }
            })

            const abbrList = simplifyPath(pointsArray).map(pt => pt.index)

            // ポイント間距離を計算して設定
            let prev = wholeTrack[0]
            prev.distance = 0.0
            for (let i = 1, len = wholeTrack.length; i < len; i++) {
                const current = wholeTrack[i]
                current.distance =
                    prev.distance +
                    hubeny(prev.lat, prev.lng, current.lat, current.lng) *
                    DIST_CORRECTION
                prev = current
            }

            const track = abbrList.map((idx) => ({
                ...wholeTrack[idx],
                index: idx,
            }))

            track.forEach(pt => {
                bb.extend(pt)
            })

            const totalDistance = track[track.length - 1].distance

            commit('setTrack', { wholeTrack, track, totalDistance, latLngBounds: bb })
        },

        async initPois({ commit, state, getters }) {

            const pois = state.brmRoute.brmData.pois.filter((poi) => {
                // start は finish とかぶるので invisible とする。データだけは使いたい。
                return ["pc", "pass", "start", "finish"].includes(poi.type)
            })

            const mapObj = getters['mapObj']

            let pcIndex = 0 // ver 1 では Poi のプロパティに PCナンバー情報がないため
            let passIndex = 0
            const markers = pois.map((poi) => {
                const type = poi.type
                if (type === 'pc') { ++pcIndex }
                if (type === 'pass') { ++passIndex }
                const label = poi.properties.pcLabel ?? (type === 'pc' ? pcIndex : (type === 'pass' ? passIndex : ''))
                const position = state.wholeTrack[poi.attachedPointIndex]

                // BRMTOOL2 と同様のアイコンを動的作成
                const icon = markerIcon({
                    type,
                    label,
                })

                const markerObj = new google.maps.Marker({
                    map: mapObj,
                    position,
                    icon: icon,
                })

                // スタートのアイコンは永久に invisible
                if (poi.type === "start") {
                    markerObj.setVisible(false)
                }

                poi.markerObj = markerObj

                // markerのオブジェクト以外に POI 情報も持たせておく
                return { markerObj, type, poi }
            })

            commit('setPoiMarkers', markers)

        },

        async initPoly({ commit, state, getters }) {

            // 全体：固定
            const trackPath = new google.maps.MVCArray()
            // ルートの LatLngBounds
            const bb = new google.maps.LatLngBounds()

            state.track.forEach(pt => {
                // 間引きコースで計算
                const latLng = new google.maps.LatLng(pt)
                trackPath.push(latLng)
                bb.extend(latLng)
            })

            const routePoly = new google.maps.Polyline({
                map: state.mapObj,
                path: trackPath,
                strokeColor: "red",
                strokeOpacity: 1,
                strokeWeight: 1,
                zIndex: 20,
            })

            const everyStart = state.currentBrm.starts.map(st => {
                const start = st.start_time
                const close = st.close_time
                const polyObj = new google.maps.Polyline()
                const polyColor = POLYCOLOR.get(st.type)
                const polyPath = new google.maps.MVCArray()


                polyObj.setOptions({
                    map: state.mapObj,
                    path: polyPath,
                    strokeColor: polyColor,
                    strokeOpacity: 1,
                    strokeWeight: 10,
                })

                return { start, close, polyObj, color: polyColor, polyPath, latLngBounds: null }
            })

            commit('setPoly', { bb, routePoly, everyStart })

        },

        async updateEveryStart({ commit, state, getters }, option) {

            if (state.brmReady !== true) {
                return
            }
            const ts = option.ts ?? Date.now()  // mockTs を指定できるように
            commit('incrementPolyOrder')
            const zIndexOrder = state.polyOrder % 2 === 0 ? 1 : -1 // 正順・逆順交互

            await wait(2000)

            state.everyStart.forEach((group, index) => {

                const elapsed = getters.elapsed({ ts, group })   // ms
                const headDist = getters.distance({ elapsed, speed: FASTEST_AVG_SPEED })
                const headPoint = getters.point({ distance: headDist })
                const tailDist = getters.distance({ elapsed, speed: SLOWEST_AVG_SPEED })
                const tailPoint = getters.point({ distance: tailDist })

                const zIndex = POLYLINE_ZINDEX + zIndexOrder * index

                commit('updateEveryStart', {
                    index,
                    points: getters.slice({ begin: tailPoint, end: headPoint }),
                    zIndex,
                    distance: {
                        head: headDist,
                        tail: tailDist
                    },
                    point: {
                        head: headPoint,
                        tail: tailPoint
                    }
                })
            })
        },

        initQueryPoints({ commit, state }) {

            if (state.brmReady !== true) {
                return
            }

            if (state.queryPointsReady) {
                return 'queryPoints already setup'
            }

            const track = state.track
            const queryPoints = []
            let distance = 0

            for (let i = 0, len = track.length; i < len; i++) {
                const pt = track[i]
                if (pt.distance - distance > WEATHER_DISTANCE) {
                    queryPoints.push({
                        cityID: null,
                        position: pt,
                        ts: null,
                        date: null,
                        type: 'onRoute'
                    })
                    distance = pt.distance
                } else {
                    continue
                }
            }

            const div = WEATHER_DIVIDE_NUM
            const ne = state.latLngBounds.getNorthEast()
            const sw = state.latLngBounds.getSouthWest()
            const latGap = (ne.lat() - sw.lat()) / div
            const lngGap = (ne.lng() - sw.lng()) / div
            for (let y = -2; y <= div + 2; y++) {
                for (let x = -2; x <= div + 2; x++) {
                    queryPoints.push({
                        cityID: null,
                        position: {
                            lat: sw.lat() + latGap * y,
                            lng: sw.lng() + lngGap * (x + (y % 2) / 2), // 千鳥模様に
                        },
                        type: "reference",
                    })
                }
            }

            commit('setQueryPoints', queryPoints)

        },

        async getWeather({ commit, dispatch, getters }, query) {

            const position = query.position

            await dispatch('gcWeatherCache')
            const cached = getters.cachedWeather(position)

            if (cached) {

                if (cached.ts + WEATHER_FETCH_INTERVAL > Date.now()) {
                    return
                } else {
                    return (async () => {
                        const data = await openWeather.apiCall(position.lat, position.lng)
                        commit('storeWeatherCache', { position, data })
                    })()
                }
            } else {

                const data = await openWeather.apiCall(position.lat, position.lng)
                commit('storeWeatherCache', { position, data })
                return

            }
        },

        gcWeatherCache({ commit }) {
            commit('gcWeatherCache')
        },

        async fetchWeather({ commit, state, dispatch, getters }) {

            const queryPoints = state.queryPoints

            for (let i = 0, len = queryPoints.length; i < len; i++) {
                const queryPt = queryPoints[i]
                const ts = queryPt.ts
                if (ts + WEATHER_FETCH_INTERVAL > Date.now()) {
                    continue
                }
                const position = queryPt.position

                try {
                    await dispatch('getWeather', { position })
                    const queryResult = getters.cachedWeather(position).data

                    const cityID = queryResult.id

                    commit('updateQueryPoint', {
                        index: i,
                        cityID,
                        ts: Date.now(),
                        data: queryResult
                    })

                    commit('updateWeather', [{
                        cityID,
                        ts: Date.now(),
                        position: new google.maps.LatLng({
                            lat: queryResult.coord.lat,
                            lng: queryResult.coord.lon,
                        }),
                        data: queryResult
                    }])

                } catch (error) {
                    console.error(`fetchWeather: error: ${error}`)
                }
            }

            commit('incrementWeatherReportUpdate')

            return 'fetchWeather end'
        },

        clearWeatherMarkers({ commit }) {
            commit('clearWeatherMarkers')
        },

        // weather report の
        async getCityList({ commit, state }) {

            const list = Array.from(state.weatherReports.keys())
            if (list.length === 0) return

            const result = await axios({
                method: "post",
                url: "/api/openweather/city",
                data: {
                    id: list,
                },
            })

            commit('setCities', result.data ?? [])

        },

        allocateWindMarkers({ commit, state, getters, dispatch }) {
            if (state.queryPoints.length === 0) {
                return
            }

            dispatch('clearWeatherMarkers')

            if (!state.mapObj) {
                return
            }

            state.queryPoints.forEach(pt => {
                if (!pt.data || !pt.data.wind) return


                const closest = getters.closestMarker(pt.position)
                //　距離が近すぎなくなるように
                if (closest < WEATHER_MARKER_DENSITY) return

                const velocity = pt.data.wind.speed
                const direction = pt.data.wind.deg
                const iconURL = gmapIcon.wind(velocity, direction)
                const mapObj = state.mapObj

                const markerObj = new google.maps.Marker({
                    position: pt.position,
                    map: mapObj,
                    icon: {
                        url: iconURL,
                        anchor: new google.maps.Point(16, 16),
                        scaledSize: new google.maps.Size(
                            48,
                            32
                        ),
                    },
                })

                commit('addWeatherMarker', markerObj)

            })
        },

        allocateTemperatureMarkers({ commit, state, getters, dispatch }) {
            if (state.queryPoints.length === 0) {
                return
            }

            dispatch('clearWeatherMarkers')

            if (!state.mapObj) {
                return
            }

            state.queryPoints.forEach(pt => {
                if (!pt.data || !pt.data.wind) return


                const closest = getters.closestMarker(pt.position)
                //　距離が近すぎなくなるように
                if (closest < WEATHER_MARKER_DENSITY) return

                const temp = pt.data.main.temp
                const iconURL = gmapIcon.temp(temp)
                const mapObj = state.mapObj

                const markerObj = new google.maps.Marker({
                    position: pt.position,
                    map: mapObj,
                    icon: {
                        url: iconURL,
                        anchor: new google.maps.Point(16, 16),
                        scaledSize: new google.maps.Size(
                            32,
                            32
                        ),
                    },
                })

                commit('addWeatherMarker', markerObj)
            })
        },

        allocateWeatherMarkers({ commit, state, getters, dispatch }) {

            if (state.weatherReports.size === 0) {
                return
            }

            dispatch('clearWeatherMarkers')

            if (!state.mapObj) {
                return
            }

            const cities = state.cities

            state.weatherReports.forEach((value, key) => {
                const city = cities.find(city => city.id === key)
                if (!city) return
                const closest = getters.closestMarker(city)
                if (closest < WEATHER_MARKER_DENSITY) return

                const data = value.data
                const iconName = data.weather[0].icon
                const iconURL = `/image/weather_my/${WEATHER_ICON.get(iconName)[1]}.png`
                const mapObj = state.mapObj

                const markerObj = new google.maps.Marker({
                    position: city, // lat, lng プロパティーを含む
                    map: mapObj,
                    icon: {
                        url: iconURL,
                        anchor: new google.maps.Point(16, 16),
                        scaledSize: new google.maps.Size(
                            32,
                            32
                        ),
                    },
                })

                commit('addWeatherMarker', markerObj)
            })

        },

        // 左上の情報欄
        // Params:
        //  false - 消す
        //  true - つける
        //  null - 内容を消去して消す
        //  { title, content } - 内容を設定してつける
        setInfo({ state, commit }, param) {

            const infoObj = {
                ...state.info
            }

            if (param === false) {
                infoObj.visible = false
            } else if (param === true) {
                infoObj.visible = true
            } else if (param === null) {
                infoObj.visible = false
                infoObj.title = this.info.content = ""
            } else if (typeof param === "object") {
                infoObj.title = param.title
                infoObj.content = param.content
            }

            commit('setInfo', infoObj)

            if (typeof param === "object") {
                setTimeout(() => {
                    commit('setInfo', { visible: true })
                }, 10)
            }
        }
    }
}

