import GmapViewStore from "@/store/gmapViewStore.js"

export default {

    namespaced: true,

    modules: {
        gmapView: GmapViewStore,
    },

    state: () => ({

        fixedGreetings: [
            "ようこそAR中部へ!",
            "Welcome to AR Chubu!",
        ],

        greetings: [
            { msg: "大変よくできました", lang: "", speechLang: "ja-JP" },
            { msg: "おつかれさま～", lang: "", speechLang: "ja-JP" },
            { msg: "おめでとうさん", lang: "", speechLang: "ja-JP" },
            { msg: "ずいぶん厳しかったでしょう", lang: "", speechLang: "ja-JP" },
            { msg: "あなたを信じてましたよ", lang: "", speechLang: "ja-JP" },
            { msg: "待ってましたよ", lang: "", speechLang: "ja-JP" },
            { msg: "お帰りんさい", lang: "", speechLang: "ja-JP" },
            { msg: "Good Job!", lang: "", speechLang: "en-US" },
            { msg: "Well done!", lang: "", speechLang: "en-US" },
            { msg: "Welcome back!", lang: "", speechLang: "en-US" },
            { msg: "You did it!", lang: "", speechLang: "en-US" },
            { msg: "Bien joué!", lang: "フランス語", speechLang: "fr-FR", translate: "Good job!" },
            { msg: "Très bien", lang: "フランス語", speechLang: "fr-FR", translate: "Very good" },
            { msg: "Bravo!", lang: "", speechLang: "en-US" },
            { msg: "Complimenti!", lang: "イタリア語", speechLang: "it-IT", translate: "Congratulations!" },
            { msg: "Fantastico!", lang: "イタリア語", speechLang: "it-IT", translate: "Fantastic!" },
            { msg: "Молодец!", lang: "ロシア語", speechLang: "ru-RU", translate: "Well done!" },
            { msg: "¡buen trabajo!", lang: "スペイン語", speechLang: "es-ES", translate: "Good job!" },
            { msg: "bom trabalho!", lang: "ポルトガル語", speechLang: "pt-BR", translate: "Good job!" },
            { msg: "Gute Arbeit!", lang: "ドイツ語", speechLang: "de-DE", translate: "Good job!" },
            { msg: "Goed Gedaan!", lang: "オランダ語", speechLang: "nsl-NL", translate: "Well done!" },
            { msg: "καλή δουλειά !", lang: "ギリシャ語", speechLang: null, translate: "Good job!" },
            { msg: "Bra Jobbat!", lang: "スウェーデン語", speechLang: null, translate: "Good job!" },
            { msg: "做 得 好 !", lang: "中国語", speechLang: "zn-CN", translate: "Good job!" },
            { msg: "잘 했 어 !", lang: "韓国語", speechLang: "ko-KR", translate: "Good job!" },
            { msg: "ทำได้ดีมาก !", lang: "タイ語", speechLang: null, translate: "Well done!" },
            { msg: "bonus labor !", lang: "ラテン語", speechLang: null, translate: "Good work!" },
            { msg: "сайн ажил!", lang: "モンゴル語", speechLang: null, translate: "Good job!" },
            { msg: "أحسنت!", lang: "アラビア語", speechLang: null, translate: "I did well!" },
            { msg: "עבודה טובה!", lang: "ヘブライ語", speechLang: null, translate: "Good job!" },
            { msg: "iyi bir iş!", lang: "トルコ語", speechLang: null, translate: "Good job!" },
            { msg: "хороша робота!", lang: "ウクライナ語", speechLang: null, translate: "Good work!" },
        ],

        everyStart: [],


    }),

    getters: {
        greetings(state) {
            return state.greetings
        },

        everyStart(state) {
            return state.everyStart
        }
    },

    mutations: {

        updateFixedMessages(state, messages) {
            state.fixedGreetings = messages
        },

        setEveryStart(state, arr) {
            state.everyStart = arr
        }
    },

    actions: {

        // メッセージ（fixedMessage）の更新
        updateFixedMessages({ commit }, messages) {
            commit('updateFixedMessages', messages)
        },
    }
}