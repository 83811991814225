<!-- 
    Communiction 相手の発言
-->

<template>
    <div class="row">
        <div class="col-10 col-md-8" :style="baseStyle">
            <div style="margin-left: 10px">
                {{ header
                }}<span class="pl-2 font-weight-bold font-tiny"
                    >@{{ updatedAt }}{{ edited }}</span
                >
            </div>
            <div class="talk-left">
                <div class="subject">
                    <b>{{ subject }}</b>
                </div>
                <div class="content" v-for="line in contentLines">
                    {{ line }}
                </div>
            </div>
            <el-link
                type="primary"
                style="position: absolute; bottom: 0; right: -1.5em"
                v-if="userId"
                @click="reply"
                >返信</el-link
            >
        </div>
    </div>
</template>

<script>
export default {
    props: {
        header: String,
        createdAt: String,
        updatedAt: String,
        subject: String,
        content: String,
        userId: Number,
        color: {
            type: String,
            default: "default",
        },
    },
    data() {
        return {}
    },

    computed: {
        contentLines() {
            return this.content.split("__NL__")
        },
        edited() {
            if (this.createdAt === this.updatedAt) {
                return ""
            } else {
                return " 変更"
            }
        },
        baseStyle() {
            return {
                "--bg-color": this.color === "default" ? "#e2ffc7" : "#ffd4c7",
            }
        },
    },
    methods: {
        reply() {
            this.$emit("reply")
        },
    },
}
</script>

<style scoped>
.talk-left {
    border: grey 2px solid;
    padding: 5px;
    margin-left: 10px;
    border-radius: 5px;
    box-sizing: border-box;
    background: var(--bg-color);
}
.talk-left:after {
    content: "";
    position: absolute;
    top: 50%;
    left: 10px;
    margin-top: -2px;
    border: 8px solid transparent;
    border-right: 8px solid grey;
    box-sizing: border-box;
    z-index: 1;
}
.talk-left:before {
    content: "";
    position: absolute;
    top: 50%;
    left: 15px;
    margin-top: 0px;
    border: 6px solid transparent;
    border-right: 6px solid var(--bg-color);
    box-sizing: border-box;
    z-index: 2;
}
</style>
