<template>
    <div class='clublist'>
        <h2>日本のブルベクラブリスト</h2>
        <el-table
            :data='clubs'>
            <el-table-column
                prop='code'
                label='ACP CODE'>

            </el-table-column>
            <el-table-column
                prop='name'
                label='団体名'>
        </el-table-column>
    </el-table>

</div>
    
    
</template>

<script>
export default {

    data(){
        return {
            clubs: [],
        }
    },
    
    mounted(){
        axios.get('/api/club')
                .then(res => {
                    this.clubs = res.data
                })
                .catch(error => console.log(error));
        }
    }
</script>

<style>
    
</style>
