<!--

    ゴール番

-->
<template>
    <div>
        <el-card ref="goalBot" :style="headStyle">
            <template slot="header">
                <div class="head-pre">
                    <i class="el-icon-bicycle pr-1"></i>ゴール受付
                </div>
                <div class="head-clock">
                    <i class="el-icon-alarm-clock pr-1 clock-icon"></i>{{ digitalClock }}
                </div>
                <div class="head-title text-center">
                    {{ greeting
                    }}<span v-if="language !== ''" style="font-size: 60%">（{{ language }}{{ translate }}）</span>
                </div>
                <div class="voicevox-credit">powered by VOICEVOX</div>
                <div class="voicevox-speaker">
                    <b>{{ voivoCredit }}</b>
                </div>
            </template>

            <div class="row">
                <div class="col-3">
                    <el-card :body-style="{
                        ...goalBotCardStyle,
                        padding: '0pt',
                    }">
                        <h5 style="padding-top: 20px; padding-left: 5px">
                            COUNT DOWN
                        </h5>
                        <count-down></count-down>
                        <el-divider></el-divider>
                        <brm-summary :show-header="false" :show-update-button="false">
                            <template v-slot:title>{{ summaryTitle }}</template>
                        </brm-summary>
                    </el-card>
                </div>
                <div class="col-9">
                    <keep-alive>
                        <component :is="currentComponent" :body-style="goalBotCardStyle" :finishers-count="finishersCount"
                            :bodyHeight="tableHeight" :table-refresh="tableRefresh" @forceLeaderBoard="forceLeaderBoard">
                        </component>
                    </keep-alive>
                    <div class="comp-sw">
                        <div class="comp-btn" @click="compChangeBtn">
                            <img :src="swIcon" class="comp-btn-img" />
                        </div>
                    </div>
                </div>
            </div>
        </el-card>
        <div class="voivo-picture">
            <img v-if="voivoSpeaking" :src="voivoPicture" />
        </div>
        <live-announce></live-announce>
    </div>
</template>

<script>
import { mapState, mapActions, mapGetters } from "vuex"
import BrmSummary from "@/components/BrmSummary"
import CountDown from "@/components/CountDown"
import LeaderBoardView from "@/components/goalBot/LeaderBoardView"
import GoogleMapView from "@/components/goalBot/GmapView"
import LiveAnnounce from "@/components/goalBot/LiveAnnounce.vue"

import {
    idleSpeaks,
    fixedPhrases,
} from "@/components/goalBot/PrecacheSentences.js"

const status_tags = {
    canceled: "キャンセル",
    dnf: "DNF",
    dns: "DNS",
    registered: "走行中",
    overtime: "OT",
    finish: "FIN",
}
const status_tag_types = {
    canceled: "",
    dnf: "danger",
    dns: "warning",
    registered: "info",
    overtime: "",
    finish: "success",
}

const statusList = [
    { status: "registered", name: "走行中" },
    { status: "overtime", name: "OT" },
    { status: "finish", name: "完走" },
    { status: "dns", name: "DNS" },
    { status: "dnf", name: "DNF" },
    { status: "canceled", name: "除外" },
]

const headColor = [
    { bg: "#38c172", text1: "#ebeddf", text2: "#006454" }, // グリーン系
    { bg: "#0168b3", text1: "#edc600", text2: "#f9e697" }, // ブルー系
    { bg: "#edc600", text1: "#0168b3", text2: "#261e1c" }, // イエロー系
    { bg: "#ab045c", text1: "#f2dae8", text2: "#f2dae8" }, // エンジ系
    { bg: "#c60019", text1: "#e4f0fc", text2: "#ebeddf" }, // レッド系
]

const ZUNDA = 3 // ずんだもん・ノーマル
const METAN = 2 // 四国めたん・ノーマル
const SORA = 19 // 九州そら・ささやき
const RYUSEI = 13 // 青山龍星・ノーマル
const FEMALE_NORMAL = [2, 3, 8, 10, 14, 16]
const FEMALE_SEXY = [4, 5, 17]
const FEMALE_SWEET = [0, 1, 15]

const EFFECTS = ["drum", "kodutumi", "fanfare", "applause", "hyoushigi"]

export default {
    components: {
        BrmSummary,
        CountDown,
        LeaderBoardView,
        GoogleMapView,
        LiveAnnounce,
    },

    data() {
        return {
            goalBotObj: null, // GoalBot (card) DOM
            goalBotCardBodyObj: null, // GoalBot からヘッダーを除いた下側 DOM

            detailVisible: false,
            detailEntryId: null,
            checkAll: false,
            statusList, // DB の 'status' と表示の対応表
            checkedStatus: ["finish", "overtime"],
            isIndeterminate: true,

            buffer: "", // キーボードバッファ（バーコードリーダー）

            tableRefresh: 1,
            tableHeight: null, // GoalBot コンテンツ部分の高さ（当初 table だけだったので tableHeight になっている）
            tableBodyObj: null,
            tableClientRect: {
                top: null,
                height: null,
                width: null,
            },

            // 定型のあいさつ
            fixedTiming: 2,
            fixedGreetingLanguage: "",

            // ヘッダーに表示する（表示中の）メッセージ
            greeting: "おつかれさまでした",
            language: "ja",
            greetingCount: 0,

            digitalClock: "clock",
            clockIcon: true,

            timerId1: null, // headerColorRotate
            timerId2: null,
            timerId3: null,
            timerId4: null,
            timerId5: null,

            headStyle: {
                "--head-bg": headColor[0].bg,
                "--head-text1": headColor[0].text1,
                "--head-text2": headColor[0].text2,
            },

            // VOICEVOX
            voivoCredit: "",
            voivoSpeaking: false,
            voivoPicture: null,

            // 実況
            reportTimerID: null,
            lastProgressReport: 0,

            // Google Map
            showMap: true,

            // 画面ローテーション
            currentComponent: GoogleMapView, //LeaderBoardView
            nextSwitchTs: 0,

            mockClock: null,
        }
    },

    watch: {
        updatedAt: {
            handler() {
                ++this.tableRefresh
            },
        },

        "current.brmId": {
            handler: function () {
                this.setMockDate()
            },
        },
    },

    computed: {
        ...mapState(["current", "goalBot"]),
        ...mapGetters(["summary"]),

        // 右上の強制変更スイッチのアイコン
        swIcon() {
            const img =
                this.currentComponent === GoogleMapView ? "lboard" : "gmap"
            return `/image/${img}_icon.svg`
        },

        fixedGreetings() {
            return this.goalBot.fixedGreetings
        },

        summaryTitle() {
            return this.current.brmTitle
        },

        goalBotCardStyle() {
            return {
                height: `${this.tableHeight}px`,
            }
        },

        updatedAt() {
            return this.current.entries.updated_at
        },

        finishersCount() {
            return this.current.entries.list.filter(
                (entry) => this.checkedStatus.includes(entry.status) // this.checkedStatus = ['finish', 'overtime'] data() で定義してある
            ).length
        },

        greetings() {
            return this.$store.getters["goalBot/greetings"]
        },
    },

    created() {
        const greeting =
            "エーアール中部ブルベへようこそ。|ゴール番のボットちゃんです。|よろしくお願いします。"

        const addition =
            Math.random() > 0.7
                ? "メモリーぶそくのために|ときどきアプリを再起動します。"
                : ""

        this.$voicevox.add({
            speakText: greeting + addition,
            speaker: _.sample(FEMALE_NORMAL),
            priority: "idle",
            once: true,
            cancellable: true,
            life: 30_000,
            idlePeriod: 300_000,
            effect: _.sample(EFFECTS),
        })

        this.$store.dispatch("resetReloadCount")

        this.headerColorRotate()

        this.headerMsgRotate()

        this.idleSpeak()
        this.progressReport()

        // 右上のデジタル時計の設定
        this.setClock()

        this.updateEntryList() // every 30sec

        window.addEventListener("voivospeak", (ev) => {
            const speaker = ev.detail
            if (!speaker) return // うまく Speaker 情報が送られないことがある（データの更新遅延の関係？）
            this.voivoCredit = `${speaker.name} is speaking...`
            this.voivoSpeaking = true
            this.voivoPicture = `/image/${speaker.picture}`
        })

        window.addEventListener("voivospeakend", () => {
            this.voivoCredit = ""
            this.voivoSpeaking = false
            this.voivoPicture = null
        })

        // 画面をリーダーボードとマップで入れ替える
        this.rotateView()

        // 開発用のニセクロックの設定
        this.setMockDate()

        // Voicevox で予めセリフを作っておく（外部ファイルでエクスポート・インポート可能）
        this.precachePhrases()
    },

    mounted() {
        // バーコードリーダー用
        window.document.addEventListener("keydown", this.keydown)
        window.document.addEventListener("keyup", this.keyup)

        this.onResized()
        window.addEventListener("resize", this.onResized)

        this.tableBodyObj = document.querySelector("#leaderTable tbody")
    },

    beforeDestroy() {
        window.document.removeEventListener("keydown", this.keydown)
        window.document.removeEventListener("keyup", this.keyup)
        window.removeEventListener("resize", this.onResized)
    },

    methods: {
        ...mapActions(["getEntryList", "getSummary"]),

        updateEntryList() {
            this.getEntryList()
            setTimeout(this.getEntryList, 30_000)
        },

        keydown(e) {
            if (e.key !== "Tab" && e.key !== "Enter" && e.key !== "Shift") {
                this.buffer += e.key
            }
        },

        keyup(e) {
            if (e.key === "Tab" || e.key === "Enter") {
                this.submit(this.buffer)
                this.buffer = ""
            }
        },

        submit: async function (qr) {
            const hash = qr.split("/").pop()

            if (hash.match(/[0-9a-f]{8}/)) {
                // ハッシュコードである
                try {
                    const res = await axios.post("/api/finish", {
                        brmId: this.current.brmId,
                        hash,
                    })

                    // ビューモードを強制的に LeaderBoardView に変える
                    this.rotateView("leader")

                    const startTs = new Date(
                        res.data.start_time_min * 60 * 1000
                    )
                    const finishTs = new Date(
                        res.data.finish_time_min * 60 * 1000
                    )
                    const startDate = startTs.getDate()
                    const finishDate = finishTs.getDate()
                    const finishHour = finishTs.getHours()
                    const finishMinute = finishTs.getMinutes()

                    this.goalBanner({
                        status: res.data.result,
                        content: {
                            name: res.data.user.name,
                            kana: res.data.user.participant.name_kana,
                            sex: res.data.user.participant.sex,
                            finish: res.data.finish_time,
                            finishDate:
                                startDate === finishDate ? null : finishDate,
                            finishHour,
                            finishMinute,
                            goal: res.data.goal_time,
                            goalHour: Math.floor(res.data.goal_time_min / 60),
                            goalMinute: res.data.goal_time_min % 60,
                        },
                    })
                    this.getEntryList()
                    this.getSummary()
                } catch (error) {
                    if (error.response) {
                        this.goalBanner({
                            status: "error",
                            content: {
                                message: error.response.data.message,
                            },
                        })
                    }
                }
            } else {
                this.goalBanner({
                    status: "error",
                    content: {
                        message: "ブルベのQRコードではないようです...",
                    },
                })
            }
        },

        bannerVNode({ status, content }) {
            const h = this.$createElement

            switch (status) {
                case "finish":
                    return h("div", { class: "banner" }, [
                        h(
                            "h1",
                            null,
                            `C O N G R A T S !　${content.name.replace(
                                /[ 　]+/,
                                " "
                            )} さん`
                        ),
                        h(
                            "h1",
                            null,
                            `フィニッシュ ${content.finish} 完走時間 ${content.goal}`
                        ),
                        h(
                            "h3",
                            { class: "banner-error" },
                            "ブルベカードに時刻を記入して受付まで"
                        ),
                    ])
                    break

                case "overtime":
                    return h("div", { class: "banner" }, [
                        h(
                            "h1",
                            null,
                            `O V E R T I M E !　${content.name.replace(
                                /[ 　]+/,
                                " "
                            )} さん`
                        ),
                        h(
                            "h3",
                            { class: "banner-error" },
                            "お疲れさまでした. とりあえず受付まで"
                        ),
                    ])
                    break
                case "error":
                    return h("div", { class: "banner" }, [
                        h("h1", null, "E R R O R !"),
                        h(
                            "h2",
                            { class: ["banner-error", "banner-error-message"] },
                            content.message
                        ),
                        h(
                            "h3",
                            { class: "banner-error" },
                            "もう一回スキャンするか受付まで..."
                        ),
                    ])
                    break
            }
        },

        // Element-UI を利用している
        goalBanner(contents) {

            // 何回もスキャンしてエラーが連続するのでエラーは無視する
            if(contents.status === 'error') return

            const instance = this.$message({
                message: this.bannerVNode(contents),
                customClass: "goal-banner",
                iconClass: "banner-icon el-icon-bicycle",
                duration: 3000,
                showClose: true,
                offset: 500,
            })
            setTimeout(() => {
                if (instance) {
                    instance.close()
                }
            }, 5000)

            this.goalSpeechVoiceVox(contents)
        },

        // VOICEVOX 版
        //  status: 'finish', 'overtime', ..
        //  content: {
        //      name: res.data.user.name,
        //      kana: res.data.user.participant.name_kana,
        //      sex: M/F
        //      finish: res.data.finish_time,
        //      finishDate:
        //      finishHour,
        //      finishMinute,
        //      goal:
        //      goalHour:
        //      goalMinute:
        async goalSpeechVoiceVox({ status, content }) {
            let speakText = ""

            switch (status) {
                case "finish":
                    speakText = content.sex === "F" ? "おかえりなさいませ|" : ""
                    speakText +=
                        `${content.kana}さん|フィニッシュタイムは|` +
                        `|${content.finishDate ? content.finishDate + "日" : ""
                        }|` +
                        `${content.finishHour}時|${content.finishMinute}分|` +
                        `認定タイムは|${content.goalHour}時間|${content.goalMinute}分|です|`

                    await this.$voicevox.add({
                        effect: _.sample(EFFECTS),
                        speakText,
                        priority: "primary",
                        speaker: content.sex === "F" ? RYUSEI : METAN,
                        terminate: true,
                    })

                    speakText = "タイムをカードに記入して|受付までどうぞ|"
                    speakText +=
                        content.sex === "F"
                            ? "世界に|ひとつ、|あなただけの|やきメダルは|いかがですか?|"
                            : (Math.random() > 0.9
                                ? "やきメダルは|いかがですか?|"
                                : "")

                    await this.$voicevox.add({
                        speakText,
                        priority: "secondary",
                        once: true,
                        speaker: content.sex === "F" ? RYUSEI : ZUNDA,
                        terminate: false,
                    })
                    break
                case "overtime":
                    speakText = content.sex === "F" ? "おかえりなさいませ|" : ""
                    speakText +=
                        `${content.kana}さん|フィニッシュタイムは|` +
                        `|${content.finishDate ? content.finishDate + "日" : ""
                        }|` +
                        `${content.finishHour}時|${content.finishMinute}分|` +
                        `でオーバータイムです|`
                    await this.$voicevox.add({
                        speakText,
                        priority: "primary",
                        speaker: content.sex === "F" ? RYUSEI : METAN,
                        terminate: true,
                    })
                    await this.$voicevox.add({
                        speakText: "とりあえず|受付までどうぞ",
                        priority: "secondary",
                        once: true,
                        speaker: content.sex === "F" ? RYUSEI : ZUNDA,
                        terminate: false,
                    })
                    break
                case "error":
                    await this.$voicevox.add({
                        speakText:
                            "なんかエラーですって。",
                        priority: "primary",
                        once: true,
                        speaker: SORA,
                        terminate: true,
                    })
                    break
            }
        },

        cellStyle({ column }) {
            switch (column.label) {
                case "No":
                case "氏名":
                case "ゴールタイム":
                case "認定タイム":
                    return { "font-size": "20px", "font-weight": "bold" }
            }
        },

        headerRowStyle({ row, rowIndex }) {
            return { height: Math.floor(this.tableHeight / 12) + "px" }
        },

        onResized() {
            setTimeout(() => {
                this.goalBotObj = this.$refs.goalBot.$el
                this.goalBotCardBodyObj =
                    this.goalBotObj.querySelector(".el-card__body")

                const goalBotCardClientRect =
                    this.goalBotCardBodyObj.getBoundingClientRect()
                this.tableHeight =
                    this.$store.state.dimension.height -
                    goalBotCardClientRect.top
            }, 10)
        },

        headerMsgRotate() {
            ++this.greetingCount

            const randomMsg =
                this.greetings[
                Math.floor(Math.random() * this.greetings.length)
                ]

            if (
                this.fixedGreetings.length > 0 &&
                this.fixedTiming &&
                this.greetingCount % this.fixedTiming === 0
            ) {
                const fixedCount = Math.floor(
                    this.greetingCount / this.fixedTiming
                )
                const fixedLength = this.fixedGreetings.length
                this.greeting = this.fixedGreetings[fixedCount % fixedLength]
                this.language = this.fixedGreetingLanguage
            } else {
                this.greeting = randomMsg.msg
                this.language = randomMsg.lang
                this.translate = randomMsg.translate
                    ? `・${randomMsg.translate}`
                    : ""
            }
            setTimeout(this.headerMsgRotate, 30_000)
        },

        headerColorRotate() {
            const randomStyle =
                headColor[Math.floor(Math.random() * headColor.length)]
            this.headStyle = {
                "--head-bg": randomStyle.bg,
                "--head-text1": randomStyle.text1,
                "--head-text2": randomStyle.text2,
            }
            setTimeout(this.headerColorRotate, 35_000)
        },

        setClock() {
            const ts = new Date()
            const hh = ts.getHours()
            const mm = ("00" + ts.getMinutes()).slice(-2)
            const ss = ("00" + ts.getSeconds()).slice(-2)
            const ms = ts.getMilliseconds()
            this.digitalClock = `${hh}:${mm}:${ss}`

            this.clockIcon = ms > 250 ? false : true

            setTimeout(this.setClock, 500)
        },

        // あらかじめセリフを仕込んでおく
        async precachePhrases() {
            // 最終クローズ時間
            const closed = this.current.starts.reduce((current, start) => {
                const closed_time = new Date(start.close_time).getTime()
                current = Math.max(current, closed_time)
                return current
            }, 0)

            const max_life_ms = Math.max(
                3600_000,
                closed - Date.now() + 7200_000
            ) // 開発のためにも 最低1時間の LIFE

            // 参加者名
            const entries = this.current.entries.list
            for (let i = 0, len = entries.length; i < len; i++) {
                const entry_name = `${entries[i].kana_name}さん`
                const sex = entries[i].sex
                const phraseObj = {
                    text: entry_name,
                    speaker: sex === "F" ? RYUSEI : METAN,
                    life: max_life_ms,
                }
                await this.$voicevox.cache(phraseObj)
            }

            // xx日
            for (let day = 0; day <= 2; day++) {
                const date = new Date(closed - day * 24 * 3600_000).getDate()
                this.$voicevox.cache({
                    text: `${date}日`,
                    speaker: METAN,
                    life: max_life_ms,
                })
            }

            for (let day = 0; day <= 2; day++) {
                const date = new Date(closed - day * 24 * 3600_000).getDate()
                this.$voicevox.cache({
                    text: `${date}日`,
                    speaker: RYUSEI,
                    life: max_life_ms,
                })
            }

            // xx時 / xx時間
            for (let hh = 0; hh < 24; hh++) {
                await this.$voicevox.cache({
                    text: `${hh}時`,
                    speaker: METAN,
                    life: max_life_ms,
                })
                await this.$voicevox.cache({
                    text: `${hh}時`,
                    speaker: RYUSEI,
                    life: max_life_ms,
                })
                await this.$voicevox.cache({
                    text: `${hh}時間`,
                    speaker: METAN,
                    life: max_life_ms,
                })
                await this.$voicevox.cache({
                    text: `${hh}時間`,
                    speaker: RYUSEI,
                    life: max_life_ms,
                })
            }

            // mm分
            for (let mm = 0; mm < 60; mm++) {
                await this.$voicevox.cache({
                    text: `${mm}分`,
                    speaker: METAN,
                    life: max_life_ms,
                })
                await this.$voicevox.cache({
                    text: `${mm}分`,
                    speaker: RYUSEI,
                    life: max_life_ms,
                })
            }

            // nn名
            const entryNum = this.current.entries.list.length

            for await (const speaker of [METAN, RYUSEI]) {
                for (let mm = 0; mm <= entryNum; mm++) {
                    await this.$voicevox.cache({
                        text: `${mm}名`,
                        speaker,
                        life: max_life_ms,
                    })
                }
            }

            // etc

            const precache = [...idleSpeaks, ...fixedPhrases]

            for await (const phrase of precache) {
                const life = max_life_ms
                const speakers = Array.isArray(phrase.speaker)
                    ? phrase.speaker
                    : [phrase.speaker]

                for await (const speaker of speakers) {
                    const speakObj = {
                        speakText: phrase.text,
                        speaker,
                        life,
                    }
                    await this.$voicevox.cacheSentence(speakObj)
                }
            }
        },

        forceLeaderBoard() {
            this.rotateView("leader")
        },

        compChangeBtn() {
            this.rotateView(
                this.currentComponent === LeaderBoardView ? "gmap" : "leader"
            )
        },

        // リーダーボードとマップビューを入れ替える
        // ライダーがQRコードを読み込ませるとすぐにリーダーボードに切り替える
        // 暇なうちは地図画面を長く、フィニッシュが増えると入れ替えを頻繁にする
        rotateView(force = null) {
            console.log("rotateView")

            const sum = this.summary
            const riders = Math.max(1, sum.registered - sum.canceled - sum.dns) // 0の除算を避ける
            const finishRate = Math.min(1, sum.finish / riders)
            const mapViewTime = (15 - 10 * finishRate) * 60_000 // マップ表示時間は5分～15分、帰ってくる人が多くなれば短くする
            const lbViewTime = (2 + 3 * finishRate) * 60_000

            if (
                force === "leader" &&
                this.currentComponent !== LeaderBoardView
            ) {
                this.currentComponent = LeaderBoardView
                this.nextSwitchTs = Date.now() + lbViewTime
                return
            }

            if (force === "gmap" && this.currentComponent !== GoogleMapView) {
                this.currentComponent = GoogleMapView
                this.nextSwitchTs = Date.now() + mapViewTime
                return
            }

            if (this.nextSwitchTs < Date.now()) {
                if (this.currentComponent === LeaderBoardView) {
                    // 一定回数ごとにリロードする（どうしてもメモリーリーク? が克服できない）
                    this.$store.dispatch("checkReload").then((judge) => {
                        if (judge === true) {
                            location.reload()
                        } else {
                            this.currentComponent = GoogleMapView
                            this.nextSwitchTs = Date.now() + mapViewTime
                        }
                    })
                } else if (this.currentComponent === GoogleMapView) {
                    this.currentComponent = LeaderBoardView
                    this.nextSwitchTs = Date.now() + lbViewTime
                }
            }

            setTimeout(() => this.rotateView(), 15_000)
        },

        idleSpeak() {
            const speak = _.sample(idleSpeaks)
            const interval = Math.floor((10 + Math.random() * 5) * 60_000) // 10～15分

            const speakObj = {
                speakText: speak.text,
                speaker: _.sample(speak.speaker),
                priority: "idle",
                terminate: false,
                once: true,
                cancellable: true,
                idlePeriod: interval,
                life: interval,
            }

            this.$voicevox.add(speakObj)
            setTimeout(this.idleSpeak, interval)
        },

        progressReport() {
            if (this.reportTimerID) {
                clearTimeout(this.reportTimerID)
            }

            const now = new Date()
            const min = now.getMinutes()
            if (
                (min !== 0 && min !== 30) ||
                this.lastProgressReport + 20 * 60_000 > Date.now()
            ) {
                // 繰り返しレポートしてしまうのを防ぐ
                this.reportTimerID = setTimeout(this.progressReport, 15_000)
                return
            }

            const hour = now.getHours()

            const summary = this.current.summary
            const entry = summary.total - summary.canceled
            const dns = summary.dns
            const start = entry - dns
            const finish = summary.finish
            const dnf = summary.dnf
            const ride = start - (finish + dnf)

            const time =
                `ただいま|時刻は|${hour}時|` +
                (min === 0 ? `ちょうど|` : "") +
                (min === 30 ? `30分|` : "") +
                `です。|途中経過をお知らせします。|`
            const progress =
                `エントリー|${entry}名|{50}|` +
                `DNS|${dns}名|{50}|出走|${start}名|{50}|完走|${finish}名|{50}|DNF|${dnf}名|` +
                `で、現在|${ride}名|が走行中です。`

            const speakObj = {
                speakText: time + progress,
                speaker: _.sample([METAN, RYUSEI]),
                priority: "secondary",
                terminate: false,
                cancellable: false,
                effect: "jihou",
                life: 30_000,
            }

            this.$voicevox.add(speakObj)
            this.lastProgressReport = Date.now()
            this.reportTimerID = setTimeout(this.progressReport, 15_000)
        },

        // 開発用の模擬 Date を設定
        setMockDate() {
            const mockFunc = this.$store.getters["goalBot/gmapView/mockFunc"]

            if (typeof mockFunc === "function") {
                this.mockClock = mockFunc()
            }

            setTimeout(this.setMockDate, 1000)
        },
    },
}
</script>

<style scoped>
::v-deep .bold-column {
    font-weight: bold;
}

::v-deep .el-card__header {
    position: relative;
    transition: background-color 1s;
    background-color: var(--head-bg);
}

::v-deep .el-card__body {
    padding: 0;
}

.head-title {
    margin-top: 15px;
    font-size: 72px;
    transition: color 1s;
    color: var(--head-text1);
}

.head-pre {
    position: absolute;
    top: 0px;
    font-size: 36px;
    color: var(--head-text2);
}

.head-clock {
    position: absolute;
    right: 0px;
    top: 0px;
    padding-right: 40px;
    font-size: 36px;
    color: var(--head-text2);
}

.head-post {
    color: var(--head-text2);
    font-size: 30px;
}

.voicevox-credit {
    position: absolute;
    bottom: 0;
    right: 0;
    padding-right: 15px;
    font-size: 18px;
    color: var(--head-text2);
}

.voicevox-speaker {
    position: absolute;
    bottom: 0;
    left: 0;
    padding-left: 15px;
    font-size: 18px;
    color: var(--head-text2);
}

.voivo-picture {
    z-index: 1000;
    position: absolute;
    bottom: 50px;
    left: 50px;
}

.banner {
    color: #ff8179;
    padding: 20px;
}

.banner-error {
    color: darkred;
}

.banner-error-message {
    margin-left: 20px;
}

.clock-icon {
    animation: blink 1s linear infinite;
}

.comp-sw {
    position: absolute;
    top: 0px;
    right: 15px;
    background: #00000040;
    /* margin: 10px; */
    width: 60px;
    height: 60px;
    border-radius: 30px;
}

.comp-btn {
    text-align: center;
    line-height: 60px;
}

.comp-btn-img {
    width: 36px;
    height: 36px;
}
</style>

<style>
.goal-banner {
    background: #ffe6ff !important;
    border-color: #ff00ff !important;
    border-width: 5px;
    width: 60%;
    height: 150px;
}

.banner-icon {
    font-size: 30px;
    color: #ff8179;
}
</style>
