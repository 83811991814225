import { render, staticRenderFns } from "./BrmEntryList.vue?vue&type=template&id=45013e98&scoped=true&"
import script from "./BrmEntryList.vue?vue&type=script&lang=js&"
export * from "./BrmEntryList.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "45013e98",
  null
  
)

export default component.exports