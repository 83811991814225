<!-- 

    スタッフアカウント管理

-->

<template>
    <div>
        <el-card>
            <template v-slot:header>
                <div class='row justify-content-between'>
                    <div class='col h3'>スタッフ一覧</div>
                    <div class='col-auto'><el-button type="primary" @click="newStaff">新規</el-button></div>
                </div>
            </template>
            <el-table :data="list" style="width:100%" :row-style='rowStyle'>
                <el-table-column prop='manage' width="90px" align="center">
                    <template v-slot='{row}'>
                        <el-button type="success" size='mini' @click="edit(row)">操作</el-button>
                    </template>
                </el-table-column>
                <el-table-column prop="userid" label="UserID" width='120px' align='center'></el-table-column>
                <el-table-column prop="name" label="氏 名" width="150px"></el-table-column>
                <el-table-column prop="role" label="権 限" width="150px">
                    <template v-slot='{row}'>
                        {{ row.roleName }}
                    </template>
                </el-table-column>
                
            </el-table>
        </el-card>
        <el-dialog
            :visible.sync="dialogVisible"
            width='80%'
            title="スタッフ編集">
            <div class="row my-1">
                <div class="font-weight-bold col-12 col-sm-3 col-md-2">ユーザー名</div>
                <div class="col-12 col-sm-9 col-md-10"><el-input v-model="form.userid"></el-input></div>
            </div>
            <div class="row my-1">
                <div class="font-weight-bold col-12 col-sm-3 col-md-2">氏名</div>
                <div class="col-12 col-sm-9 col-md-10"><el-input v-model="form.name"></el-input></div>
            </div>
            <div class="row my-1">
                <div class="font-weight-bold col-12 col-sm-3 col-md-2">メール</div>
                <div class="col-12 col-sm-9 col-md-10"><el-input v-model="form.email"></el-input></div>
            </div>
            <div class="row my-1">
                <div class="font-weight-bold col-12 col-sm-3 col-md-2">権限</div>
                <div class="col-12 col-sm-9 col-md-10">
                    <el-select v-model="form.role">
                        <el-option v-for="role in roles" :key="role.role" :label="role.name" :value="role.role" />
                    </el-select>
                </div>
            </div>
            <div class="row my-1 mb-2 border-bottom-1">
                <div class="font-weight-bold col-12 col-sm-3 col-md-2">パスワード</div>
                <div class="col-12 col-sm-9 col-md-10"><el-input v-model="form.password" show-password></el-input></div>
            </div>
            <div class="row my-1 justify-content-center">
                <div class="col text-center">
                    <el-button type="primary" @click="submit">変更</el-button>
                    <el-button @click="dialogVisible=false">キャンセル</el-button>
                </div>
            </div>
        </el-dialog>
    </div>
</template>

<script>
    import { mapState, mapGetters, mapActions } from 'vuex'
    
    const roles = [ 
        { role: 10, name: 'システム管理者'},
        { role: 9, name: 'スタッフ（高）'},
        { role: 7, name: 'スタッフ（通常）'},
        { role: 5, name: 'スタッフ（低）'},
        { role: 4, name: 'BOT'}
    ]
    
    export default {
            data(){
                return {
                    roles,
                    staffs: [],
                    dialogVisible: false,
                    form:{
                        id: null,
                        userid: null,
                        name: null,
                        email: null,
                        role: null,
                        password: null
                    }
                }
            },
            
            computed: {
                
                list(){
                    
                    return this.staffs.map( (staff) => {
                        return {
                            ...staff,
                            roleName: roles.find( r => staff.role >= r.role ).name
                        }
                    })
                }
                
            },
            
            created(){
                this.getStaffList()
            },
            
            methods: {
                
                rowStyle(){},

                getStaffList(){
                    axios.get('/api/stafflist')
                        .then( res => { 
                            this.staffs = res.data
                        })
                },
                
                edit({ id, userid, name, email, role}){
                    
                    this.form = Object.assign( this.form, { id, userid, name, email, role} )
                    this.dialogVisible = true
                },
                
                newStaff(){
                    
                    this.form = {
                        id: null,
                        userid: null,
                        name: null,
                        email: null,
                        role: null,
                        password: null
                    }
                    this.dialogVisible = true
                },
                
                submit: async function(){
                    await this.$confirm('送信しますか')
                        .catch(()=>{
                            this.dialogVisible=false
                            this.$message('取り消しました')
                        })
                    axios.post('/api/staffupdate', this.form, { headers: { 'X-HTTP-Method-Override': 'PUT'} })
                            .then( res=>{
                                if( res.data.status === 'ok'){
                                    this.$message({
                                        message: res.data.message,
                                        type: 'success'
                                    })
                                    this.dialogVisible = false
                                } else {
                                    this.$message({
                                        message: 'エラーです（原因不明）',
                                        type: 'error'
                                    })
                                }
                            })
                            .catch( error => {
                                this.$message({
                                    message: error.response ? error.response.data.message : 'エラーです',
                                    type: 'error',
                                    showClose: true,
                                    duration: 0
                                })
                            })
                    
                }
            },
            
            beforeDestroy(){
                //
            }
            
    }
    
</script>

<style scoped>
        
    
</style>
