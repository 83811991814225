<!--
    2023年度より導入の無人ゴールブルベ用
    ライダーのページに案内を表示
-->

<template>
    <div>
        <p style="margin-bottom:0px;">
            <span style="font-size: 18px; color: red;">今回は<b>無人ゴール</b>ブルベです</span>
            <el-button @click="visible = !visible" type="success" round size="small">{{ visible ? "閉じる" : "説明を開く"
            }}</el-button>
        </p>

        <ul class="desc" v-show="visible">
            <li>フィニッシュは<b>{{ current.goalplace }}</b>です</li>
            <li>買い物をしてレシートをもらって下さい</li>
            <li>右上の「<b>メニュー</b>」から「<b>フィニッシュ</b>」を選択して下さい</li>
            <li>フィニッシュ時間をレシートの時間に合わせて下さい</li>
            <li>フォトチェックがあった場合は写真をメールに添付して送って下さい.「<b>メニュー</b>」から「<b>本部へメール送信</b>」を選択して下さい.</li>
            <li>上記リンクでメール送信がうまくいかないときは <b>{{ siteOptions.email }}</b> 宛にお送り下さい
            </li>
        </ul>
    </div>
</template>

<script>
import { mapState } from "vuex"
export default {
    data() {
        return {
            visible: false,
        }
    },
    computed: {
        ...mapState(['current', 'siteOptions']),

        icon() {
            return `el-icon-arrow-${this.visible ? "up" : "down"}`
        },
    },
}
</script>

<style scoped>
.desc {
    list-style-position: outside;
    padding-inline: 10px;
    padding-top: 10px;
}
</style>