// メダル刻印用のイメージを作成

// 2024-2027 シーズンはメダルデザインが変わってメダルの内部に穴があるので
// 完全にデザインを新たにした

// canvas ごと返して LeaderBoard.vue の方で toBlob してダウンロードさせる
// 
// 主催者のところは予め用意したビットマップを取り込んでいる。非同期処理なので Promise ベースとした
// 

import chuchan_logo from '../../image/chuchan_logo.png'
import medal_2024 from '../../image/2024_Medal_Image.png'
import ar_chubu from '../../image/ar_chubu_mini.png'
import stencil from '../../font/STENCIL.TTF'

const loadImage = (src) => {
    return new Promise(resolve => {
        const img = new Image()
        img.onload = () => resolve(img)
        img.src = src
    })
}

const loadFont = () => {
    return new Promise(async (resolve) => {
        const font = new FontFace("Stencil", `url(${stencil}`)
        const f = await font.load()
        resolve(f)
    })
}

export const makeImage = (year, brm, name, time, kinen = false) => {

    return new Promise(async (resolve) => {

        const canvas = document.createElement('canvas')
        canvas.width = 600  // 300DPI 2inch=約5cm
        canvas.height = 600
        const ctx = canvas.getContext('2d')
        const logo = await loadImage(chuchan_logo)
        const medal = await loadImage(medal_2024)
        const arChubuChars = await loadImage(ar_chubu)
        const font = await loadFont()


        ctx.translate(283, 375) // 名前の中心を原点にしている. そのために名前を scale してもずれなかった. (2023/10/14 記)

        // 開発用 メダル外周円 Φ48mm (@300DPI)
        // ctx.save()
        // ctx.strokeStyle = 'black'
        // ctx.beginPath()
        // ctx.arc(0, 0, 283, 0, 2 * Math.PI)
        // ctx.stroke()
        // ctx.restore()

        // メダルのイメージ
        ctx.save()
        //ctx.drawImage(medal, -283, -385)
        ctx.restore()

        // ロゴ
        ctx.save()
        ctx.drawImage(logo, -100, -280)
        ctx.restore()

        // AR中部文字
        ctx.save()
        ctx.textAlign = 'center'
        ctx.textBaseline = 'middle'
        ctx.font = '48px Arial'
        ctx.fillStyle = 'black'
        ctx.fillText('AR', -190, -105)
        ctx.fillText('中部', -190, -55)
        ctx.restore()

        // name
        ctx.save()
        const maxNameWidth = 440
        const fontSizes = [...Array(100)].map((v, i) => i)
        const appSize = fontSizes.reduce((app, size) => {
            ctx.font = `bold ${size}px Arial`
            const tmpText = ctx.measureText(name)
            if (tmpText.width < maxNameWidth && size > app) {
                return size
            } else {
                return app
            }
        }, -Infinity)
        ctx.fillStyle = "black"

        // 記念メダルのときは文字を少し扁平にしてスペースをあける
        // ctx.scale(1, kinen ? 0.8 : 1)

        ctx.font = `bold ${appSize}px Arial`
        ctx.textAlign = 'center'
        ctx.textBaseline = 'middle'

        const text = ctx.measureText(name)
        const textHeight = text.actualBoundingBoxAscent + text.actualBoundingBoxDescent

        ctx.scale(1, 70 / textHeight)

        ctx.fillText(name, -15, 20)
        ctx.restore()

        // BRM, YEAR
        ctx.save()
        ctx.fillStyle = "black"
        ctx.font = 'bold 50px Arial'

        ctx.textAlign = 'left'
        ctx.textBaseline = 'middle'
        ctx.fillText(`${brm}`, 90, -55, 165)
        ctx.fillText(`${year}`, 90, -105)

        // TIME
        ctx.textAlign = 'center'
        ctx.font = 'bold 60px Arial'
        ctx.fillText(time, 0, 110)
        ctx.restore()

        // 記念メダル
        if (kinen === true) {
            ctx.save()
            ctx.fillStyle = "black"
            ctx.font = 'bold 40px Arial'
            ctx.textAlign = 'center'
            ctx.textBaseline = 'bottom'
            ctx.scale(1, 0.9)
            ctx.fillText("ACP CENTENARY", 0, -15)
            ctx.font = 'bold 60px Arial'
            ctx.restore()
        }
        ctx.restore()
        resolve(canvas)
    })
}

export const makeMedalImage = async (year, brm, _name, _time, kinen = false) => {

    const name = _name ?? 'YOUR, Name'
    const time = _time ?? '--h--m'

    const engraveImage = await makeImage(year, brm, name, time, kinen)
    const canvas = document.createElement('canvas')
    canvas.width = 600  // 300DPI 2inch=約5cm
    canvas.height = 600
    const ctx = canvas.getContext('2d')

    // 刻印内容のくり抜き
    ctx.save()
    ctx.filter = "blur(1px)"
    ctx.drawImage(engraveImage, 17, 30)
    ctx.fillStyle = 'rgb(85, 85, 85)'
    ctx.globalCompositeOperation = 'source-in'
    ctx.fillRect(0, 0, 600, 600)
    ctx.restore()

    ctx.save()
    ctx.fillStyle = "rgb(226, 198, 146)"
    ctx.strokeStyle = "rgb(190, 112, 67)"
    ctx.lineWidth = 4
    ctx.globalCompositeOperation = 'destination-over'
    ctx.beginPath()
    ctx.arc(300, 300, 298, 0, 2 * Math.PI)
    ctx.stroke()
    ctx.beginPath()
    ctx.arc(300, 300, 300, 0, 2 * Math.PI)
    ctx.fill()

    ctx.restore()

    return canvas
}

