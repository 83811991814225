<!-- 
    DNF一覧表示
-->

<template>
    <el-card>
        <template v-slot:header
            ><h1>
                DNF一覧
                <el-button
                    class="ml-2"
                    type="info"
                    size="mini"
                    :icon="updateIcon"
                    circle
                    @click="update"
                ></el-button>
            </h1>
            DNFを取り消した参加者も表示されています
        </template>
        <el-table
            :data="list"
            style="width: 100%"
            :row-style="rowStyle"
            @sort-change="sort"
        >
            <el-table-column
                prop="start_no"
                label="No."
                width="80px"
                align="center"
                sortable="custom"
            >
                <template v-slot="{ row }">
                    <b>{{ row.start_no }}</b>
                </template>
            </el-table-column>
            <el-table-column prop="status" label="" width="80px" align="center">
                <template v-slot="{ row }">
                    <el-tag v-if="row.cancel === 1" type="danger">取消</el-tag>
                </template>
            </el-table-column>
            <el-table-column prop="rider_name" label="氏名"></el-table-column>
            <el-table-column
                prop="format"
                label="時刻"
                sortable="custom"
            ></el-table-column>
            <el-table-column prop="reason" label="理由1"></el-table-column>
            <el-table-column prop="reason2" label="理由2"></el-table-column>
            <el-table-column prop="reporter" label="報告者"></el-table-column>
        </el-table>
    </el-card>
</template>

<script>
import { mapState, mapGetters, mapActions } from "vuex"

export default {
    data() {
        return {
            dnfList: [],
            updateLoading: false,

            sortProp: "start_no",
            sortOrder: "ascending",
        }
    },

    computed: {
        ...mapState(["current"]),

        list() {
            const selected = this.dnfList.map((dnf) => {
                const ts = new Date(dnf.dnf_time)
                const ev_date = new Date(this.current.brmDate).getDate()

                const format_date =
                    ts.getDate() !== ev_date ? `${ts.getDate()}/ ` : ""
                const format_time = `${ts.getHours()}:${(
                    "00" + ts.getMinutes()
                ).slice(-2)}`

                return {
                    ...dnf,
                    format: format_date + format_time,
                }
            })

            return selected.sort((_a, _b) => {
                let a, b
                switch (this.sortProp) {
                    case "start_no":
                        a = _a.start_no
                        b = _b.start_no
                        break
                    case "format":
                        a = new Date(_a.dnf_time)
                        b = new Date(_b.dnf_time)
                        break
                }

                return this.sortOrder === "ascending" ? a - b : b - a
            })
        },

        updateIcon() {
            return this.updateLoading ? "el-icon-loading" : "el-icon-refresh"
        },
    },

    created() {
        this.getDnfList()
    },

    methods: {
        getDnfList: async function () {
            await axios("/api/dnflist/" + this.current.brmId).then(
                (res) => (this.dnfList = res.data)
            )
        },

        rowStyle({ row }) {
            if (row.cancel) {
                return { "background-color": "#f8f9fa", color: "#6c757d" }
            }
        },

        update() {
            this.updateLoading = true
            this.getDnfList()
                .then(() => {
                    this.updateLoading = false
                })
                .catch(() => {
                    this.updateLoading = false
                })
        },

        sort({ prop, order }) {
            this.sortProp = prop
            this.sortOrder = order
        },
    },
}
</script>

<style scoped>
</style>
