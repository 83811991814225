<!-- 
    現在のブルベのサマリー
-->

<template>
    <div class="brm-summary">
        <div class="row align-items-center justify-content-between">
            <div class="col">
                <slot name="title">
                    <p class="summary-title" v-if="showTitle">ブルベサマリー</p>
                </slot>
            </div>
            <div class="col-2 text-right mr-2 mb-1" v-if="showUpdateButton">
                <el-button
                    type="success"
                    size="mini"
                    :icon="updateIcon"
                    circle
                    @click="update"
                ></el-button>
            </div>
        </div>
        <el-table
            :data="summaryTableData"
            :show-header="showHeader"
            :row-class-name="rowClassName"
            :row-style="rowStyle"
            style="width: 100%"
        >
            <el-table-column prop="label" label="項目"></el-table-column>
            <el-table-column
                prop="value"
                label="人数"
                align="center"
            ></el-table-column>
        </el-table>
        <div class="text-right text-dark" v-if="showFooter">
            {{ updatedAt }}
        </div>
    </div>
</template>

<script>
import { mapState, mapGetters, mapActions } from "vuex"

export default {
    props: {
        showTitle: {
            type: Boolean,
            default: true,
        },
        showHeader: {
            type: Boolean,
            default: false,
        },
        showFooter: {
            type: Boolean,
            default: true,
        },
        showUpdateButton: {
            type: Boolean,
            default: true,
        },
        // 完走数のあとにメダル購入数を表示
        medal: {
            type: Boolean,
            default: false,
        },
    },

    data() {
        return {
            updateLoading: false,
        }
    },

    computed: {
        ...mapGetters(["summary", "isStaff"]),
        summaryTableData() {
            const data = this.summary
            return [
                {
                    item: "entry",
                    label: "エントリー",
                    value: data.total - data.canceled,
                },
                {
                    item: "dns",
                    label: "DNS",
                    value: data.dns,
                },
                {
                    item: "goaway",
                    label: "出走",
                    value: data.total - data.canceled - data.dns,
                },
                {
                    item: "dnf",
                    label: "DNF",
                    value: data.dnf,
                },
                {
                    item: "finish",
                    label: "完走",
                    value: `${data.finish} ${
                        this.showMedalCount
                            ? "（メダル " + data.medal + "）"
                            : ""
                    }`,
                },
                {
                    item: "ontheway",
                    label: "走行中",
                    value: data.registered,
                },
            ]
        },

        showMedalCount() {
            return this.medal || this.isStaff
        },

        // 表示用 更新時間
        updatedAt() {
            return this.summary.updatedAt.format
        },
        updateIcon() {
            return this.updateLoading ? "el-icon-loading" : "el-icon-refresh"
        },
    },

    methods: {
        ...mapActions(["getSummary"]),
        update() {
            this.updateLoading = true
            this.getSummary()
                .then(() => {
                    this.updateLoading = false
                })
                .catch(() => {
                    this.updateLoading = false
                })
        },

        rowClassName({ row, rowIndex }) {
            const item = row.item
            switch (item) {
                case "ontheway":
                case "entry":
                    return ["font-weight-bold"]
                    break
            }
        },
        rowStyle({ row, rowIndex }) {
            const item = row.item
            switch (item) {
                case "ontheway":
                    return { background: "#ecf5ff", color: "#409EFF" }
                    break
                case "finish":
                    return { background: "#caffee", color: "#006454" }
                    break
                case "dns":
                    return { background: "#f8f9fa", color: "#6574cd" }
                    break
                case "dnf":
                    return { background: "#f8f9fa", color: "#e3342f" }
                    break
                case "entry":
                    return { background: "#ecf5ff", color: "#343a40" }
                    break
            }
        },
    },
}
</script>

<style scoped>
.color {
    color: #caffee;
}
</style>
