<template>
    <el-card :style="gmapStyle" class="gmap-wrap">
        <div ref="gmap" style="width: 100%; height: 100%"></div>
        <transition name="info">
            <div class="info" v-if="info.visible">
                <h3>{{ info.title }}</h3>
                <p>{{ info.content }}</p>
            </div>
        </transition>
        <div class="rader-info" v-if="raderUpdate !== null">
            <div class="h5 mb-1">雨雲レーダー</div>
            <p class="mb-0" v-if="raderUpdate">{{ raderUpdate }} 更新</p>
        </div>
        <div class="legend">
            <Legend></Legend>
        </div>
    </el-card>
</template>

<script>
import { mapGetters, mapActions } from "vuex"

import Legend from "@/components/goalBot/Legend.vue"

import { markerIcon } from "@/components/goalBot/brmtool.gmapcueicon.js"

// 時間待ち
const wait = (ms) => new Promise((res) => setTimeout(res, ms))

// WEATHER
const WEATHER_MARKER_ROTATE = 20_000

const WEATHER_ICON = new Map([
    ["01d", ["7688-02", "shine"]], // clear sky
    ["02d", ["7688-03", "cloud"]], // few clouds
    ["03d", ["7688-04", "cloud"]], // scatterd clouds
    ["04d", ["7688-04", "cloud"]], // broken clouds
    ["09d", ["7688-01", "rain"]], // shower rain
    ["10d", ["7688-01", "rain"]], // rain
    ["11d", ["7688-16", "thunder"]], // thunderstorm
    ["13d", ["7688-07", "snow"]], // snow
    ["50d", ["7688-04", "cloud"]], // mist
    ["01n", ["7688-02", "shine"]], // clear sky
    ["02n", ["7688-03", "cloud"]], // few clouds
    ["03n", ["7688-04", "cloud"]], // scatterd clouds
    ["04n", ["7688-04", "cloud"]], // broken clouds
    ["09n", ["7688-01", "rain"]], // shower rain
    ["10n", ["7688-01", "rain"]], // rain
    ["11n", ["7688-16", "thunder"]], // thunderstorm
    ["13n", ["7688-07", "snow"]], // snow
    ["50n", ["7688-04", "cloud"]], // mist
])

const GUIDE = 10 // ガイド 雨晴はう（ノーマル）

export default {
    components: {
        Legend,
    },

    props: ["bodyStyle"],

    data() {
        return {
            activated: false,

            //
            mapShowTimer: null,

            // WEATHER
            weatherMarkerRotateCount: 1,
            currentWeatherType: "wind",

            iconRotateCounter: 1,

            mockClock: 0,

            updateEveryStartTimer: null,
            mapListener: null,
        }
    },

    computed: {
        ...mapGetters(["currentBrm"]),

        ...mapGetters("goalBot/gmapView", [
            "mapObj",
            "track",
            "brmReady",
            "latLngBounds",
            "everyStart",
            "raderUpdate",
            "info",
            "weatherReportUpdate",
        ]),

        gmapStyle() {
            return {
                "--gmap-height": this.bodyStyle.height,
            }
        },
    },

    // 最初の表示時にも activated は走る
    activated() {
        this.activated = true
    },

    deactivated() {
        this.activated = false
    },

    async mounted() {
        // Google Map の初期化： この後は mapObj が出来上がっている
        await this.$store.dispatch("goalBot/gmapView/initMap", this.$refs.gmap)
        this.mapListener = this.mapObj.addListener(
            "zoom_changed",
            _.debounce(this.dispatchWeatherMarkers, 1000)
        )

        // map に重ねる雨雲レーダーのタイル（リアルタイム）を返す関数を設定
        await this.$store.dispatch("goalBot/gmapView/initRader")
        await this.$store.dispatch("goalBot/gmapView/updateRader")

        this.setMockDate()

        console.log(await this.initBrm())
        await wait(10_000)  // wait を相当入れないとデータができない。なぜ？
        this.updateEveryStart()
        this.panAndZoom("route")

        this.weatherMarkerRotate()
        this.mapShowProgram()

        await this.$store.dispatch("goalBot/gmapView/initQueryPoints")
        await this.fetchWeather()
        this.$store.dispatch("goalBot/gmapView/getCityList")

    },

    beforeDestroy() {
        this.mapListener.remove()
        this.mapListener = null
        this.$store.dispatch("goalBot/gmapView/removeMapObj")
    },

    methods: {
        ...mapActions("goalBot/gmapView", ["setInfo"]),

        async initBrm() {
            try {

                await this.$store.dispatch("goalBot/gmapView/setBrm")

                await this.$store.dispatch("goalBot/gmapView/initTrack")
                await this.$store.dispatch("goalBot/gmapView/initPois")
                // メインの Polyline に加えて各スタートの Polyline を含むセットアップ
                await this.$store.dispatch("goalBot/gmapView/initPoly")

                return "GmapView: ブルベデータがセットアップされました."
            } catch (error) {
                throw new Error(
                    `GmapView: データのセットアップに失敗しました. ${error}`
                )
            }
        },

        updateEveryStart() {
            if (this.updateEveryStartTimer) {
                clearTimeout(this.updateEveryStartTimer)
                this.updateEveryStartTimer = null
            }

            if (!this.brmReady) {
                console.log(
                    "updateEveryStart: BRMデータが取り込まれていません."
                )
            } else {
                this.$store.dispatch("goalBot/gmapView/updateEveryStart", {
                    //ts: this.mockClock,
                })
            }
            this.updateEveryStartTimer = setTimeout(
                this.updateEveryStart,
                60_000
            )
        },

        async fetchWeather() {
            await this.$store.dispatch("goalBot/gmapView/fetchWeather")
            setTimeout(
                () => this.$store.dispatch("goalBot/gmapView/fetchWeather"),
                3 * 60_000
            )
            return
        },

        // WeatherMarker を一定時間でローテートする
        // markerType をローテート → watch して各マーカー描画を dispatch
        weatherMarkerRotate() {
            const types = ["wind", "temperature", "weather"]
            const count = this.weatherMarkerRotateCount++
            this.currentWeatherType = types[count % 3]
            this.dispatchWeatherMarkers()
            setTimeout(() => this.weatherMarkerRotate(), 20_000)
        },

        dispatchWeatherMarkers() {
            let action = "goalBot/gmapView/"
            switch (this.currentWeatherType) {
                case "wind":
                    action += "allocateWindMarkers"
                    break
                case "temperature":
                    action += "allocateTemperatureMarkers"
                    break
                case "weather":
                    action += "allocateWeatherMarkers"
                    break
            }
            this.$store.dispatch(action)
        },

        // 開発用の模擬 Date を設定
        setMockDate() {
            const mockFunc = this.$store.getters["goalBot/gmapView/mockFunc"]

            if (typeof mockFunc === "function") {
                this.mockClock = mockFunc()
            }

            setTimeout(this.setMockDate, 1000)
        },

        panAndZoom(target, option) {
            if (this.mapObj === null) return

            const finishPt = this.track[this.track.length - 1]
            const bb = new google.maps.LatLngBounds()

            return new Promise(async (resolve, reject) => {
                switch (target) {
                    case "route": // ルート全体を表示
                        await this.mapObj.setZoom(12) // 一度ズームが 0 になってしまうのを抑制するための措置
                        await this.mapObj.fitBounds(this.latLngBounds)
                        break
                    case "remain": // 残りルート
                        this.everyStart.forEach((group) =>
                            bb.union(group.latLngBounds)
                        )
                        bb.extend(finishPt)
                        await this.mapObj.fitBounds(bb)
                        break
                    case "riders": // 全走者
                        this.everyStart.forEach((group) =>
                            bb.union(group.latLngBounds)
                        )
                        await this.mapObj.fitBounds(bb)
                        break
                    case "start": // スタート毎（オプションでインデックス）
                        const startIndex = option.start
                        await this.mapObj.fitBounds(
                            bb.union(this.everyStart[startIndex].latLngBounds)
                        )
                        break
                    case "poi": // POI にズームイン
                        const zoom = option.zoom ?? 12

                        await this.mapObj.setZoom(zoom - 2) // 少し引く
                        await wait(1000)
                        await this.mapObj.panTo(option.position) // 移動
                        await this.mapObj.setZoom(zoom) // ズームイン
                        await wait(3000)

                        break
                    default:
                        reject("そのようなメニューはありません")
                        break
                }
                resolve(`panAndZoom end: ${target}`)
            })
        },

        // 表示プログラム

        async mapShowProgram() {

            if (this.mapShowTimer) {
                clearTimeout(this.mapShowTimer)
                this.mapShowTimer = null
            }

            await this.panAndZoom("route")
            await wait(30_000)

            // await this.panAndZoom("riders")
            // await wait(20_000)

            // await this.panAndZoom("remain")
            // await wait(20_000)

            this.mapShowTimer = setTimeout(this.mapShowProgram, 1_000)
            return
        },
    },
}
</script>

<style scoped>
::v-deep .el-card__body {
    padding: 0px;
    height: var(--gmap-height);
}

.gmap-wrap {
    position: relative;
}

.info {
    position: absolute;
    background: #00000060;
    margin: 10px;
    padding: 20px;
    border-radius: 20px;
    color: white;
    font-size: 18px;
    top: 0;
    left: 0;
}

.visible {
    transition: height 2s;
    height: 200px;
}

.rader-info {
    position: absolute;
    bottom: 0;
    right: 0;
    margin: 10px;
    padding: 10px;
    border-radius: 10px;
    color: white;
    background: #00000060;
}

.legend {
    position: absolute;
    bottom: 10px;
    left: 0;
    background: #ffffffaa;
    margin: 10px;
    padding: 5px 10px;
    border-radius: 10px;
}

.debug-button {
    position: absolute;
    top: 0px;
    right: 50%;
    background: #00000060;
    margin: 10px;
}
</style>