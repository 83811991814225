<!-- 
    スタート時間毎のゴールでのカウントダウン表示
    <count-down :start="'6:00'" />
-->

<template>
    <div>
        <div
            v-for="start in summary"
            v-if="start.entries.length"
            :key="start.id + '-' + refresh"
        >
            <div class="row justify-content-between mb-2">
                <div class="col ml-1">
                    <el-tag :type="start.type">{{ start.tag }}</el-tag>
                </div>
                <div class="col text-right">
                    <h6 class="pr-1">
                        <span class="badge badge-dark py-1 mr-2">残り</span
                        ><span
                            ><b>{{ remain(start.goal_time) }}</b></span
                        >
                    </h6>
                </div>
            </div>
            <table class="table" :style="baseStyle">
                <tbody>
                    <tr>
                        <th class="py-1" scope="row">DNF</th>
                        <td class="text-right py-1">{{ start.dnf }}</td>
                    </tr>
                    <tr class="finish">
                        <th class="py-1" scope="row">Finish</th>
                        <td class="text-right py-1">{{ start.finish }}</td>
                    </tr>
                    <tr class="registered">
                        <th class="py-1" scope="row">走行中...</th>
                        <td class="text-right py-1">
                            <b>{{ start.registered }}</b>
                        </td>
                    </tr>
                </tbody>
            </table>
        </div>
        <div v-else>Warning: エントリーがありません</div>
    </div>
</template>

<script>
import { mapState, mapGetters, mapActions } from "vuex"

export default {
    data() {
        return {
            refresh: 1,
            timer: null,
        }
    },

    computed: {
        ...mapGetters(["detailedSummary"]),
        ...mapState(["current"]),

        brmDay() {
            return this.current.lastUpdate.getTime()
        },

        summary() {
            return this.detailedSummary.starts.map((start) => {
                const goal_time =
                    new Date(start.start_time).getTime() +
                    this.current.brmTimeLimit * 3600 * 1000
                return {
                    ...start,
                    goal_time,
                }
            })
        },

        baseStyle() {
            return {
                "--finish-bg-color": "rgb(202, 255, 238)",
                "--finish-color": "rgb(0, 100, 84)",
                "--registered-bg-color": "rgb(236, 245, 255)",
                "--registered-color": "rgb(64, 158, 255)",
            }
        },
    },

    created() {
        this.timer = window.setInterval(() => {
            ++this.refresh
        }, 1000)
    },

    methods: {
        ...mapActions([]),

        remain(ts) {
            let seconds = Math.floor((ts - Date.now()) / 1000)
            const sign = seconds >= 0
            seconds = Math.abs(seconds)
            const hr = Math.floor(seconds / 3600)
            const min = ("00" + (Math.floor(seconds / 60) % 60)).slice(-2)
            const sec = ("00" + (seconds % 60)).slice(-2)
            return (sign ? "" : "-") + `${hr}:${min}:${sec}`
        },
    },

    beforeDestroy() {
        window.clearInterval(this.timer)
    },
}
</script>

<style scoped>
.registered {
    background-color: var(--registered-bg-color);
    color: var(--registered-color);
}
.finish {
    background-color: var(--finish-bg-color);
    color: var(--finish-color);
}
</style>
