<template>
    <div>
        <div><b>走行位置予想</b></div>
        <div
            class="text-info"
            style="font-size: 12px; padding-right: 10px; text-align: right"
        >
            15 ～ 25km/h
        </div>
        <div class="each" v-for="each in everyStarts" :key="each.index">
            <span><img :src="each.icon" /></span>
            <div class="d-inline-block text-right" style="width: 40px">
                <b>{{ each.text }}</b>
            </div>
        </div>
    </div>
</template>

<script>
const routePath = new Path2D(
    "M3.215,18.438C8.006,11.966,22.47-1.757,27.941,6.647c1.925,2.958-4.118,14.655-1.344,18.685c5.393,7.838,25.284-5.162,34.58-12.699"
)

export default {

    data() {
        return {}
    },

    computed: {

        starts(){
            return this.$store.getters['goalBot/gmapView/everyStart']
        },

        everyStarts() {

            return this.starts.map((start, index) => {
                const icon = this.makeIcon(start)
                const startTime = `${start.start.getHours()}:${(
                    "00" + start.start.getMinutes()
                ).slice(-2)}`
                return { icon, text: startTime, index }
            })
        },
        icons() {
            return this.starts.map((start) => this.makeIcon(start))
        },
    },

    methods: {
        makeIcon(startObj) {
            const color = startObj.color
            const canvas = document.createElement("canvas")
            const ctx = canvas.getContext("2d")

            canvas.width = 64
            canvas.height = 32

            ctx.clearRect(0, 0, 64, 32)
            ctx.save()
            ctx.lineWidth = 8
            ctx.strokeStyle = color
            ctx.stroke(routePath)
            ctx.restore()
            ctx.save()
            ctx.strokeStyle = "red"
            ctx.lineWidth = 1
            ctx.stroke(routePath)
            ctx.restore()

            return canvas.toDataURL()
        },
    },
}
</script>

<style scoped>
.each {
    padding: 5px 10px 5px 0px;
}
</style>