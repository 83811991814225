export default {

    namespaced: true,

    state: () => ({

        queue: [],
        stopSpeaking: false,
        lastSpoke: 0,

    }),

    getters: {

        checkUniq: (state) => (speakText, speaker) => {
            const uniq =  state.queue.findIndex(q => q.speakText === speakText && q.speaker === speaker)
            return uniq === -1 ? true : false
        },

        selectQueue: (state) => (id) => {
            return state.queue.find(q => q.id === id)
        },

        nextQueue: (state) => () => {
            const queue = state.queue.find(q => q.ts < Date.now())
            return queue ?? null
        },

        nextQueueId: (state) => () => {
            const lastId = state.queue.reduce((num, q) => {
                return q.id > num ? q.id : num
            }, 0)
            return lastId + 1
        },

        lastSpoke(state) {
            return state.lastSpoke
        },

        stopSpeaking(state) {
            return state.stopSpeaking
        }


    },

    mutations: {

        spoken(state) {
            state.lastSpoke = Date.now()
        },

        setStopSpeaking(state, bool) {
            state.stopSpeaking = bool
        },

        removeQueue(state, id) {
            const index = state.queue.findIndex(q => q.id === id)
            if (index !== -1) {
                state.queue.splice(index, 1)
            }
        },

        rebuildQueue(state, { primary, secondary, idle }) {

            state.queue = Object.assign([], [...primary, ...secondary, ...idle])
        }
    },


    actions: {

        spoken({ commit }) {
            commit('spoken')
        },

        addQueue({ state, commit, getters }, speakObj) {

            if (speakObj.once && !getters.checkUniq(speakObj.speakText, speakObj.speaker)) {
                return
            }

            const id = getters.nextQueueId()
            speakObj.id = id

            const primary = state.queue.filter(q => q.priority === 'primary')
                .sort((a, b) => a.ts - b.ts)
            const secondary = state.queue.filter(q => q.priority === 'secondary')
                .sort((a, b) => a.ts - b.ts)
            const idle = state.queue.filter(q => q.priority === 'idle')
                .sort((a, b) => a.ts - b.ts)



            switch (speakObj.priority) {
                case 'immediate':
                    primary.unshift(speakObj)
                    break
                case 'acute':
                    primary.splice(1, 0, speakObj)
                    break
                case 'primary':
                    primary.push(speakObj)
                    break
                case 'secondary':
                    secondary.push(speakObj)
                    secondary.sort((a, b) => a.ts - b.ts)
                    break
                case 'idle':
                    idle.push(speakObj)
                    idle.sort((a, b) => a.ts - b.ts)
                    break
            }

            commit('rebuildQueue', { primary, secondary, idle })

        },

        removeQueue({ commit }, id) {
            commit('removeQueue', id)
        },

        gcQueue({ state, commit }) {
            const obsolute = state.queue.filter(q => {
                return q.expire < Date.now()
            })
            obsolute.forEach(q => {
                commit('removeQueue', q.id)
            })
        },

        stopSpeaking({ commit, getters, dispatch }, id) {
            commit('setStopSpeaking', true)
            const currentQueue = getters.selectQueue(id)
            if (currentQueue && currentQueue.cancellable) {
                dispatch('removeQueue', id)
            }
        },

        resumeSpeaking({ commit }) {

            commit('setStopSpeaking', false)
        }

    }

}