<!-- 
    DNF キャンセル
    参加者自身による DNF キャンセル
-->

<template>
        <div>
        <el-card>
            <template v-slot:header>DNF取り消し処理</template>
            <p>よくぞ踏みとどまってくれました. 手続きが完了すれば、本部への電話連絡は不要です.</p>
            <el-button v-if='user.role == 3 && !formVisible' type='primary' @click='proceed()'>手続きにすすむ</el-button>
            <el-form v-if='isStaff || formVisible' v-model='dnfForm' label-width='100px'>
                <el-form-item label='スタート番号'  class='my-0'>
                    <el-input :readonly='user.role === 3' v-model='dnfForm.startNo'></el-input>
                </el-form-item>
                <el-form-item label='ひとこと' class='my-0'>
                    <el-input v-model='dnfForm.reason2'></el-input>
                </el-form-item>
                <el-form-item label='報告時刻' class='my-0'>
                    <el-time-picker v-model='dnfForm.dnfAt' style='width:100%'></el-time-picker>
                </el-form-item>
                <el-form-item>
                    <el-button @click='submit' type='primary'>送信</el-button>
                    <el-button @click='cancel'>キャンセル</el-button>
                </el-form-item>
            </el-form>
        </el-card> 
    </div>
</template>

<script>

import { mapState, mapGetters, mapActions } from 'vuex'

export default {

        data(){
            return {
                formVisible: false,
                dnfForm: {
                    startNo: null,
                    userId: null,
                    name: '',
                    reason: '',
                    reason2: '',
                    dnfAt: new Date()
                }
            }
        },
        
        computed: {
            ...mapState(['user', 'current']),
            ...mapGetters(['isStaff']),
        },
        
        created(){
            if( this.user.role === 3){
                this.dnfForm.startNo = this.user.info.entry.start_no
                this.dnfForm.userId = this.user.id
            }
        },
        
        mounted(){
            if( this.user.role !== 3) return false
            if( this.current.brmId !== this.user.riderBrmId ){
                this.$message({
                    message: 'あなたは今開催中のブルベに参加していません',
                    offset: 50,
                    type: 'warning',
                    onClose: ()=>window.location.href='/'
                })
            }
        },
        
        methods:{
            ...mapActions(['getUserInfo', 'getSummary']),
            
            proceed(){
                this.formVisible = true
            },

            submit(){
                this.$confirm('DNFを取り消します. 覚悟はいいですか?', '確認',{
                    confirmButtonText: 'はい',
                    cancelButtonText: 'キャンセル', 
                    type: 'success',
                    center: true
                }).then(()=>this.commit()).catch( ()=>this.cancel() )
            },
            cancel(){
                if( this.user.role === 3 ){
                    this.$message({ message:'思いとどまってくれてありがとう! 頑張って走ってくださいね。',onClose:()=>{ location.href='/' }})
                } else {
                    this.$message('手続きをキャンセルしました.')
                    // this.$router.push({ name: 'home' })
                }
            },
            commit(){
                const dnfInfo = {
                    command: 'cancel',
                    user_id: this.dnfForm.userId,
                    brm_id: this.current.brmId,
                    reporter_id: this.user.id,
                    reason: '参加者によるDNF取り消し',
                    reason2: this.dnfForm.reason2 ?? '',
                    dnf_time: this.dnfForm.dnfAt.toLocaleString()
                }

                axios.post('/api/dnf', dnfInfo)
                    .then( res => {
                        if( this.user.role === 3 ){
                            if(res.data.status === 'ok'){
                                this.$message({
                                    message: 'DNFを取り消しました. 2度とこのような過ちを犯さないよう頑張ってフィニッシュしてください.',
                                    onClose: ()=>{
                                        window.location.href = '/'
                                    }
                                })
                            } else {
                                this.$message({
                                    message: 'システムのエラーで受け付けられませんでした(本当です...)',
                                    onClose: ()=>{
                                        this.$message({
                                            message: '何度も失敗するようなら電話連絡をお願いします.'
                                        })
                                    }
                                })
                            }
                        }
                        this.getUserInfo()
                        this.getSummary()
                    } )
                    .catch(error=>{})
            }
        }  
}    
    
</script>