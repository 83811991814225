<!-- 
    Goal Bot で表示する Leader Board
-->

<template>
    <el-card :body-style="bodyStyle">
        <h5>F I N I S H E R S　（新着順）</h5>
        <table class="table">
            <thead>
                <tr>
                    <th class="align-middle text-center">No.</th>
                    <th class="align-middle text-center">リザルト</th>
                    <th class="align-middle text-left">氏名</th>
                    <th class="align-middle text-center">スタート</th>
                    <th class="align-middle text-center">ゴールタイム</th>
                    <th class="align-middle text-center">認定タイム</th>
                </tr>
            </thead>
            <transition-group tag="tbody" name="entry" appear>
                <tr v-for="entry in entryList" :key="entry['start_no']">
                    <th class="align-middle text-center h4">
                        {{ entry["start_no"] }}
                    </th>
                    <td class="align-middle text-center">
                        <el-button
                            size="mini"
                            :type="entry['status_tag_type']"
                            >{{ entry["status_tag"] }}</el-button
                        >
                    </td>
                    <td class="align-middle h4">
                        {{ entry["name"].replace(/[　 ]+/, " ") }}
                    </td>
                    <td class="align-middle text-center">
                        <el-tag :type="entry['start_time_tag_type']">{{
                            entry["start_time_tag"]
                        }}</el-tag>
                    </td>
                    <td class="align-middle text-center h4">
                        {{ entry["finishTime"] }}
                    </td>
                    <td class="align-middle text-center h4">
                        {{ entry["brevetTime"] }}
                    </td>
                </tr>
            </transition-group>
        </table>
    </el-card>
</template>

<script>
import { mapState, mapGetters } from "vuex"

const status_tags = {
    canceled: "キャンセル",
    dnf: "DNF",
    dns: "DNS",
    registered: "走行中",
    overtime: "OT",
    finish: "FIN",
}

const status_tag_types = {
    canceled: "",
    dnf: "danger",
    dns: "warning",
    registered: "info",
    overtime: "",
    finish: "success",
}

export default {
    props: ["bodyStyle"],
    data() {
        return {
            sortProp: "finish_at",
            sortOrder: "descending",
            checkedStatus: ["finish", "overtime"],

            entryList: [],
        }
    },

    created() {
        this.getEntryList()
    },

    methods: {
        getEntryList() {
            const current = this.$store.state.current
            const list = current.entries.list
                .map((entry) => {
                    const start = current.starts.find((start) => {
                        return start.id == entry.start_id
                    })
                    const finishTime = this.$datetime.formatDDhhmm(
                        entry.finish_at,
                        entry.start_time
                    )
                    const brevetTimeMinutes = this.$datetime.diffMinutes(
                        entry.result_at,
                        entry.start_time
                    )

                    return {
                        ...entry,
                        status_tag: status_tags[entry.status],
                        status_tag_type: status_tag_types[entry.status],
                        start_time_tag: start.tag ? start.tag : "",
                        start_time_tag_type: start.type ? start.type : "",
                        finishTime:
                            entry.status === "finish" ||
                            entry.status === "overtime"
                                ? finishTime
                                : "",
                        brevetTimeMinutes,
                        brevetTime:
                            entry.status === "finish"
                                ? this.$datetime.formatHHMM(brevetTimeMinutes)
                                : "",
                    }
                })

                .filter((entry) => this.checkedStatus.includes(entry.status))

                .sort((_a, _b) => {
                    // 同フィニッシュタイムになったときに順番が前後するので updated_at で先にソートしておく
                    const a = new Date(_a.updated_at)
                    const b = new Date(_b.updated_at)

                    return this.sortOrder === "ascending" ? a - b : b - a
                })

                .sort((_a, _b) => {
                    let a, b
                    switch (this.sortProp) {
                        case "start_no":
                            a = _a.start_no
                            b = _b.start_no
                            break
                        case "finish_at":
                            a = new Date(_a.finish_at)
                            b = new Date(_b.finish_at)
                            break
                        case "brevetTime":
                            a = _a.brevetTimeMinutes
                            b = _b.brevetTimeMinutes
                            break
                    }

                    return this.sortOrder === "ascending" ? a - b : b - a
                })
            this.entryList = list
        },
    },
}
</script>

<style scoped>
.entry-enter-to,
.entry-leave {
    opacity: 1;
    height: 100px;
}

.entry-enter-active,
.entry-leave-active {
    transition: opacity 0.5s;
    animation: table-row 10s ease;
}
.entry-enter, .entry-leave-to /* .fade-leave-active below version 2.1.8 */ {
    opacity: 0;
}

@keyframes table-row {
    0% {
        /*height: 175px;*/
        background: pink;
        font-size: 150%;
    }
    95% {
        /*height: 175px;*/
        background: pink;
        font-size: 150%;
    }
    100% {
        height: 100px;
    }
}

@keyframes blink {
    0% {
        opacity: 0;
    }
    75% {
        opacity: 0.9;
    }
    90% {
        opacity: 1;
    }
    100% {
        opacity: 1;
    }
}
</style>
