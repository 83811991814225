<!-- 
    ゴール処理用・リーダーボード
    ゴールで各人が開いておく
-->

<template>
    <div>
        <h5 class="alert alert-dark">{{ brmTitle }}</h5>
        <div class="row mt-5">
            <div class="d-none d-lg-block col-lg-6 side">
                <el-table :data="summary" :border="true" size="mini" show-summary>
                    <el-table-column label="" align="center">
                        <template v-slot="{ row }">
                            <el-tag :type="row.type">
                                {{ row.start_time }}
                            </el-tag>
                        </template>
                    </el-table-column>
                    <el-table-column prop="all" align="center">
                        <template v-slot:header>
                            <el-tooltip content="「総数」は申込みからキャンセル分を除外">
                                <div>
                                    総数<br /><span style="font-size: 75%">(キャンセル)</span>
                                </div>
                            </el-tooltip>
                        </template>
                        <template v-slot="{ row }">
                            {{ row.all }}
                            <template v-if="row.canceled > 0"><br />({{ row.canceled }})</template>
                        </template>
                    </el-table-column>
                    <el-table-column label="DNS" prop="dns" align="center"></el-table-column>
                    <el-table-column label="出走" prop="rode" align="center"></el-table-column>
                    <el-table-column prop="finish" align="center">
                        <template slot="header">
                            <div>完走<br />(メダル)</div>
                        </template>
                        <template v-slot="{ row }">
                            {{ row.finish }}<br />({{ row.medal }})
                        </template>
                    </el-table-column>
                    <el-table-column label="DNF" prop="dnf" align="center"></el-table-column>
                    <el-table-column prop="registered" align="center">
                        <template v-slot:header>
                            <el-tooltip content="「走行中」になっている参加者数です"><span>その他</span></el-tooltip>
                        </template>
                    </el-table-column>
                    <el-table-column v-if="brmData.brm.multipurpose_check === 1" prop="multipurpose_check"
                        :label="brmData.brm.multipurpose_check_name" align="center">
                    </el-table-column>
                </el-table>
            </div>
            <div class="col-12 col-lg-6 leaderboard">
                <div slot="header">
                    <div class="row">
                        <div class="col-2"></div>
                        <el-checkbox :indeterminate="isIndeterminate" v-model="checkAll"
                            @change="handleCheckAllChange">すべて</el-checkbox>
                    </div>
                    <div class="col-10">
                        <el-checkbox-group v-model="checkedStatus" @change="handleCheckedStatusChange">
                            <el-checkbox v-for="s in statusList" :label="s.status" :key="s.status">{{ s.name
                            }}</el-checkbox>
                        </el-checkbox-group>
                    </div>
                </div>
            </div>

            <el-table :data="list()" :border="true" size="mini" style="width: 100%" @sort-change="sort">
                <el-table-column label="No" width="60" prop="start_no" align="center" fixed
                    sortable="custum"></el-table-column>

                <el-table-column label="リザルト" width="100" align="center">
                    <template v-slot="{ row }">
                        <el-tag size="small" effect="dark" :type="row.status_tag_type">{{ row.status_tag }}</el-tag>
                    </template>
                </el-table-column>
                <el-table-column label="氏名" prop="name">
                    <template v-slot="{ row }">
                        {{ row.user.name.replace(/[　 ]+/, " ")
                        }}<i :class="row.approvedIcon"></i>
                    </template>
                </el-table-column>
                <el-table-column width="100" align="center" :filters="startTimeList" :filter-method="filterStartTime">
                    <template v-slot:header>
                        <el-tooltip content="絞り込みできます"><span>スタート</span></el-tooltip>
                    </template>
                    <template v-slot="{ row }">
                        <el-tag :type="row.start_time_tag_type" size="small">{{
                            row.start_time_tag
                        }}</el-tag>
                    </template>
                </el-table-column>
                <el-table-column label="ゴール" prop="finish_at" align="center" sortable="custom">
                    <template v-slot="{ row }">
                        {{ row.finishTime }}
                    </template>
                </el-table-column>
                <el-table-column label="認定" prop="brevetTime" align="center" sortable="custum">
                    <template v-slot="{ row }">
                        {{ row.brevetTime }}
                    </template>
                </el-table-column>
                <el-table-column label="メダル" align="center">
                    <template v-slot="{ row }">
                        <i style="color: gold; font-size: 18px" v-if="row.medal" class="el-icon-medal"></i>
                    </template>
                </el-table-column>
                <el-table-column v-if="brmData.brm.multipurpose_check === 1" :label="brmData.brm.multipurpose_check_name"
                    align="center">
                    <template v-slot="{ row }">
                        <i style="color: gold; font-size: 18px" v-if="row.multipurpose_check"
                            class="el-icon-star-on"></i>
                    </template>
                </el-table-column>
            </el-table>

            <el-dialog :visible.sync="detailVisible" width="90%">
                <EntryStatusEdit :entry_id="detailEntryId" @terminate="onTerminate"></EntryStatusEdit>
            </el-dialog>
        </div>
    </div>
    </div>
</template>

<script>

import EntryStatusEdit from "@/components/EntryStatusEdit"

const status_tags = {
    canceled: "キャンセル",
    dnf: "DNF",
    dns: "DNS",
    registered: "走行中",
    overtime: "OT",
    finish: "FIN",
}
const status_tag_types = {
    canceled: "",
    dnf: "danger",
    dns: "warning",
    registered: "info",
    overtime: "",
    finish: "success",
}

const statusList = [
    { status: "registered", name: "走行中" },
    { status: "overtime", name: "OT" },
    { status: "finish", name: "完走" },
    { status: "dns", name: "DNS" },
    { status: "dnf", name: "DNF" },
    { status: "canceled", name: "除外" },
]

export default {
    props: ["id"],

    components: {
        EntryStatusEdit,
    },

    data() {
        return {
            detailVisible: false,
            detailEntryId: null,
            checkAll: false,
            statusList,
            checkedStatus: ["finish", "overtime"],

            isIndeterminate: true,

            sortProp: "finish_at",
            sortOrder: "descending",

            brmData: {
                brm: {
                    event_date: null,
                    distance: null
                },
                summary: null
            },

            updateLoading: false,
        }
    },

    created() {
        this.getResult()
    },

    computed: {

        brmTitle() {

            const dt = new Date(this.brmData.brm.event_date)
            const yr = dt.getFullYear()
            const mo = dt.getMonth() + 1
            const dy = ('00' + dt.getDate()).slice(-2)
            const distance = this.brmData.brm.distance

            return `BRM${mo}${dy}-${distance}km (${yr}) リザルト`
        },

        summary() {
            if (!this.brmData.summary) {
                return null
            }
            return this.brmData.summary.map((each, index) => ({
                ...each,
                all: each.entries - each.canceled,
                rode: each.entries - each.canceled - each.dns,
                type: ["", "success", "danger", "warning", "info"][index % 5],
            }))
        },

        // スタート時間でフィルタリング用
        startTimeList() {
            return this.brmData.summary.map((entry) => {
                return {
                    text: entry.start_time,
                    value: entry.start_time,
                }
            })
        },

        startTagType() {
            const tagType = new Map()
            this.summary.forEach((entry) => {
                tagType.set(entry.start_time, entry.type)
            })
            return tagType
        },

        entryList() {
            if (!this.brmData || !this.brmData.entries) {
                return
            }
            return this.brmData.entries.map((entry) => {
                const startDay = new Date(entry.start.start_at).getDate()
                const finishDay = new Date(entry.finish_at).getDate()
                const finishTime =
                    (startDay !== finishDay ? `${finishDay}/ ` : "") +
                    new Date(entry.finish_at).getHours() +
                    ":" +
                    ("00" + new Date(entry.finish_at).getMinutes()).slice(-2)
                const brevetTime =
                    new Date(entry.result_at) - new Date(entry.start.start_at)
                const brevetTimeMinutes = Math.floor(brevetTime / (60 * 1000))
                const brevetTimeHr = Math.floor(brevetTimeMinutes / 60)
                const brevetTimeMin = brevetTimeMinutes % 60

                return {
                    ...entry,
                    status_tag: status_tags[entry.status],
                    status_tag_type: status_tag_types[entry.status],
                    start_time_tag: entry.start_time,
                    start_time_tag_type: this.startTagType.get(
                        entry.start_time
                    ),
                    finishTime:
                        entry.status === "finish" || entry.status === "overtime"
                            ? finishTime
                            : "",
                    brevetTimeMinutes,
                    brevetTime:
                        entry.status === "finish"
                            ? brevetTimeHr +
                            ":" +
                            ("00" + brevetTimeMin).slice(-2)
                            : "",
                    approvedIcon: entry.approved
                        ? "approved el-icon-circle-check"
                        : "not-approved el-icon-question",
                }
            })
        },

        updateIcon() {
            return this.updateLoading ? "el-icon-loading" : "el-icon-refresh"
        },
    },

    methods: {
        getResult() {
            axios.get("/api/brmResult/" + this.id).then((response) => {
                this.brmData = response.data
            })
        },

        list() {
            if (!this.entryList) {
                return
            }
            // select
            const selected = this.entryList.filter((entry) =>
                this.checkedStatus.includes(entry.status)
            )
            const ordered = selected.sort((_a, _b) => {
                let a, b
                switch (this.sortProp) {
                    case "start_no":
                        a = _a.start_no
                        b = _b.start_no
                        break
                    case "finish_at":
                        a = new Date(_a.finish_at)
                        b = new Date(_b.finish_at)
                        break
                    case "brevetTime":
                        a = _a.brevetTimeMinutes
                        b = _b.brevetTimeMinutes
                        break
                }

                return this.sortOrder === "ascending" ? a - b : b - a
            })
            return ordered
        },

        btnClick(row) {
            this.detailEntryId = row.id
            this.detailVisible = true
        },
        onTerminate() {
            this.detailVisible = false
        },
        handleCheckAllChange(val) {
            this.checkedStatus = val ? statusList.map((s) => s.status) : []
            this.isIndeterminate = false
        },
        handleCheckedStatusChange(val) {
            let checkedCount = val.length
            this.checkAll = checkedCount === this.statusList.length
            this.isIndeterminate =
                checkedCount > 0 && checkedCount < this.statusList.length
        },
        sort({ prop, order }) {
            this.sortProp = prop
            this.sortOrder = order
        },

        update() {
            this.updateLoading = true
            this.getEntryList()
                .then(() => {
                    this.updateLoading = false
                })
                .catch(() => {
                    this.updateLoading = false
                })
        },

        filterStartTime(value, row) {
            return row.start_time_tag === value
        },

        // event handler: リーダーボードのセル
        cellMouseEnter(row, column, cell, event) {
            //                    console.log(row, column, cell, event)
        },
    },
}
</script>

<style scoped>
.approved {
    padding-left: 6px;
    color: gold;
}

.not-approved {
    padding-left: 6px;
    color: red;
}
</style>
