const speakers = [
    { id: 2, name: '四国めたん', mood: 'ノーマル', sex: 'female', picture: 'bustup-metan.webp' }, // 時刻のアナウンス
    { id: 0, name: '四国めたん', mood: 'あまあま', sex: 'female', picture: 'bustup-metan.webp' },
    { id: 6, name: '四国めたん', mood: 'ツンツン', sex: 'female', picture: 'bustup-metan.webp' },
    { id: 4, name: '四国めたん', mood: 'セクシー', sex: 'female', picture: 'bustup-metan.webp' },
    { id: 3, name: 'ずんだもん', mood: 'ノーマル', sex: 'female', picture: 'bustup-zundamon.webp' }, // 指示
    { id: 1, name: 'ずんだもん', mood: 'あまあま', sex: 'female', picture: 'bustup-zundamon.webp' },
    { id: 7, name: 'ずんだもん', mood: 'ツンツン', sex: 'female', picture: 'bustup-zundamon.webp' },
    { id: 5, name: 'ずんだもん', mood: 'セクシー', sex: 'female', picture: 'bustup-zundamon.webp' },
    { id: 8, name: '春日部つむぎ', mood: 'ノーマル', sex: 'female', picture: 'bustup-tsumugi.webp' },
    { id: 10, name: '雨晴はう', mood: 'ノーマル', sex: 'female', picture: 'bustup-hau.webp' },
    { id: 9, name: '波音リツ', mood: 'ノーマル', sex: 'female', picture: 'bustup-ritsu.webp' },
    { id: 11, name: '玄野武宏', mood: 'ノーマル', sex: 'male', picture: 'bustup-takehiro.webp' },
    { id: 12, name: '白上虎太郎', mood: 'ノーマル', sex: 'male', picture: 'bustup-kotarou.webp' },
    { id: 13, name: '青山龍星', mood: 'ノーマル', sex: 'male', picture: 'bustup-ryusei.webp' },
    { id: 14, name: '冥鳴ひまり', mood: 'ノーマル', sex: 'female', picture: 'bustup-himari.webp' },
    { id: 16, name: '九州そら', mood: 'ノーマル', sex: 'female', picture: 'bustup-sora.webp' },
    { id: 15, name: '九州そら', mood: 'あまあま', sex: 'female', picture: 'bustup-sora.webp' },
    { id: 18, name: '九州そら', mood: 'ツンツン', sex: 'female', picture: 'bustup-sora.webp' },
    { id: 17, name: '九州そら', mood: 'セクシー', sex: 'female', picture: 'bustup-sora.webp' },
    { id: 19, name: '九州そら', mood: 'ささやき', sex: 'female', picture: 'bustup-sora.webp' },
]

const DEFAULT = 3
const FEMALE_NORMAL = [2, 3, 8, 10, 14, 16]
const FEMALE_SEXY = [4, 5, 17]
const FEMALE_SWEET = [0, 1, 15]
const ZUNDA = 3 // ずんだもん・ノーマル
const METAN = 2 // 四国めたん・ノーマル
const SORA = 19 // 九州そら・ささやき
const RYUSEI = 13 // 青山龍星・ノーマル
const MALE = [11, 12, 13]

export const idleSpeaks = [
    { text: 'みんな、|なかなか|戻って来ないわね。', speaker: FEMALE_NORMAL },
    { text: '今日の|コースって|そんなに厳しかったかしら。', speaker: FEMALE_NORMAL },
    { text: 'そろそろ、|みんな、|帰ってくる頃だと|思うんですけど。', speaker: FEMALE_NORMAL },
    { text: '暇で暇でしょうがないですね。', speaker: FEMALE_NORMAL },
    { text: 'みなさん、|頑張ってらっしゃるんでしょうね。', speaker: FEMALE_NORMAL },
    { text: 'なかなか|戻って来ないですね。|眠くなってきませんか？', speaker: FEMALE_NORMAL },
]

export const fixedPhrases = [
    { text: "エーアール中部ブルベへようこそ。|ゴール番のボットちゃんです。|よろしくお願いします。", speaker: FEMALE_NORMAL },
    { text: "メモリーぶそくのために|ときどきアプリを再起動します。", speaker: FEMALE_NORMAL },
    { text: 'フィニッシュタイムは|認定タイムは|です|でオーバータイムです', speaker: [RYUSEI, METAN] },
    { text: 'おかえりなさいませ|タイムをカードに記入して|受付までどうぞ|とりあえず', speaker: [RYUSEI, ZUNDA] },
    { text: '世界に|ひとつ、|あなただけの|やきメダルは|いかがですか?', speaker: [RYUSEI, ZUNDA] },
    { text: 'なんかエラーですって。|でも、気にしないでね。', speaker: SORA },
    { text: 'ただいま|時刻は|30分|ちょうど|です。|途中経過をお知らせします。',speaker: [METAN,RYUSEI]},
    { text: 'エントリー|DNS|出走|完走|DNF|で、現在|が走行中です。',speaker: [METAN,RYUSEI]},
]

