import axios from 'axios'
import jsonpAdapter from 'axios-jsonp'

import { OPEN_WEATHER_API_KEY } from "@/config.js"

const wait = (ms) => new Promise(resolve => { setTimeout(resolve, ms) })

const apiCall = (lat, lon) => {

    const url = 'https://api.openweathermap.org/data/2.5/weather'
    return new Promise(async (resolve, reject) => {

        const ts = Date.now()

        const response = await axios({
            method: 'GET',
            url,
            adapter: jsonpAdapter,
            params: {
                lat,
                lon,
                units: 'metric',
                appid: OPEN_WEATHER_API_KEY,
            }
        }).catch(async (error) => {
            await wait(1000)
            throw new Error(error)
        })

        // Openweather の問い合わせ制限が 60クエリー/分 なので 1秒以内に返ってきたときは wait を入れる
        const ms = 1000 - (Date.now() - ts)
        if (ms > 0) {
            await wait(ms)
        }

        resolve(response.data)

    })



}

export default {

    apiCall

}