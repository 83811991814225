/**
 * First we will load all of this project's JavaScript dependencies which
 * includes Vue and other libraries. It is a great starting point when
 * building robust, powerful web applications using Vue and Laravel.
 */

require('./bootstrap')


import NotMe from '@/components/NotMe.vue'


import router from './vue-router'
import store from './vue-store'

import { mapState, mapGetters, mapActions } from 'vuex'
import MyDateTimePlugin from './MyDateTimePlugin'

Vue.use(MyDateTimePlugin)

const app = new Vue({
    el: '#app',
    router,
    store,
    components: {
        NotMe,
    },

    data() {
        return {
            //
        }
    },

    created() {

        this.getUserInfo()

        // 現在開催中のブルベを取得して store に
        this.updateCurrent()
            .then(() => {
                this.updateBrm()
            })
    },

    mounted() {

        this.getWindowSize()
        window.addEventListener('resize', this.getWindowSize)

    },
    beforeDestroy() {

        window.removeEventListener('resize', this.getWindowSize)
    },

    computed: {
        ...mapGetters(['isSystemAdmin', 'isStaffHigher', 'isStaff', 'isBot', 'isRider', 'isSelfGoal', 'mailtoLink']),
        ...mapState({
            user: (state) => state.user.info,
        }),

        isFinishMenuShown() {
            return this.isSelfGoal
        },

        mailto() {
            return this.mailtoLink
        }

    },

    methods: {
        ...mapActions(['getUserInfo', 'updateCurrent', 'getSummary', 'getWindowSize', 'getEntryList']),

        updateBrm() {
            this.getEntryList()
            this.getSummary()
            setTimeout(this.updateBrm, 5 * 60_000)
        }

    },

})
