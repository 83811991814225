<!-- 
    Communiction こちらの発言
-->

<template>
    <div class="row justify-content-end">
        <div class="col-10 col-md-8">
            <div>{{header}}<span class='pl-2 font-weight-bold font-tiny'>@{{updatedAt}}{{edited}}</span></div>
            <div class="talk-right">
                <div class='subject'><b>{{subject}}</b></div>
                <div class='content' v-for='line in contentLines'>{{line}}</div>
            </div>
            <el-link type="primary" style="position:absolute;bottom:0;left:-1.5em;" @click="edit">編集</el-link>
            <el-link type="primary" style="position:absolute;bottom:1.5em;left:-1.5em;" @click="delPost">削除</el-link>
             
        </div>
    </div>
</template>

<script>
    import { mapState, mapGetters, mapActions } from 'vuex'
    
    export default {
            props:{
                header: String,
                createdAt: String,
                updatedAt: String,
                subject: String,
                content: String,
                type: String
            },
            data(){
                return {}
            },
            
            computed: {
                contentLines(){
                    return this.content.split('__NL__')
                },
                edited(){
                    if( this.createdAt === this.updatedAt ){
                        return ''
                    } else {
                        return ' 変更'
                    }
                }
            },
            
            methods: {
                edit(){
                    this.$emit('edit')
                },
                delPost(){
                    this.$emit('del-post')
                }
            }
    }
    
</script>

<style scoped>
    .talk-right {
        border: grey 2px solid;
        margin-right: 10px;
        padding: 5px;
        border-radius: 5px;
        box-sizing: border-box;
        background: lightcyan;
    }
    .talk-right:after {
        content: "";
        position: absolute;
        top: 50%;
        right: 10px;
        margin-top: -2px;
        border: 8px solid transparent;
        border-left: 8px solid grey;
        box-sizing: border-box;
        z-index: 1;
    }
    .talk-right:before {
        content: "";
        position: absolute;
        top: 50%;
        right: 15px;
        margin-top: 0px;
        border: 6px solid transparent;
        border-left: 6px solid lightcyan;
        box-sizing: border-box;
        z-index: 2;
    }
    
</style>
